import React, { useContext, useEffect, useState } from "react";
import logo from "../../Assets/Images/SNT-logo-2022-removebg-preview.png";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { MdEmail, MdSend } from "react-icons/md";
import jsPDF from "jspdf";
import { BsDownload } from "react-icons/bs";
import html2canvas from "html2canvas";
import { uid } from "uid";
import axios from "axios";
import toast from "react-hot-toast";
import { LoderContext } from "../../App";
import { v4 as uuidv4 } from "uuid";

function UserOrderdetails({ quotation, onClose, id, orders }) {
  const { setIsLoading } = useContext(LoderContext);

  const calculateTotalAmount = (price, gst, q) => {
    const priceWithGst = parseFloat(price) * (1 + parseFloat(gst) / 100);
    return priceWithGst * q;
  };

  const calculateTotalAmountForAllItems = () => {
    return orders.reduce((total, order) => {
      return (
        total +
        order.order_items.reduce((subTotal, item) => {
          return (
            subTotal +
            calculateTotalAmount(
              item?.product_details?.discount_on_mrp,
              item?.product_details?.gst,
              item?.quantity
            )
          );
        }, 0)
      );
    }, 0);
  };

  const totalAmount = calculateTotalAmountForAllItems();

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col items-center justify-center my-auto z-50"
      >
        <div className="flex flex-row mt-5">
          <div onClick={(e) => e.stopPropagation()}>
            <button
              className="flex flex-row px-5 py-2 border-2 border-[#F58634] text-[#F58634] rounded-full hover:bg-[#F58634] hover:text-white duration-150 ease-in bg-white"
              // onClick={downloadQuotation}
            >
              <span className="mr-2 mt-1">
                <BsDownload />
              </span>
              Download Quotation
            </button>
          </div>
          <div className="ml-5" onClick={(e) => e.stopPropagation()}>
            <button
              // onClick={sendMail}
              className="flex flex-row px-5 py-2 border-2 border-[#F58634] text-[#F58634] rounded-full hover:bg-[#F58634] hover:text-white duration-150 ease-in bg-white"
            >
              <span className="mr-2 mt-1">
                <MdSend />
              </span>
              Mail Quotation
            </button>
          </div>
        </div>
        <div
          // ref={quotationRef}
          className="bg-white p-4 rounded shadow-md my-5 h-[310mm] w-[210mm] mb-10 sm:mt-5 mt-5 mr-5"
        >
          <div className="flex items-center justify-between border-b-2 border-gray-200">
            <div className="flex items-start justify-start">
              <img src={logo} className="w-52" alt="Company Logo" />
            </div>
            <div className="flex items-end justify-end flex-col mb-2 w-5/12">
              <h2 className="w-full text-xs flex mt-2">
                <span className="mr-2 text-lg text-[#F58634]">
                  <FaPhone />
                </span>
                +91 79 66140001/2,+91 88664 56412,+91 92277 63361
              </h2>
              <h2 className="w-full text-xs flex mt-2">
                <span className="mr-2 text-lg text-[#F58634]">
                  <MdEmail />
                </span>
                navkar@navkartrade.com
              </h2>
              <h2 className="w-full text-xs flex mt-2">
                <span className="mr-2 text-lg text-[#F58634]">
                  <FaLocationDot />
                </span>
                918/919, Solaris Business Hub, Opp. Jain Mandir, Nr. Bhuyangdev
                Cross Road, Ahmedabad-63.
              </h2>
            </div>
          </div>
          <div className="flex items-center justify-center my-3">
            <h2 className="text-xl text-[#F58634] font-semibold">
              Tax Invoice
            </h2>
          </div>
          {orders.map((e) => {
            return (
              <div className="flex items-center justify-between">
                <div className="flex items-start justify-start mt-4">
                  <h2 className="text-gray-500 font-semibold text-base">
                    To: {e?.user?.name} <br />
                    <span className="text-sm">{e?.address}</span> <br />
                    GST: {e?.user?.gst_no}
                  </h2>
                </div>
                <div className="flex items-end justify-end mt-4">
                  <h2 className="text-gray-500 font-semibold text-base">
                    Invoice Date: {new Date().toLocaleDateString("en-IN")}{" "}
                    <br />
                    Order-ID: {e?.uuid} <br />
                    Invoice Number: {uuidv4().slice(10)}
                  </h2>
                </div>
              </div>
            );
          })}
          <div className="mt-4 block w-full">
            <table className="min-w-full bg-white text-sm">
              <thead className="bg-[#F58634] text-white">
                <tr>
                  <th className="py-1 px-3">Model Number</th>
                  <th className="py-1 px-3">Product Name</th>
                  <th className="py-1 px-3">Quantity</th>
                  <th className="py-1 px-3">Price (₹)</th>
                  <th className="py-1 px-3">Discount (%)</th>
                  <th className="py-1 px-3">GST (%)</th>
                  <th className="py-1 px-3">Total (₹)</th>
                </tr>
              </thead>
              <tbody>
                {orders.length > 0 &&
                  orders.map((order, index) =>
                    order.order_items.map((item) => (
                      <tr key={index} className="bg-gray-100">
                        <td className="border px-3 py-1">
                          {item?.product_details?.product_model_no}
                        </td>
                        <td className="border px-3 py-1">
                          {item?.product_details?.product_name}
                        </td>
                        <td className="border px-3 py-1">{item?.quantity}</td>
                        <td className="border px-3 py-1">
                          ₹{Number(item?.product_details?.mrp).toLocaleString()}
                        </td>
                        <td className="border px-3 py-1">
                          {parseInt(item?.product_details?.discount)}
                        </td>
                        <td className="border px-3 py-1">
                          {item?.product_details?.gst}
                        </td>
                        <td className="border px-3 py-1">
                          ₹
                          {calculateTotalAmount(
                            item?.product_details?.discount_on_mrp,
                            item?.product_details?.gst,
                            item?.quantity
                          ).toFixed(2)}
                        </td>
                      </tr>
                    ))
                  )}
                <tr className="bg-gray-100">
                  <td className="border px-3 py-1" colSpan="6">
                    <div className="flex items-end justify-end">
                      <h2 className="font-bold text-lg">Total Amount</h2>
                    </div>
                  </td>
                  <td className="border px-3 py-1">
                    ₹{Number(totalAmount).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex justify-end mt-4">
            <div>
              <p className="text-xs">
                *Keep this invoice and manufacturer box for warranty purposes.
              </p>
              <p className="text-xs">
                At Shree Navkar Treadcom we try to deliver perfectly each and
                every time. But in the off-chance that you need to return the
                item, please do so with the original brand box/price tag,
                original packing, and invoice without which it will be really
                difficult for us to act on your request. Please help us in
                helping you. Terms and conditions apply.
              </p>
              <p className="text-xs">
                The goods sold are intended for end user consumption and not for
                re-sale.
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center mt-5">
            <div>
              <p className="text-xs">
                Sold By: Shree Navkar Treradcom
                <br />
                Ship-from Address: 918/919, Solaris Business Hub, Opp. Jain
                Mandir, Nr. Bhuyangdev Cross Road, Ahmedabad-63.
                <br />
                GST - Navkar_Gst_No
                <br />
                FSSAI License No - 13321999000230
                <br />
                IRN -
                3fd3c340d3663920e4f7f05e6294d3d94f40b56e4d1b99735cb9e74d7838d66e
              </p>
            </div>
            <div className="flex flex-col items-end">
              <div>
                <p className="text-xs text-right">Signature</p>
              </div>
              <div>
                <p className="text-xs text-right">Authorized Signatory</p>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <p className="text-xs text-center">
              Contact Shree Navkar: L : +91 79 66140001/2 ||
              www.navkar@navkartrade.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserOrderdetails;
