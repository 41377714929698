import React, { useContext, useEffect, useRef, useState } from "react";
import Navbar from "../../Components/Navbar";
import { AnimatePresence, useScroll } from "framer-motion";
import { LoderContext } from "../../App";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../Components/FormFields/Input";
import { PiCurrencyInrDuotone } from "react-icons/pi";
import { FiPlus } from "react-icons/fi";
import { FaMinus } from "react-icons/fa6";
import toast from "react-hot-toast";
import { CartContext } from "../../CartContext";
import emptycart from "../../Components/Images/Emptycart.webp";
import loginimage from "../../Components/Images/Login_Animation - 1718622000131.json";
import { useNavigate, useParams } from "react-router-dom";
import { IoCloseCircle } from "react-icons/io5";
import UserButton from "./UserComponents/UserButton";
import Lottie from "lottie-react";
import Cookies from "js-cookie";
function Cartpage() {
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useContext(LoderContext);
  const { products, getCartitems, totalPrice, discountPrice } =
    useContext(CartContext);
  const [discount_amount_final, setDiscount_amount_final] = useState(null);
  const orderdetails = useRef();
  const { Checkout } = useParams();

  const handleCoupon = (values, no_message) => {
    setIsLoading({ loader: true });
    axios
      .post("/api/v1/carts/apply_coupon", {
        coupon_code: values?.coupon_code,
      })
      .then((res) => {
        if (res?.status === 200) {
          Cookies.set("C_code", JSON.stringify(values), { expires: 30 });
        }
        console.log(res);
        if (no_message === false) {
          toast.success(res?.data?.message);
        }
        localStorage.setItem("Coupon", res?.data?.discount_amount);
        getCartitems();
        setDiscount_amount_final(res?.data?.discount_amount);
      })
      .catch((err) => {
        console.log(err);
        if (no_message === false) {
          toast.error(err?.response?.data?.errors);
        }
        if (err?.response?.status === 422) {
          getCartitems();
        }
      });
  };

  const handleRemoveitem = (id, value) => {
    setIsLoading({ loader: true });
    axios
      .delete(`/api/v1/cart_items/${id}`)
      .then((res) => {
        if (res?.status === 200) {
          toast.success(res?.data?.message);
          const coupon_code = Cookies.get("C_code");
          if (coupon_code) {
            handleCoupon(JSON.parse(coupon_code), true);
            getCartitems();
          } else {
            getCartitems();
          }
        }
      })
      .catch((err) => {
        toast.error(err?.message);
        console.log(err);
      });
  };
  const handleDecrease = (id, value) => {
    setIsLoading({ loader: true });
    if (value - 1 === 0) {
      handleRemoveitem(id, value);
    } else
      axios
        .put(`/api/v1/cart_items/${id}`, {
          quantity: value - 1,
        })
        .then((res) => {
          toast.success(res?.data?.message);
          const coupon_code = Cookies.get("C_code");
          if (coupon_code) {
            handleCoupon(JSON.parse(coupon_code), true);
          }
          console.log(res);
          if (!coupon_code) {
            getCartitems();
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };
  const handleIncrease = (id, value) => {
    setIsLoading({ loader: true });
    axios
      .put(`/api/v1/cart_items/${id}`, {
        quantity: value + 1,
      })
      .then((res) => {
        toast.success(res?.data?.message);
        const coupon_code = Cookies.get("C_code");
        if (coupon_code) {
          handleCoupon(JSON.parse(coupon_code), true);
        }
        console.log(res);
        if (!coupon_code) {
          getCartitems();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNavigate = () => {
    navigate("#targetDiv");
    orderdetails.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div
      className={`${products.length === 0 ? `` : `text-gray-50`}  min-h-svh`}
    >
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 py-8 mt-16">
        <AnimatePresence>
          {!localStorage.getItem("isAuthenticated") ? (
            <div className="flex flex-col items-center justify-center w-full">
              <Lottie
                animationData={loginimage}
                loop
                autoplay
                className="h-64 w-64"
              />
              <h1 className="mt-8 text-xl font-semibold text-[#242c44]">
                PLEASE LOG IN
              </h1>
              <h1 className="mt-4 text-[#242c44]">
                Login to view items in your Cart.
              </h1>
              <button
                onClick={() => {
                  navigate("/Login");
                }}
                className="px-5 mt-4 py-2 border-2 border-[#242C44] text-[#242C44] rounded-full hover:bg-[#242C44] hover:text-white duration-150 ease-in"
              >
                <span>Login</span>
              </button>
            </div>
          ) : products.length === 0 && !isLoading.loader ? (
            <div className="flex flex-col items-center justify-center w-full">
              <img
                src={emptycart}
                alt="Cart_Is_Empty"
                className="w-auto h-[162px]"
              />
              <h1 className="mt-8 text-xl font-semibold text-[#242c44]">
                Your cart is empty!
              </h1>
              <h1 className="mt-4 text-[#242c44]">Add items to it now.</h1>
              <button
                onClick={() => {
                  navigate("/");
                }}
                className="px-5 mt-4 py-2 border-2 border-[#242C44] text-[#242C44] rounded-full hover:bg-[#242C44] hover:text-white duration-150 ease-in"
              >
                <span>Shop now</span>
              </button>
            </div>
          ) : isLoading.loader ? (
            <div className="flex flex-col xl:flex-row">
              <div className="w-full xl:w-2/3">
                <div className="min-h-screen">
                  {Array(4)
                    .fill()
                    .map((_, i) => (
                      <div
                        key={i}
                        className=" border shadow-md rounded-xl p-4 mb-4"
                      >
                        <div className="w-full flex justify-end">
                          <Skeleton
                            width={25}
                            height={25}
                            className="rounded-xl"
                          />
                        </div>
                        <div className="flex flex-col space-x-2 sm:flex-row items-center">
                          <div className="">
                            <Skeleton width={160} height={160} />
                          </div>
                          <div className="pr-4 w-full">
                            <Skeleton
                              width="100%"
                              height={20}
                              count={4}
                              className="mb-2"
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="w-full xl:w-1/3 xl:fixed top-20 right-4">
                <div className="h-[500px] w-full p-8 shadow-xl">
                  <div className="mb-4 flex w-full justify-between">
                    <Skeleton width={200} height={50} />
                    <Skeleton width={100} height={50} />
                  </div>
                  <div className="mb-4">
                    <Skeleton height={20} />
                  </div>
                  {Array(4)
                    .fill()
                    .map((_, i) => (
                      <div
                        key={i}
                        className="flex items-center justify-between mb-2"
                      >
                        <Skeleton width={200} height={25} />
                        <Skeleton width={100} height={25} />
                      </div>
                    ))}
                  <div className="mt-8">
                    <Skeleton height={45} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col xl:flex-row">
              <div className="w-full xl:w-2/3">
                <div className="text-sm text-gray-400 flex items-center justify-center ">
                  <span> ---- </span>
                  <h1
                    onClick={() => {
                      navigate("/Checkout/cart");
                    }}
                    className={`${
                      Checkout === "cart" ? `text-lg text-[#242c44]` : ``
                    } cursor-pointer`}
                  >
                    Cart
                  </h1>
                  <span> ---- </span>
                  <h1
                    onClick={() => {
                      navigate("/Checkout/address");
                    }}
                    className={`${
                      Checkout === "address" ? `text-lg text-[#242c44]` : ``
                    } cursor-pointer`}
                  >
                    Address
                  </h1>
                  <span> ---- </span>
                  <h1
                    onClick={() => {
                      navigate("/Checkout/payment");
                    }}
                    className={`${
                      Checkout === "payment" ? `text-lg text-[#242c44]` : ``
                    } cursor-pointer`}
                  >
                    Payment
                  </h1>
                </div>
                <div className="rounded-xl md:p-4 bg-white shadow-md">
                  {products.map((e, index) => {
                    return (
                      <div
                        key={index}
                        className="shadow-xl rounded-lg flex-col justify-between mt-4 p-4 border border-[#242c44] border-opacity-25 relative"
                      >
                        <div
                          className="absolute top-4 right-4 cursor-pointer"
                          onClick={() => {
                            handleRemoveitem(e?.id, e?.quantity);
                          }}
                        >
                          <IoCloseCircle size={24} className="text-[#242c44]" />
                        </div>
                        <div className="sm:flex ">
                          <div className="p-0 h-[148px] sm:w-[180px] w-full flex items-center justify-center">
                            <img
                              src={e?.product_url}
                              alt="product_image"
                              className="object-fill  h-[148px] sm:w-[180px] w-full  rounded-md"
                            />
                          </div>
                          <div className="w-full p-4  flex flex-col md:justify-between text-gray-600">
                            <div className="flex flex-col w-full ">
                              <h1 className="text-[#242c44] sm:text-lg  font-semibold mb-2">
                                {e?.product?.product_name}
                              </h1>
                              <p className="text-sm text-gray-600 text-justify sm:line-clamp-1 sm:mb-px mb-1">
                                {e?.product?.info}
                              </p>
                              <p className="text-[#242c44] text-sm mb-2">
                                <span className="text-gray-600">Brand: </span>
                                <span>{e?.product?.brand_name}</span>
                              </p>
                            </div>
                            <div className="sm:flex items-end justify-between max-md:h-full">
                              <div className="md:flex  items-center space-x-2 w-full">
                                <p className="text-lg  font-semibold text-[#242c44]">
                                  {"₹" + e?.product?.discounted_price}
                                </p>
                                <p className="line-through text-sm">
                                  {"₹" + e?.product?.mrp * e?.quantity}
                                </p>
                                <p className="text-gray-400">
                                  ( {e?.product?.discount} % off )
                                </p>
                              </div>
                              <div className="flex items-center w-full text-sm justify-end max-sm:mt-4">
                                <div className="flex space-x-2">
                                  <span
                                    className="cursor-pointer flex items-center justify-center border rounded-full p-2 bg-gray-100 hover:bg-gray-200"
                                    onClick={() => {
                                      handleDecrease(e?.id, e?.quantity);
                                      localStorage.removeItem("Coupon");
                                    }}
                                  >
                                    <FaMinus size={20} />
                                  </span>
                                  <span className="flex items-center ">
                                    <span>{"Qty : "}</span>
                                    <span className="sm:text-xl text-2xl ml-2">
                                      {" " + e?.quantity}
                                    </span>
                                  </span>
                                  <span
                                    className="cursor-pointer flex items-center justify-center border rounded-full p-2 bg-gray-100 hover:bg-gray-200"
                                    onClick={() => {
                                      handleIncrease(e?.id, e?.quantity);
                                      localStorage.removeItem("Coupon");
                                    }}
                                  >
                                    <FiPlus size={20} className=" " />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                ref={orderdetails}
                id="orderdetails"
                className="w-full xl:w-1/3 xl:fixed top-28 mt-8 h-screen right-4 "
              >
                <div className="h-auto w-full p-8 rounded-xl shadow-xl bg-white border border-[#242c44]">
                  <div className="mb-4">
                    <Formik
                      initialValues={{ coupon_code: "" }}
                      validationSchema={Yup.object().shape({
                        coupon_code: Yup.string().required("* required"),
                      })}
                      onSubmit={(values, { setSubmitting }) => {
                        handleCoupon(values, false);
                        setSubmitting(false);
                      }}
                    >
                      {({ values }) => (
                        <Form>
                          <div className="flex flex-col w-full  ">
                            <h1 className="text-md font-semibold text-[#242c44]">
                              Apply Coupon
                            </h1>
                            <div className="flex items-center justify-between">
                              <Input
                                name="coupon_code"
                                placeholder="Enter Coupon Code"
                                type="text"
                              />
                              <UserButton
                                name="Apply"
                                type="submit"
                                className="w-full mt-8 "
                                // onClick={() => setSubmitCoupon(!submitCoupon)}
                                // disable={submitCoupon}
                              />
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <div className="mb-4">
                    <span className="text-xl font-semibold text-[#242c44]">
                      PRICE DETAILS
                    </span>
                    <span className="text-xl font-semibold text-[#242c44]">{` (${products.length} items) `}</span>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-[#242c44]">Total MRP</span>
                    <span className="flex items-center text-[#242c44]">
                      <PiCurrencyInrDuotone />
                      {totalPrice}
                    </span>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-[#242c44]">Discount On MRP</span>
                    <span className="flex items-center text-green-600">
                      - <PiCurrencyInrDuotone />
                      {discountPrice}
                    </span>
                  </div>
                  <div className="flex items-center justify-between mb-2 text-[#242c44]">
                    <span className="text-[#242c44]">Coupon Discount</span>
                    {localStorage.getItem("Coupon") || discount_amount_final ? (
                      <span className="flex items-center text-green-600">
                        - <PiCurrencyInrDuotone />
                        {localStorage.getItem("Coupon")}
                      </span>
                    ) : (
                      "---"
                    )}
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-[#242c44]">Delivery Charges</span>
                    <span className="flex items-center text-green-600">
                      "FREE"
                    </span>
                  </div>
                  <div className="max-md:flex hidden justify-between w-full mb-2 pt-2">
                    <span className="text-[#242c44] text-lg font-semibold">
                      Total Amount
                    </span>
                    <span className="flex items-center font-semibold text-[#242c44]">
                      <PiCurrencyInrDuotone />
                      {totalPrice -
                        discountPrice -
                        localStorage.getItem("Coupon")}
                    </span>
                  </div>
                  <div className="max-md:fixed max-md:bottom-0 max-md:flex bg-white  text-[#242c44]  items-center max-md:p-4  justify-between  border-t-2  left-0 mx-auto w-full ">
                    <div className="flex max-md:flex-col items-center justify-between  md:mb-2 md:pt-2">
                      <span className="text-[#242c44] text-lg font-semibold">
                        Total Amount
                      </span>
                      <span className="flex items-center text-lg font-semibold text-[#242c44]">
                        <PiCurrencyInrDuotone />
                        {totalPrice -
                          discountPrice -
                          localStorage.getItem("Coupon")}
                      </span>
                      <span
                        onClick={handleNavigate}
                        className="max-md:flex hidden cursor-pointer "
                      >
                        {"View Details"}
                      </span>
                    </div>
                    <div className="md:mt-4">
                      <button
                        onClick={() => {
                          // handlePlaceOrder();
                          navigate("/Checkout/address");
                        }}
                        type="submit"
                        className="py-2 px-2 w-full rounded-full md:rounded-none  border-2 border-[#242c44] text-[#242c44] hover:bg-[#242c44] hover:text-white duration-150 ease-in"
                      >
                        PLACE ORDER
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Cartpage;
