import React, { useEffect, useState } from "react";
import SideBar from "./AdminComponents/SideBar";
import { useSelector } from "react-redux";
import OrderTable from "./AdminComponents/Table/OrderTable";
import axios from "axios";
import OrderUpdate from "./AdminComponents/Modal/UpdateForm/OrderUpdate";
import toast from "react-hot-toast";
import Loader from "./AdminComponents/Loader";

function Orders() {
  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState("");
  const [open, setOpen] = useState(false);
  const [courierData, setCourierData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const view = useSelector((state) => state.navbar.value);

  const fetchOrders = () => {
    axios
      .get("api/v1/orders")
      .then((res) => {
        setOrders(res.data.orders);
        setLoader(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCourierDetails = () => {
    axios
      .get(`api/v1/couriers`)
      .then((res) => {
        setCourierData(res.data.couriers);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateOrderDetails = (value, { resetForm }) => {
    axios
      .put(`api/v1/orders/${orderDetails.id}/assign_courier`, {
        courier_id: value.courier_id,
        tracking_id: value.tracking_id,
        status: value.status,
        payment_status: value.payment_status,
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success(res.data.message);
          resetForm();
          setOpen(false);
          fetchOrders();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleOrderUpdateView = (order) => {
    setOpen(true);
    setOrderDetails({ ...order });
  };

  useEffect(() => {
    fetchOrders();
    fetchCourierDetails();
  }, []);
  return (
    <div>
      <SideBar />
      <div
        className={`h-screen p-7 ${
          view ? "ml-40 ease-in duration-150" : "ml-0 ease-in duration-150"
        }`}
      >
        {loader ? (
          <div className="flex flex-col">
            <div className="flex flex-col items-start justify-start xl:ml-20 2xl:ml-20 sm:ml-20 ml-20 w-52 mt-10">
              <label className="block text-sm text-gray-600">
                Search (Order ID)
              </label>
              <input
                placeholder="Order ID"
                type="text"
                onChange={(e) => setSearch(e.target.value)}
                className="block w-full px-4 py-2 mt-2 text-[#1B1D1E] placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1B1D1E]  focus:ring-[#1B1D1E] focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
            <div className="flex items-center justify-center sm:ml-20 ml-0 mt-5">
              <div className="w-full">
                <OrderTable
                  data={orders}
                  onUpdate={handleOrderUpdateView}
                  search={search}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </div>
      {open && (
        <OrderUpdate
          data={orderDetails}
          onClose={() => {
            setOpen(false);
            setOrderDetails("");
          }}
          courierData={courierData}
          onUpdate={updateOrderDetails}
        />
      )}
    </div>
  );
}

export default Orders;
