import React from "react";
import { RxCross1 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";

function ProductFeatureModal({ onClose, name, featureData }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-80 mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634]">
                {name}
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <ul>
            {featureData &&
              featureData.map((feature, index) => (
                <li key={index} className="text-lg m-3 flex">
                  <span className="mt-1 mr-2 text-[#F58634]">
                    <TiTick />
                  </span>
                  {feature}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProductFeatureModal;
