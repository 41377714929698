import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Admin from "./Pages/Admin/Admin";
import Product from "./Pages/Admin/Product";
import PrivateRoute from "./PrivateRoute";
import Login from "./Pages/User/Login";
import Signup from "./Pages/User/Signup";
import Home from "./Pages/User/Home";
import ViewProduct from "./Pages/Admin/ViewProduct";
import Viewmore from "./Pages/User/Viewmore";
import { createContext, useState } from "react";
import Courier from "./Pages/Admin/Courier";
// import Cartpage from "./Pages/User/Cartpage";
import Orders from "./Pages/Admin/Orders";
import Quotation from "./Pages/Admin/Quotation";
import { CartProvider } from "./CartContext";
// import Addresspage from "./Pages/User/Addresspage";
import AddQuotation from "./Pages/Admin/AddQuotation";
// import Paymentpage from "./Pages/User/Paymentpage";
import UserProfilePage from "./Pages/User/UserProfilepage";
import WebContent from "./Pages/Admin/WebContent";
import AllProducts from "./Pages/User/AllProducts";
import UserPrivateRoute from "./UserPrivateRoute";
import Checkoutpage from "./Checkoutpages.jsx";
export const LoderContext = createContext();

function App() {
  const [isLoading, setIsLoading] = useState({
    loader: false,
    unauthorized: false,
    notfound: false,
  });
  const [statusFilter, setStatusFilter] = useState(null);
  return (
    <LoderContext.Provider value={{ isLoading, setIsLoading }}>
      <div className="App">
        <Router>
          <Routes>
            <Route
              path="/Products"
              element={
                <PrivateRoute>
                  <Product />
                </PrivateRoute>
              }
            />
            <Route
              path="/ViewProducts"
              element={
                <PrivateRoute>
                  <ViewProduct />
                </PrivateRoute>
              }
            />
            <Route
              path="/Courier"
              element={
                <PrivateRoute>
                  <Courier />
                </PrivateRoute>
              }
            />
            <Route
              path="/Orders"
              element={
                <PrivateRoute>
                  <Orders />
                </PrivateRoute>
              }
            />
            <Route
              path="/AddQuotation"
              element={
                <PrivateRoute>
                  <AddQuotation />
                </PrivateRoute>
              }
            />
            <Route
              path="/ViewQuotation"
              element={
                <PrivateRoute>
                  <Quotation />
                </PrivateRoute>
              }
            />
            <Route
              path="/WebContent"
              element={
                <PrivateRoute>
                  <WebContent />
                </PrivateRoute>
              }
            />
            <Route path="/Admin" element={<Admin />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Signup" element={<Signup />} />
            <Route
              path="/"
              element={
                <CartProvider>
                  <Home
                    setStatusFilter={setStatusFilter}
                    statusFilter={statusFilter}
                  />
                </CartProvider>
              }
            />
            <Route
              path="/AllProducts/ViewProducts/:id"
              element={
                <CartProvider>
                  <Viewmore />
                </CartProvider>
              }
            />
            <Route
              path="/Checkout/:Checkout"
              element={
                <UserPrivateRoute>
                  <CartProvider>
                    <Checkoutpage />
                  </CartProvider>
                </UserPrivateRoute>
              }
            />
            {/* <Route
              path="/Checkout/cart"
              element={
                <UserPrivateRoute>
                  <CartProvider>
                    <Cartpage />
                  </CartProvider>
                </UserPrivateRoute>
              }
            />
            <Route
              path="/Checkout/address"
              element={
                <UserPrivateRoute>
                  <CartProvider>
                    <Addresspage />
                  </CartProvider>
                </UserPrivateRoute>
              }
            />
            <Route
              path="/Checkout/payment"
              element={
                <UserPrivateRoute>
                  <CartProvider>
                    <Paymentpage />
                  </CartProvider>
                </UserPrivateRoute>
              }
            /> */}
            <Route
              path="/UserProfile/:Page"
              element={
                <UserPrivateRoute>
                  <CartProvider>
                    <UserProfilePage />
                  </CartProvider>
                </UserPrivateRoute>
              }
            />
            <Route
              path="/AllProducts"
              element={
                <CartProvider>
                  <AllProducts
                    setStatusFilter={setStatusFilter}
                    statusFilter={statusFilter}
                  />
                </CartProvider>
              }
            />
          </Routes>
        </Router>
      </div>
    </LoderContext.Provider>
  );
}

export default App;
