import React, { useEffect, useState } from "react";
import SideBar from "./AdminComponents/SideBar";
import { useSelector } from "react-redux";
import Button from "../../Components/Button/Button";
import CategoryModal from "./AdminComponents/Modal/CategoryModal";
import axios from "axios";
import ProductForm from "./AdminComponents/Modal/Form/ProductForm";
import toast from "react-hot-toast";
import CouponModal from "./AdminComponents/Modal/CouponModal";
import Loader from "./AdminComponents/Loader";

function Product() {
  const [open, setOpen] = useState(false);
  const [openCoupon, setOpenCoupon] = useState(false);
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const view = useSelector((state) => state.navbar.value);

  const fetchCategories = () => {
    axios
      .get(`api/v1/categories`)
      .then((response) => {
        setCategories(response.data.categories);
        setLoader(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAddProduct = (value, { resetForm }) => {
    const loadingToast = toast.loading("Adding Product...", {
      autoClose: false,
    });
    const formData = new FormData();

    formData.append("product[category_id]", value.category);
    formData.append("product[product_name]", value.product_name);
    formData.append("product[brand_name]", value.brand_name);
    formData.append("product[product_model_name]", value.product_model_name);
    formData.append(
      "product[connecting_technology]",
      value.connecting_technology_name
    );
    formData.append("product[mobile_application]", value.mobile_application);
    formData.append("product[product_model_no]", value.product_model_no);
    formData.append("product[asin_no]", value.asin_no);
    formData.append("product[country]", value.country);
    formData.append("product[description]", value.description);
    value.features.forEach((feature, index) => {
      formData.append("product[features][]", feature);
    });
    value.special_features.forEach((specialFeature, index) => {
      formData.append("product[special_features][]", specialFeature);
    });
    formData.append("product[warranty]", value.warranty);
    formData.append("product[mrp]", value.mrp);
    formData.append("product[gst]", value.gst);
    formData.append("product[height]", value.height);
    formData.append("product[width]", value.width);
    formData.append("product[depth]", value.depth);
    formData.append("product[weight]", value.weight);
    formData.append("product[material]", value.material);
    formData.append("product[discount]", value.discount);
    formData.append("product[delivery_time]", value.delivery_time);
    formData.append("product[info]", value.info);
    formData.append("product[main_image]", value.main_image);
    formData.append("product[brochure]", value.brochure);
    formData.append("product[bestseller]", value.best_selling);
    formData.append("product[featured]", value.featured);
    [...value.other_images].forEach((image, index) => {
      formData.append("product[other_images][]", image);
    });
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(`api/v1/products`, formData, { headers })
      .then((response) => {
        if (response.status === 201) {
          resetForm();
          toast.dismiss(loadingToast);
          toast.success(response.data.message);
        }
      })
      .catch((error) => {
        toast.dismiss(loadingToast);
        toast.error(error.message);
        console.log(error);
      });
  };

  return (
    <div>
      <SideBar />
      <div
        className={`h-screen p-7 ${
          view ? "ml-40 ease-in duration-150" : "ml-0 ease-in duration-150"
        }`}
      >
        {loader ? (
          <div className="flex flex-col">
            <div className="flex justify-between items-start sm:ml-20 ml-0 mt-10">
              <div className="flex items-start justify-start">
                <Button name={"Add Categories"} onClick={() => setOpen(true)} />
              </div>
              <div className="flex items-end justify-end sm:ml-0 ml-2">
                <Button
                  name={"Add Coupon"}
                  onClick={() => setOpenCoupon(true)}
                />
              </div>
            </div>
            <div className="flex items-center justify-center sm:ml-20 ml-0 mt-5">
              <ProductForm
                categories={categories}
                handleAddProduct={handleAddProduct}
              />
            </div>
          </div>
        ) : (
          <div className="bg-white h-screen ">
            <Loader />
          </div>
        )}
      </div>

      {open && (
        <CategoryModal
          setOpen={setOpen}
          categories={categories}
          fetchCategories={fetchCategories}
        />
      )}
      {openCoupon && <CouponModal setOpen={setOpenCoupon} />}
    </div>
  );
}

export default Product;
