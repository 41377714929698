import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight, FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import ReactPaginate from "react-paginate";
import QuaotationDetail from "../Modal/QuaotationDetail";

function QuotationTable({ data, onUpdate, onDelete }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [quotationDetail, setQuotionDetail] = useState("");

  const handleViewQuotation = (quotation) => {
    setQuotionDetail({ ...quotation });
    setOpen(true);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  return (
    <div>
      <div className="flex flex-col h-full border">
        <div className="w-full mx-auto bg-white  rounded-sm">
          <header className="flex justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Quotations</h2>
            {totalPages > 1 && (
              <div className="flex justify-end">
                <ReactPaginate
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                  containerClassName={"flex"}
                  pageRangeDisplayed={1}
                  previousLabel={<FaChevronLeft />}
                  nextLabel={<FaChevronRight />}
                  activeClassName={"active"}
                  previousClassName={
                    "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                  }
                  nextClassName={
                    "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                  }
                  pageClassName={"mx-1"}
                  pageLinkClassName={
                    "bg-gray-200 text-[#020002] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                  }
                  breakClassName={"mx-1"}
                  breakLinkClassName={
                    "px-3 py-1 bg-gray-200 text-gray-600 rounded"
                  }
                />
              </div>
            )}
          </header>
        </div>

        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full border-collapse">
            <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-100">
              <tr>
                <th className="px-4 py-2">
                  <div className="text-left">Name</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Email</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Contact number</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">View Quotation</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Action</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {currentItems.map((quotation) => (
                <tr key={quotation.id}>
                  <td className="p-2 border-r">
                    <div className="text-left">{quotation.name}</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">{quotation.email}</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">{quotation.mobile_number}</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      <button
                        onClick={() => handleViewQuotation(quotation)}
                        className="hover:underline font-semibold"
                      >
                        View
                      </button>
                    </div>
                  </td>
                  <td className="p-2 ">
                    <div className="flex text-left">
                      <button
                        onClick={() => onUpdate(quotation)}
                        className="bg-green-200 text-green-600 p-2 rounded-lg text-lg shadow-md hover:shadow-none duration-300 ease-out"
                      >
                        <MdEdit />
                      </button>
                      <button
                        onClick={() => onDelete(quotation)}
                        className="bg-red-200 text-red-600 p-2 rounded-lg text-lg ml-2 shadow-md hover:shadow-none duration-300 ease-out"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {open && (
        <QuaotationDetail
          onClose={() => setOpen(false)}
          quotation={quotationDetail}
        />
      )}
    </div>
  );
}

export default QuotationTable;
