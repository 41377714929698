import { createSlice } from "@reduxjs/toolkit";

export const navbarSlice = createSlice({
  name: "navbar",
  initialState: {
    value: true,
  },
  reducers: {
    toggleView: (state) => {
      state.value = !state.value;
    },
  },
});

export const { toggleView } = navbarSlice.actions;

export default navbarSlice.reducer;
