import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Input from "../../../../Components/FormFields/Input";
import Button from "../../../../Components/Button/Button";
import * as Yup from "yup";
import { MdEdit } from "react-icons/md";
import { FaChevronLeft, FaChevronRight, FaTrash } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import axios from "axios";
import ReactPaginate from "react-paginate";
import toast from "react-hot-toast";

function CouponModal({ setOpen }) {
  const [add, setAdd] = useState(false);
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);

  const handleCouponUpdateView = (coupon) => {
    setSelectedCoupon({ ...coupon });
    setUpdate(true);
  };
  const handleDeleteCouponView = (coupon) => {
    setSelectedCoupon({ ...coupon });
    setDelete(true);
  };

  const addCoupon = (value, { resetForm }) => {
    const loading = toast.loading("Adding coupon...", {
      autoClose: false,
    });
    axios
      .post(`api/v1/coupons`, {
        coupon: {
          code: value.coupon_name,
          discount: value.coupon_discount,
          amount: value.amount,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(loading);
          toast.success(res.data.message);
          getCoupons();
          setAdd(false);
          resetForm();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateCoupon = (value, { resetForm }) => {
    axios
      .put(`api/v1/coupons/${selectedCoupon.id}`, {
        coupon: {
          code: value.coupon_name,
          discount: value.coupon_discount,
          amount: value.amount,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setUpdate(false);
          toast.success(res.data.message);
          resetForm();
          getCoupons();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteCoupon = () => {
    axios
      .delete(`api/v1/coupons/${selectedCoupon.id}`)
      .then((response) => {
        if (response.status === 200) {
          getCoupons();
          setDelete(false);
          setSelectedCoupon("");
          toast.success(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCoupons = () => {
    axios
      .get(`api/v1/coupons`)
      .then((res) => {
        setCoupons(res.data.coupons);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCoupons();
  }, []);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = coupons.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(coupons.length / itemsPerPage);

  const validationSchema = Yup.object().shape({
    coupon_name: Yup.string().required("Coupon name is required"),
    coupon_discount: Yup.number()
      .required("Coupon discount is required")
      .positive("Enter positive number"),
    amount: Yup.number()
      .required("Amount for discount is required")
      .positive("Enter positive number"),
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50">
        <div
          className={`bg-white p-5 sm:p-8 rounded shadow-md max-w-5xl  mx-auto ${
            update ? "sm:w-full w-full" : "sm:w-full w-11/12"
          }`}
        >
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634]">
                Coupon
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={() => setOpen(false)}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          {update ? (
            <div>
              <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634]">
                {selectedCoupon.code}
              </h1>
              <Formik
                initialValues={{
                  coupon_name: selectedCoupon.code || "",
                  coupon_discount: selectedCoupon.discount || "",
                  amount: selectedCoupon.amount || "",
                }}
                onSubmit={updateCoupon}
              >
                <Form>
                  <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    <div>
                      <Input
                        name={"coupon_name"}
                        label={"Coupon name"}
                        placeholder={"Coupon name"}
                        type={"text"}
                      />
                    </div>
                    <div>
                      <Input
                        name={"coupon_discount"}
                        label={"Coupon discount(%)"}
                        placeholder={"Coupon discount"}
                        type={"number"}
                      />
                    </div>
                    <div>
                      <Input
                        name={"amount"}
                        label={"Discount on amount"}
                        placeholder={"12***"}
                        type={"number"}
                      />
                    </div>
                    <div className="mt-6 flex flex-row">
                      <div>
                        <Button name={"Update"} type={"submit"} />
                      </div>
                      <div className="ml-2">
                        <Button
                          name={"Cancel"}
                          type={"button"}
                          onClick={() => setUpdate(false)}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          ) : (
            <div>
              {add ? (
                <div>
                  <Formik
                    initialValues={{
                      coupon_name: "",
                      coupon_discount: "",
                      amount: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={addCoupon}
                  >
                    <Form>
                      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                        <div>
                          <Input
                            name={"coupon_name"}
                            label={"Coupon name"}
                            placeholder={"Coupon name"}
                            type={"text"}
                          />
                        </div>
                        <div>
                          <Input
                            name={"coupon_discount"}
                            label={"Coupon discount(%)"}
                            placeholder={"Coupon discount"}
                            type={"number"}
                          />
                        </div>
                        <div>
                          <Input
                            name={"amount"}
                            label={"Discount on amount"}
                            placeholder={"12***"}
                            type={"number"}
                          />
                        </div>
                        <div className="mt-6 flex flex-row">
                          <div>
                            <Button name={"Add"} type={"submit"} />
                          </div>
                          <div className="ml-2">
                            <Button
                              name={"Cancel"}
                              type={"button"}
                              onClick={() => setAdd(false)}
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                  </Formik>
                </div>
              ) : (
                <div>
                  <Button name={"Add coupon"} onClick={() => setAdd(true)} />
                </div>
              )}
              <div className="w-full mb-4 overflow-hidden rounded-lg shadow-lg mt-5">
                <div className="w-full overflow-x-auto">
                  <table className="w-full">
                    <thead>
                      <tr className="text-md font-normal tracking-wide text-left text-gray-900 bg-gray-100 border-b border-gray-600">
                        <th className="px-4 py-2">Coupon name</th>
                        <th className="px-4 py-2">Coupon Discount</th>
                        <th className="px-4 py-2">Discount on amount</th>
                        <th className="px-4 py-2">Action</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y-2 divide-gray-200">
                      {currentItems.map((coupon) => (
                        <tr className="text-gray-700">
                          <td className="px-4 py-3">
                            <div>{coupon.code}</div>
                          </td>
                          <td className="px-4 py-3">
                            <div>{coupon.discount} %</div>
                          </td>
                          <td className="px-4 py-3">
                            <div>{coupon.amount}</div>
                          </td>
                          <td className="px-4 py-3">
                            {Delete && selectedCoupon.id === coupon.id ? (
                              <div className="flex flex-col">
                                <div>
                                  <h1 className="mr-2">
                                    Do you wanna delete {selectedCoupon.code} ?
                                  </h1>
                                </div>
                                <div>
                                  <button
                                    onClick={deleteCoupon}
                                    className="bg-green-200 text-green-600 p-2 rounded-lg text-lg shadow-md hover:shadow-none duration-300 ease-out"
                                  >
                                    <TiTick />
                                  </button>
                                  <button
                                    onClick={() => {
                                      setSelectedCoupon("");
                                      setDelete(false);
                                    }}
                                    className="bg-red-200 text-red-600 p-2 rounded-lg text-lg ml-2 shadow-md hover:shadow-none duration-300 ease-out"
                                  >
                                    <RxCross1 />
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div className="flex">
                                <button
                                  onClick={() => handleCouponUpdateView(coupon)}
                                  className="bg-green-200 text-green-600 p-2 rounded-lg text-lg shadow-md hover:shadow-none duration-300 ease-out"
                                >
                                  <MdEdit />
                                </button>
                                <button
                                  onClick={() => handleDeleteCouponView(coupon)}
                                  className="bg-red-200 text-red-600 p-2 rounded-lg text-lg ml-2 shadow-md hover:shadow-none duration-300 ease-out"
                                >
                                  <FaTrash />
                                </button>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {totalPages > 1 && (
                <div className="flex justify-end">
                  <ReactPaginate
                    pageCount={totalPages}
                    onPageChange={handlePageChange}
                    containerClassName={"flex"}
                    pageRangeDisplayed={1}
                    previousLabel={<FaChevronLeft />}
                    nextLabel={<FaChevronRight />}
                    activeClassName={"active"}
                    previousClassName={
                      "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                    }
                    nextClassName={
                      "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                    }
                    pageClassName={"mx-1"}
                    pageLinkClassName={
                      "bg-gray-200 text-[#020002] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                    }
                    breakClassName={"mx-1"}
                    breakLinkClassName={
                      "px-3 py-1 bg-gray-200 text-gray-600 rounded"
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CouponModal;
