import { Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import * as Yup from "yup";
import File from "../../../../../Components/FormFields/File";
import Button from "../../../../../Components/Button/Button";

function AddImageForm({ onClose, onSubmit }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 my-auto mx-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-3xl w-full mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634] mr-5">
                Carousel Slider Images and Client Logo
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <Formik
            initialValues={{
              slider_image: [],
              logo_image: [],
            }}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <File
                      name={"slider_image"}
                      type={"file"}
                      accept={"Image/*"}
                      label={"Slider images"}
                      onChange={(e) =>
                        setFieldValue("slider_image", e.target.files)
                      }
                      multiple={true}
                    />
                  </div>
                  <div>
                    <File
                      name={"logo_image"}
                      type={"file"}
                      accept={"Image/*"}
                      label={"Client logo images"}
                      onChange={(e) =>
                        setFieldValue("logo_image", e.target.files)
                      }
                      multiple={true}
                    />
                  </div>
                </div>
                <div className="mt-2 sm:mt-4">
                  <Button type={"submit"} name={"Add"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default AddImageForm;
