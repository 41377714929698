import React from "react";
import { RxCross1 } from "react-icons/rx";

function ExtraDetailModal({ onClose, productData }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 my-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className="mr-5 text-lg sm:text-2xl font-semibold  text-[#F58634]">
                {productData.product_name}'s Extra details
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 text-base font-semibold">
            <div className="flex flex-col">
              <h1 className="text-gray-400">Connecting technology</h1>
              <h1 className="text-gray-800">
                {productData.connecting_technology}
              </h1>
            </div>
            <div className="flex flex-col">
              <h1 className="text-gray-400">Height</h1>
              <h1 className="text-gray-800">{productData.height}</h1>
            </div>
            <div className="flex flex-col">
              <h1 className="text-gray-400">Width</h1>
              <h1 className="text-gray-800">{productData.width}</h1>
            </div>
            <div className="flex flex-col">
              <h1 className="text-gray-400">Depth</h1>
              <h1 className="text-gray-800">{productData.depth}</h1>
            </div>
            <div className="flex flex-col">
              <h1 className="text-gray-400">Weight</h1>
              <h1 className="text-gray-800">{productData.weight}</h1>
            </div>
            <div className="flex flex-col">
              <h1 className="text-gray-400">Warranty</h1>
              <h1 className="text-gray-800">{productData.warranty}</h1>
            </div>
            <div className="flex flex-col">
              <h1 className="text-gray-400">Material</h1>
              <h1 className="text-gray-800">{productData.material}</h1>
            </div>
            <div className="flex flex-col">
              <h1 className="text-gray-400">Delivery time</h1>
              <h1 className="text-gray-800">{productData.delivery_time}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExtraDetailModal;
