import { ErrorMessage, Field } from "formik";
import React from "react";

function UserInput({
  label,
  name,
  type,
  placeholder,
  readOnly,
  length,
  accept,
  disable,
  value,
  onChange,
}) {
  return (
    <div>
      <label className="block mb-2 text-sm text-gray-600 ">{label}</label>
      <Field
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        type={type}
        maxLength={length}
        accept={accept}
        disabled={disable}
        value={value}
        onChange={onChange}
        className="block w-full px-4 py-2 mt-2 text-[#1B1D1E] placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1B1D1E]  focus:ring-[#1B1D1E] focus:outline-none focus:ring focus:ring-opacity-40"
      />
      <ErrorMessage
        name={name}
        className="text-red-500 text-sm mt-1"
        component="div"
      />
    </div>
  );
}

export default UserInput;
