import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight, FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import ReactPaginate from "react-paginate";
import ProductFeatureModal from "../Modal/ProductFeatureModal";
import ProductImages from "../Modal/ProductImages";
import ExtraDetailModal from "../Modal/ExtraDetailModal";

function ProductTable({
  data,
  handleUpdateProductView,
  handleDeleteProductView,
  productData,
}) {
  const [viewImages, setViewImages] = useState(false);
  const [viewFeature, setViewFeature] = useState(false);
  const [viewSpecialFeature, setViewSpecialFeature] = useState(false);
  const [viewExtraDetail, setViewExtraDetail] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);

  const handleViewFeature = (product) => {
    setSelectedProduct({ ...product });
    setViewFeature(true);
  };

  const handleViewSpecialFeature = (product) => {
    setSelectedProduct({ ...product });
    setViewSpecialFeature(true);
  };

  const handleViewImages = (product) => {
    setSelectedProduct({ ...product });
    setViewImages(true);
  };

  const handleViewExtraDetails = (product) => {
    setSelectedProduct({ ...product });
    setViewExtraDetail(true);
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  return (
    <>
      <div className="flex flex-col h-full border">
        <div className="w-full mx-auto bg-white  rounded-sm">
          <header className="flex justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Products</h2>
            {totalPages > 1 && (
              <div className="flex justify-end">
                <ReactPaginate
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                  containerClassName={"flex"}
                  pageRangeDisplayed={1}
                  previousLabel={<FaChevronLeft />}
                  nextLabel={<FaChevronRight />}
                  activeClassName={"active"}
                  previousClassName={
                    "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                  }
                  nextClassName={
                    "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                  }
                  pageClassName={"mx-1"}
                  pageLinkClassName={
                    "bg-gray-200 text-[#020002] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                  }
                  breakClassName={"mx-1"}
                  breakLinkClassName={
                    "px-3 py-1 bg-gray-200 text-gray-600 rounded"
                  }
                />
              </div>
            )}
          </header>
        </div>

        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full border-collapse">
            <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-100">
              <tr>
                <th className="px-4 py-2">
                  <div className="text-left">Product name</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Brand name</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Category</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Product model name</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Product model number</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Price</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">GST</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Discont</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Features</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Special Features</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Images</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Brochure</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Extra detail</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Action</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {currentItems.map((product) => (
                <tr key={product.id}>
                  <td className="p-2 border-r">
                    <div className="text-left">{product.product_name}</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">{product.brand_name}</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">{product.category}</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      {product.product_model_name}
                    </div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">{product.product_model_no}</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">{product.mrp}</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">{product.gst}%</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">{product.discount}%</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      <button
                        onClick={() => handleViewFeature(product)}
                        className="hover:underline font-semibold"
                      >
                        View
                      </button>
                    </div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      <button
                        onClick={() => handleViewSpecialFeature(product)}
                        className="hover:underline font-semibold"
                      >
                        View
                      </button>
                    </div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      <button
                        onClick={() => handleViewImages(product)}
                        className="hover:underline font-semibold"
                      >
                        View
                      </button>
                    </div>
                  </td>
                  <td className="p-2 border-r">
                    <div
                      onClick={() => window.open(product.brochure_url)}
                      className="text-left"
                    >
                      <button className="hover:underline font-semibold">
                        View
                      </button>
                    </div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      <button
                        onClick={() => handleViewExtraDetails(product)}
                        className="hover:underline font-semibold"
                      >
                        View
                      </button>
                    </div>
                  </td>
                  <td className="p-2 ">
                    <div className="flex text-left">
                      <button
                        onClick={() => handleUpdateProductView(product)}
                        className="bg-green-200 text-green-600 p-2 rounded-lg text-lg shadow-md hover:shadow-none duration-300 ease-out"
                      >
                        <MdEdit />
                      </button>
                      <button
                        onClick={() => handleDeleteProductView(product)}
                        className="bg-red-200 text-red-600 p-2 rounded-lg text-lg ml-2 shadow-md hover:shadow-none duration-300 ease-out"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {viewFeature && (
        <ProductFeatureModal
          name={"Features"}
          onClose={() => setViewFeature(false)}
          featureData={selectedProduct.features}
        />
      )}
      {viewSpecialFeature && (
        <ProductFeatureModal
          name={"Special Features"}
          onClose={() => setViewSpecialFeature(false)}
          featureData={selectedProduct.special_features}
        />
      )}
      {viewImages && (
        <ProductImages
          onClose={() => setViewImages(false)}
          ImagesData={selectedProduct}
          productData={productData}
        />
      )}
      {viewExtraDetail && (
        <ExtraDetailModal
          onClose={() => setViewExtraDetail(false)}
          productData={selectedProduct}
        />
      )}
    </>
  );
}

export default ProductTable;
