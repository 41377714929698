import { FieldArray, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import Select from "../../../../../Components/FormFields/Select";
import Input from "../../../../../Components/FormFields/Input";
import Radio from "../../../../../Components/FormFields/Radio";
import TextArea from "../../../../../Components/FormFields/TextArea";
import { FaPlus } from "react-icons/fa";
import Button from "../../../../../Components/Button/Button";
import File from "../../../../../Components/FormFields/File";

function ProductForm({ handleAddProduct, categories }) {
  const validationSchema = Yup.object().shape({
    product_name: Yup.string().required("Product name is required"),
    brand_name: Yup.string().required("Brand name is required"),
    product_model_name: Yup.string().required("Product model name is required"),
    connecting_technology: Yup.string().required(
      "Connecting technology is required"
    ),
    mobile_application: Yup.boolean().required(
      "Mobile application is required"
    ),
    product_model_no: Yup.string().required("Product model number is required"),
    asin_no: Yup.string().required("ASIN number is required"),
    country: Yup.string().required("Country is required"),
    description: Yup.string().required("Description is required"),
    warranty: Yup.string().required("Warranty information is required"),
    mrp: Yup.number()
      .required("MRP is required")
      .positive("MRP must be a positive number"),
    gst: Yup.string().required("GST is required"),
    height: Yup.string().required("Height is required"),
    width: Yup.string().required("Width is required"),
    depth: Yup.string().required("Depth is required"),
    weight: Yup.string().required("Weight is required"),
    material: Yup.string().required("Material is required"),
    discount: Yup.number()
      .required("Discount is required")
      .integer("Discount must be an integer"),
    delivery_time: Yup.string().required("Delivery time is required"),
    info: Yup.string().required("Info is required"),
    category: Yup.string().required("Category is required"),
    main_image: Yup.mixed().required("Poster image is required"),
    other_images: Yup.mixed().required("Product images is required"),
    brochure: Yup.mixed().required("Brochure is required"),
  });

  const connectingTech = [
    { id: 1, name: "Wireless" },
    { id: 2, name: "Cellular" },
    { id: 3, name: "Bluetooth" },
  ];

  const handleCategoryChange = (value, setFieldValue) => {
    setFieldValue("category", value);
  };

  const handleConnectingTech = (value, setFieldValue) => {
    const selectedTech = connectingTech.find((tech) => tech.id == value);

    if (value) {
      setFieldValue("connecting_technology", value);
      setFieldValue("connecting_technology_name", selectedTech.name);
    }
  };

  return (
    <div className="bg-[#F4F4F4] p-8 rounded-md shadow-md mb-10">
      <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634]">
        Product
      </h1>
      <Formik
        initialValues={{
          product_name: "",
          brand_name: "",
          product_model_name: "",
          connecting_technology: "",
          connecting_technology_name: "",
          mobile_application: false,
          product_model_no: "",
          asin_no: "",
          country: "",
          description: "",
          special_features: [],
          features: [],
          warranty: "",
          mrp: "",
          gst: "",
          height: "",
          width: "",
          depth: "",
          weight: "",
          material: "",
          discount: "",
          delivery_time: "",
          info: "",
          category: "",
          main_image: null,
          other_images: null,
          brochure: null,
          featured: false,
          best_selling: false,
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleAddProduct}
      >
        {({ setFieldValue, errors, values }) => (
          <Form>
            <div className="grid grid-cols-1 sm:grid-cols-5 gap-2 sm:gap-4 mt-2 sm:mt-4">
              <div>
                <Select
                  name={"category"}
                  selectedOption={"Category"}
                  label={"Category"}
                  options={categories}
                  onChange={(e) =>
                    handleCategoryChange(e.target.value, setFieldValue)
                  }
                />
              </div>
              <div>
                <Input
                  name={"product_name"}
                  label={"Product name"}
                  placeholder={"Product name"}
                  type={"text"}
                />
              </div>
              <div>
                <Input
                  name={"brand_name"}
                  label={"Brand name"}
                  placeholder={"Brand name"}
                  type={"text"}
                />
              </div>
              <div>
                <Input
                  name={"product_model_name"}
                  label={"Product model name"}
                  placeholder={"Product model name"}
                  type={"text"}
                />
              </div>
              <div>
                <Input
                  name={"product_model_no"}
                  label={"Product model number"}
                  placeholder={"Product model number"}
                  type={"text"}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
              <div>
                <Select
                  name={"connecting_technology"}
                  label={"Connecting technology"}
                  selectedOption={"Technology"}
                  options={connectingTech}
                  onChange={(e) =>
                    handleConnectingTech(e.target.value, setFieldValue)
                  }
                />
              </div>
              <div>
                <label className="block mb-2 text-sm text-gray-600 ">
                  Mobile application
                </label>
                <div className="flex mt-4">
                  <Radio
                    name={"mobile_application"}
                    id={"yes"}
                    value={true}
                    label={"Yes"}
                    onChange={() => {
                      setFieldValue("mobile_application", true);
                    }}
                  />
                  <div className="ml-2">
                    <Radio
                      name={"mobile_application"}
                      id={"no"}
                      value={false}
                      label={"No"}
                      onChange={() => {
                        setFieldValue("mobile_application", false);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <Input
                  label={"Product ASIN"}
                  name={"asin_no"}
                  type={"text"}
                  placeholder={"ASIN no"}
                />
              </div>
              <div>
                <Input
                  name={"country"}
                  placeholder={"India"}
                  type={"text"}
                  label={"Country of origin"}
                />
              </div>
            </div>
            <div className="mt-2 sm:mt-4">
              <TextArea
                name={"description"}
                label={"Description"}
                placeholder={"description..."}
              />
            </div>
            <FieldArray name="features">
              {({ push }) => (
                <div className="mt-2 sm:mt-4">
                  <label className="block mb-2 text-sm text-gray-600">
                    Features
                  </label>
                  <button
                    type="button"
                    onClick={() => push("")}
                    className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                  >
                    <FaPlus />
                  </button>
                  <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    {values.features.map((detail, index) => (
                      <Input
                        name={`features.${index}`}
                        type={"text"}
                        placeholder={"Feature"}
                        label={"Feature"}
                      />
                    ))}
                  </div>
                </div>
              )}
            </FieldArray>
            <FieldArray name="special_features">
              {({ push }) => (
                <div className="mt-2 sm:mt-4">
                  <label className="block mb-2 text-sm text-gray-600">
                    Special features
                  </label>
                  <button
                    type="button"
                    onClick={() => push("")}
                    className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                  >
                    <FaPlus />
                  </button>
                  <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                    {values.special_features.map((detail, index) => (
                      <Input
                        name={`special_features.${index}`}
                        type={"text"}
                        placeholder={"Special feature"}
                        label={"Special feature"}
                      />
                    ))}
                  </div>
                </div>
              )}
            </FieldArray>
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
              <div>
                <Input
                  name={"mrp"}
                  type={"number"}
                  label={"MRP"}
                  placeholder={"12xx"}
                />
              </div>
              <div>
                <Input
                  name={"gst"}
                  type={"number"}
                  label={"GST (%)"}
                  placeholder={"8"}
                />
              </div>
              <div>
                <Input
                  name={"discount"}
                  type={"number"}
                  label={"Discont (%)"}
                  placeholder={"12"}
                />
              </div>
              <div>
                <Input
                  name={"warranty"}
                  type={"text"}
                  label={"Warranty"}
                  placeholder={"Warranty"}
                />
              </div>
              <div>
                <Input
                  name={"height"}
                  type={"text"}
                  label={"Height"}
                  placeholder={"Height"}
                />
              </div>
              <div>
                <Input
                  name={"width"}
                  type={"text"}
                  label={"Width"}
                  placeholder={"Width"}
                />
              </div>
              <div>
                <Input
                  name={"depth"}
                  type={"text"}
                  label={"Depth"}
                  placeholder={"Depth"}
                />
              </div>
              <div>
                <Input
                  name={"weight"}
                  type={"text"}
                  label={"Weight"}
                  placeholder={"Weight"}
                />
              </div>
              <div>
                <Input
                  name={"material"}
                  type={"text"}
                  label={"Material"}
                  placeholder={"Material"}
                />
              </div>
              <div>
                <Input
                  name={"delivery_time"}
                  type={"text"}
                  label={"Delivery time"}
                  placeholder={"1 hour"}
                />
              </div>
              <div>
                <File
                  name={"main_image"}
                  type={"file"}
                  accept={"Image/*"}
                  label={"Main image"}
                  onChange={(e) =>
                    setFieldValue("main_image", e.target.files[0])
                  }
                  multiple={false}
                />
              </div>
              <div>
                <File
                  name={"other_images"}
                  type={"file"}
                  accept={"Image/*"}
                  label={"Other images"}
                  onChange={(e) =>
                    setFieldValue("other_images", e.target.files)
                  }
                  multiple={true}
                />
              </div>
              <div>
                <File
                  name={"brochure"}
                  type={"file"}
                  accept={".pdf"}
                  label={"Brochure"}
                  onChange={(e) => setFieldValue("brochure", e.target.files[0])}
                  multiple={false}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm text-gray-600 ">
                  Best seller ?
                </label>
                <div className="flex mt-4">
                  <Radio
                    name={"best_selling"}
                    id={"yes"}
                    value={true}
                    label={"Yes"}
                    onChange={() => {
                      setFieldValue("best_selling", true);
                    }}
                  />
                  <div className="ml-2">
                    <Radio
                      name={"best_selling"}
                      id={"no"}
                      value={false}
                      label={"No"}
                      onChange={() => {
                        setFieldValue("best_selling", false);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <label className="block mb-2 text-sm text-gray-600 ">
                  Featured ?
                </label>
                <div className="flex mt-4">
                  <Radio
                    name={"featured"}
                    id={"yes"}
                    value={true}
                    label={"Yes"}
                    onChange={() => {
                      setFieldValue("featured", true);
                    }}
                  />
                  <div className="ml-2">
                    <Radio
                      name={"featured"}
                      id={"no"}
                      value={false}
                      label={"No"}
                      onChange={() => {
                        setFieldValue("featured", false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 sm:mt-4">
              <TextArea name={"info"} placeholder={"INFO"} label={"Info"} />
            </div>
            <div className="mt-2 sm:mt-4">
              <Button type={"submit"} name={"Add"} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ProductForm;
