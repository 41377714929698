import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiMenuFold3Line } from "react-icons/ri";
import { RiMenuFold4Line } from "react-icons/ri";
import { toggleView } from "../../../Features/navbarSlice";
import { CgMenuLeft } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";
import { LuPackagePlus } from "react-icons/lu";
import { LuPackageOpen } from "react-icons/lu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaTruck } from "react-icons/fa";
import { TbShoppingCartFilled } from "react-icons/tb";
import { MdDescription } from "react-icons/md";
import { RiStickyNoteAddLine } from "react-icons/ri";
import logo from "../../../Assets/Images/SNT-logo-2022-removebg-preview.png";
import logo2 from "../../../Assets/Images/SNT-logo-2022-removebg.png";
import { IoDesktopOutline, IoLogOut } from "react-icons/io5";

function SideBar() {
  const view = useSelector((state) => state.navbar.value);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const tabs = [
    {
      id: 1,
      name: "Add Product",
      path: "Products",
      icon: <LuPackagePlus />,
    },
    {
      id: 2,
      name: "View Product",
      path: "ViewProducts",
      icon: <LuPackageOpen />,
    },
    { id: 3, name: "Courier", path: "Courier", icon: <FaTruck /> },
    { id: 4, name: "Orders", path: "Orders", icon: <TbShoppingCartFilled /> },
    {
      id: 5,
      name: "Add Quotation",
      path: "AddQuotation",
      icon: <RiStickyNoteAddLine />,
    },
    {
      id: 6,
      name: "View Quotation",
      path: "ViewQuotation",
      icon: <MdDescription />,
    },
    {
      id: 7,
      name: "Web Content",
      path: "WebContent",
      icon: <IoDesktopOutline />,
    },
  ];

  return (
    <div>
      <button
        onClick={() => dispatch(toggleView())}
        className="bg-[#F58634] border-white border-2 p-2 rounded-full sm:hidden flex fixed top-5 left-5 z-40 text-white text-xl duration-100 ease-in outline-none"
      >
        {view ? <RxCross2 /> : <CgMenuLeft />}
      </button>
      <div
        className={`${
          view ? "w-11/12 sm:w-60" : "w-20 scale-0 sm:scale-100"
        } sm:h-screen h-full bg-[#F58634] p-5 duration-100 fixed overflow-x-hidden overflow-y-auto `}
      >
        <div
          className={`sm:flex hidden  ${
            view ? "items-end justify-end" : "items-center justify-center"
          }`}
        >
          <button
            onClick={() => dispatch(toggleView())}
            className="text-white text-xl border-white border-2 p-2 rounded-full outline-none"
          >
            {view ? <RiMenuFold3Line /> : <RiMenuFold4Line />}
          </button>
        </div>
        <div
          className={`${
            view ? "p-3" : "p-0"
          } items-center justify-center sm:mt-5 mt-12 bg-white rounded shadow-lg sm:mx-0 mx-20`}
        >
          {view ? (
            <img className="h-14 sm:w-full w-auto" src={logo} alt="Logo" />
          ) : (
            <img className="h-10 w-10" src={logo2} alt="logo" />
          )}
        </div>
        <ul className="flex flex-col mt-5">
          {tabs.map((tab, index) => (
            <li
              key={index}
              className={`${
                location.pathname === `/${tab.path}`
                  ? "bg-white text-[#F58634]"
                  : "text-white"
              }  text-base p-2 mt-2 rounded-lg outline-none`}
            >
              <Link
                to={`/${tab.path}`}
                className="flex flex-row uppercase outline-none"
              >
                <span className="mt-1 mr-2 text-xl">{tab.icon}</span>
                <span className={`${view ? "flex" : "hidden"} text-lg`}>
                  {tab.name}
                </span>
              </Link>
            </li>
          ))}
          <li
            className="text-white text-base p-2 mt-2 rounded-lg outline-none cursor-pointer"
            onClick={() => {
              localStorage.clear();
              navigate("/Admin");
            }}
          >
            <div className="flex flex-row uppercase outline-none">
              <span className="mt-1 mr-2 text-xl">
                <IoLogOut />
              </span>
              <span className={`${view ? "flex" : "hidden"} text-lg`}>
                LogOut
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SideBar;
