import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { AnimatePresence, motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import Cardview1 from "../../Components/CardViews.jsx/Cardview1";
import { Link, useNavigate } from "react-router-dom";
import { LoderContext } from "../../App";
import axios from "axios";
import logo from "../../Assets/Images/SNT-logo-2022-removebg-preview.png";
import { MdFilterListOff } from "react-icons/md";

function AllProducts() {
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useContext(LoderContext);
  const [filterBestseller, setFilterBestseller] = useState(false);
  const [filterFeatured, setFilterFeatured] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading({ loader: true });
      try {
        const response = await axios.get("/api/v1/products");
        if (response.status === 200) {
          setAllProducts(response.data.products);
          setFilteredProducts(response.data.products);
          setIsLoading({ loader: false });
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        handleFetchError(error);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get("/api/v1/categories");
        if (response.status === 200) {
          setCategories(response.data.categories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchProducts();
    fetchCategories();
  }, [setIsLoading]);

  const handleFetchError = (error) => {
    if (error?.response?.status === 404) {
      setIsLoading({ notfound: true });
    } else if (error?.code === "ERR_NETWORK") {
      setIsLoading({ notfound: true, loader: false });
    }
  };

  const handleViewMore = (id) => {
    navigate(`ViewProducts/${id}`);
  };

  const handleFilterChange = () => {
    let filtered = allProducts;

    if (filterName) {
      filtered = filtered.filter((product) =>
        product.product_name.toLowerCase().includes(filterName.toLowerCase())
      );
    }

    if (filterCategory) {
      filtered = filtered.filter(
        (product) => product.category_id === parseInt(filterCategory)
      );
    }

    if (filterBestseller) {
      filtered = filtered.filter((product) => product.bestseller === true);
    }

    if (filterFeatured) {
      filtered = filtered.filter((product) => product.featured === true);
    }

    setFilteredProducts(filtered);
  };

  useEffect(() => {
    handleFilterChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterName, filterCategory, filterBestseller, filterFeatured]);

  const clearFilters = () => {
    setFilterName("");
    setFilterCategory("");
    setFilterBestseller(false);
    setFilterFeatured(false);
    setFilteredProducts(allProducts);
  };

  return (
    <div className="min-h-screen bg-[#ffffff]">
      <Navbar />
      <div className="container mx-auto px-4 py-8">
        <AnimatePresence mode="wait">
          <motion.div
            key="products"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.9 }}
          >
            {isLoading.loader ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-16">
                {Array.from({ length: 5 }, (_, index) => (
                  <div
                    key={index}
                    className="max-w-sm mx-auto rounded-md overflow-hidden shadow-md bg-white transition-transform duration-300 hover:shadow-lg hover:-translate-y-2 py-0"
                  >
                    <div className="relative group">
                      <Skeleton height={160} width={300} />
                    </div>
                    <div className="p-4 h-56 flex flex-col justify-between">
                      <h3 className="text-lg font-semibold text-gray-800">
                        <Skeleton width={150} />
                      </h3>
                      <h4 className="text-md font-semibold text-gray-600">
                        <Skeleton width={150} />
                      </h4>
                      <p className="text-gray-600 text-sm mb-2 line-clamp-3">
                        <Skeleton width={200} />
                      </p>
                      <div className="flex items-center justify-between">
                        <span className="font-semibold text-lg text-gray-800 flex items-center justify-center">
                          <Skeleton width={100} />
                        </span>
                        <span>
                          <Skeleton width={80} />
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mt-16 p-4">
                <h1 className="text-4xl font-semibold text-[#242c44] h-fit w-fit p-2">
                  Our Products
                </h1>
                <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                  <div className=" mt-4 md:mt-0 w-full flex max-lg:flex-col items-center justify-between">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-2">
                      <input
                        type="text"
                        placeholder="Filter by name"
                        className="p-2 border border-gray-300 rounded-lg w-full"
                        value={filterName}
                        onChange={(e) => setFilterName(e.target.value)}
                      />
                      <select
                        className="p-2 border border-gray-300 rounded-lg w-full"
                        value={filterCategory}
                        onChange={(e) => setFilterCategory(e.target.value)}
                      >
                        <option value="">Filter by category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className={`grid grid-cols-1 ${
                        filteredProducts.length === allProducts.length
                          ? `sm:grid-cols-2`
                          : ` sm:grid-cols-3 `
                      }  gap-4 `}
                    >
                      <button
                        className={`w-full py-2 ${
                          filterBestseller
                            ? "bg-gray-700 text-white"
                            : "text-gray-700 hover:bg-gray-400"
                        } rounded-md transition duration-300 ease-in-out`}
                        onClick={() => setFilterBestseller(!filterBestseller)}
                      >
                        Bestsellers
                      </button>
                      <button
                        className={`w-full py-2 ${
                          filterFeatured
                            ? "bg-gray-700 text-white"
                            : "text-gray-700 hover:bg-gray-400"
                        } rounded-md transition duration-300 ease-in-out`}
                        onClick={() => setFilterFeatured(!filterFeatured)}
                      >
                        Featured
                      </button>
                      {filteredProducts.length !== allProducts.length && (
                        <button
                          className="w-full py-2 text-gray-700 hover:bg-blue-100 rounded-md transition duration-300 ease-in-out flex items-center justify-center"
                          onClick={clearFilters}
                        >
                          <span>Remove filter</span>
                          <span className="ml-2">
                            <MdFilterListOff size={20} />
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-8">
                  {filteredProducts.map((product) => (
                    <Cardview1
                      key={product.id}
                      src={product.main_image_url}
                      productname={product.product_name}
                      category={product.category}
                      description={product.description}
                      mrp={product.mrp}
                      id={product.id}
                      handleViewMore={handleViewMore}
                    />
                  ))}
                </div>
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
      <footer className="flex flex-col sm:flex-row w-full justify-between items-start sm:items-center bg-white p-8 rounded-md shadow-lg">
        <div className="mb-8 sm:mb-0">
          <img
            src={logo}
            alt="Shree Narkar Tradecom Logo"
            className="h-10 w-auto"
          />
          <p className="flex flex-col mt-2 text-gray-600">
            <span>© 2021 Shree Narkar Tradecom.</span>
            <span>All rights reserved.</span>
          </p>
        </div>
        <div className="mb-8 sm:mb-0">
          <h1 className="text-lg font-semibold text-gray-800">Get in Touch</h1>
          <p className="flex flex-col mt-2 text-gray-600">
            <span>918/919, Solaris Business Hub, Opp. Jain Mandir,</span>
            <span>Nr. Bhuyangdev Cross Road, Ahmedabad-63.</span>
          </p>
          <p className="mt-2 text-gray-600">
            <Link to="mailto:navkar@navkartrade.com">
              E: navkar@navkartrade.com
            </Link>
            <br />
            <Link to="tel:+917966140001">L: +91 79 66140001/2</Link>
            <br />
            <Link to="tel:+918866456412">
              M: +91 88664 56412 / +91 92277 63361
            </Link>
          </p>
        </div>
        <div>
          <h1 className="text-lg font-semibold text-gray-800">Learn More</h1>
          <p className="flex flex-col mt-2 text-gray-600">
            <Link to="/about-us">About Us</Link>
            <Link to="/our-services">Our Services</Link>
            <Link to="/products">Products</Link>
            <Link to="/terms-of-use">Terms of Use</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default AllProducts;
