import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight, FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import ReactPaginate from "react-paginate";

function TestimonialView({ data, DeleteView, UpdateView }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(6);

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  return (
    <div className="mb-8">
      <div className="flex items-end justify-end">
        {totalPages > 1 && (
          <div className="flex justify-end">
            <ReactPaginate
              pageCount={totalPages}
              onPageChange={handlePageChange}
              containerClassName={"flex"}
              pageRangeDisplayed={1}
              previousLabel={<FaChevronLeft />}
              nextLabel={<FaChevronRight />}
              activeClassName={"active"}
              previousClassName={
                "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
              }
              nextClassName={
                "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
              }
              pageClassName={"mx-1"}
              pageLinkClassName={
                "bg-gray-200 text-[#020002] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
              }
              breakClassName={"mx-1"}
              breakLinkClassName={"px-3 py-1 bg-gray-200 text-gray-600 rounded"}
            />
          </div>
        )}
      </div>
      <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-8">
        {currentItems.map((testimonial) => (
          <div
            className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8 cursor-pointer"
            key={testimonial.id}
          >
            <div className="flex items-center gap-4">
              <img
                alt=""
                src={testimonial.user_profile_url}
                className="size-16 rounded-full object-cover"
              />

              <div>
                <div className="flex flex-row justify-center gap-0.5 ">
                  <button
                    onClick={() => UpdateView(testimonial)}
                    className="bg-green-200 text-green-600 p-2 rounded-lg text-sm shadow-md hover:shadow-none duration-300 ease-out"
                  >
                    <MdEdit />
                  </button>
                  <button
                    onClick={() => DeleteView(testimonial)}
                    className="bg-red-200 text-red-600 p-2 rounded-lg text-sm ml-2 shadow-md hover:shadow-none duration-300 ease-out"
                  >
                    <FaTrash />
                  </button>
                </div>
                <p className="mt-0.5 text-lg font-medium text-gray-900">
                  {testimonial.user_name}
                </p>
              </div>
            </div>

            <p className="mt-4 text-gray-700 line-clamp-4">
              {testimonial.content}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TestimonialView;
