import { Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import Input from "../../../../../Components/FormFields/Input";
import * as Yup from "yup";
import Button from "../../../../../Components/Button/Button";

function CourierForm({ onClose, onSubmit }) {
  const validationSchema = Yup.object().shape({
    courier_name: Yup.string()
      .required("Courier serivce provider name is required")
      .min(3, "Name must have 3 characters"),
    web_link: Yup.string().required("Website link is needed"),
  });

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 my-auto mx-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-3xl w-full mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634] mr-5">
                Courier service
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <div>
            <Formik
              initialValues={{
                courier_name: "",
                web_link: "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <Input
                      name={"courier_name"}
                      label={"Name"}
                      placeholder={"Name"}
                      type={"text"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"web_link"}
                      label={"Courier website link"}
                      placeholder={"website link"}
                      type={"text"}
                    />
                  </div>
                </div>
                <div className="mt-2 sm:mt-4">
                  <Button name={"Add"} type={"submit"} />
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourierForm;
