import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { LoderContext } from "./App";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountPrice, setTotalDiscountPrice] = useState(0);
  const [totleQuantity, setTotalQuantity] = useState(0);
  const { setIsLoading } = useContext(LoderContext);
  const [currentUser, setCurrentUser] = useState(null);
  const getCartitems = () => {
    setIsLoading({ loader: true });
    axios
      .get("/api/v1/carts")
      .then((response) => {
        if (response.status === 200) {
          const { cart_items } = response.data;
          const { user } = response.data;
          Cookies.set("is_coupon_applied", response?.data?.is_coupon_applied, {
            expires: 30,
          });
          console.log(response?.data);
          if (cart_items.length === 0) {
            localStorage.removeItem("Coupon");
          }
          setProducts(
            cart_items?.sort(
              (a, b) =>
                parseFloat(b?.product?.discounted_price) -
                parseFloat(a?.product?.discounted_price)
            )
          );
          setCurrentUser(user);
          setTotalQuantity(
            cart_items.reduce((total, item) => total + item.quantity, 0)
          );
          setTotalPrice(
            cart_items.reduce(
              (total, item) =>
                total + parseInt(item?.product?.mrp) * parseInt(item?.quantity),
              0
            )
          );
          setTotalDiscountPrice(
            cart_items.reduce(
              (total, item) =>
                total +
                ((item.product.mrp * item?.product?.discount) / 100) *
                  item.quantity,
              0
            )
          );
          setIsLoading({ loader: false });
        }
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setIsLoading({
            unauthorized: true,
          });
        }
        if (!err?.response?.status === 404 || err?.code === "ERR_NETWORK") {
          setIsLoading({ notfound: true, loader: false });
        }
        console.error("Error fetching products:", err);
      });
  };
  useEffect(() => {
    if (localStorage.getItem("isAuthenticated")) {
      getCartitems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <CartContext.Provider
      value={{
        products,
        setProducts,
        totalPrice,
        discountPrice,
        totleQuantity,
        getCartitems,
        currentUser,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
