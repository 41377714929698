import React, { useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import { Form, Formik } from "formik";
import Input from "../../Components/FormFields/Input";
import * as Yup from "yup";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../Assets/Images/SNT-logo-2022-removebg-preview.png";
function Admin() {
  const [clientId, setClientId] = useState("");
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters."),
  });

  const getClientDetails = () => {
    axios
      .get("api/v1/users/credential")
      .then((response) => {
        setClientId(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLogin = (values, { resetForm }) => {
    const loadingToast = toast.loading("Logging in...", {
      autoClose: false,
    });
    axios
      .post(`api/v1/users/login`, {
        user: {
          email: values.email,
          password: values.password,
        },
        client_id: clientId,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.dismiss(loadingToast);
          toast.success(response.data.message);
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("userRole", response.data.user.role);
          localStorage.setItem("access_token", response.data.user.access_token);
          navigate("/Products");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    const access_token = localStorage.getItem("access_token");

    if (isAuthenticated && access_token) {
      navigate("/Products");
    } else {
      navigate("/Admin");
    }

    getClientDetails();
  }, []);

  return (
    <div className="bg-[#fbffff]">
      <div className="flex justify-center h-screen">
        <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
          <div className="flex-1">
            <div className="flex items-center justify-center mb-2">
              <img className="h-14 sm:w-52 w-52" src={logo} alt="Logo" />
            </div>

            <div className="mt-8">
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
              >
                <Form>
                  <div>
                    <Input
                      label={"E-mail"}
                      type={"text"}
                      name={"email"}
                      placeholder={"johnsmith@example.com"}
                    />
                  </div>

                  <div className="mt-6">
                    <Input
                      label={"Password"}
                      type={"text"}
                      name={"password"}
                      placeholder={"password"}
                    />
                  </div>
                  <div className="flex items-center justify-center mt-6">
                    <Button name={"Log In"} type={"submit"} />
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
        <div
          className="hidden bg-cover lg:block lg:w-2/3"
          style={{
            backgroundImage: `url(https://images.unsplash.com/photo-1451187580459-43490279c0fa?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
          }}
        ></div>
      </div>
    </div>
  );
}

export default Admin;
