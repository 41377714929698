import { Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import Input from "../../../../../Components/FormFields/Input";
import File from "../../../../../Components/FormFields/File";
import TextArea from "../../../../../Components/FormFields/TextArea";
import Button from "../../../../../Components/Button/Button";

function TestimonialUpdateForm({ data, onClose, onSubmit }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 my-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-3xl w-full mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className=" text-lg sm:text-2xl font-semibold text-[#F58634] mr-5">
                {data.user_name}'s Testimonial
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <Formik
            initialValues={{
              photo: "",
              name: "" || data.user_name,
              description: "" || data.content,
            }}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div className="grid sm:grid-cols-2 grid-cols-2 gap-4 sm:mt-2 mt-4 ">
                  <div>
                    <Input
                      name={"name"}
                      placeholder={"John Smith"}
                      label={"Customer name"}
                      type={"text"}
                    />
                  </div>
                  <div>
                    <File
                      name={"photo"}
                      type={"file"}
                      accept={"Image/*"}
                      label={"Customer photo"}
                      onChange={(e) =>
                        setFieldValue("photo", e.target.files[0])
                      }
                      multiple={false}
                    />
                  </div>
                </div>
                <div className="sm:mt-2 mt-4 h-32">
                  <TextArea
                    name={"description"}
                    label={"Description"}
                    placeholder={"Description..."}
                  />
                </div>
                <div className="sm:mt-2 mt-4">
                  <Button name={"Update"} type={"submit"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default TestimonialUpdateForm;
