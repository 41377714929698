import { ErrorMessage, Field } from "formik";
import React from "react";

function Radio({ label, name, id, value ,onChange}) {
  return (
    <div className="flex flex-row">
      <label htmlFor={label} className="text-sm text-gray-800 mr-1 ">
        {label}
      </label>
      <Field type="radio" name={name} value={value} id={id} onChange={onChange} className="accent-[#F58634]" />
    </div>
  );
}

export default Radio;
