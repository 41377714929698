import React, { useContext, useEffect, useState } from "react";
import { PiCurrencyInrDuotone } from "react-icons/pi";
import { CartContext } from "../../CartContext";
// import useRazorpay from "react-razorpay";
import Navbar from "../../Components/Navbar";
import UserButton from "./UserComponents/UserButton";
import { RiSecurePaymentFill } from "react-icons/ri";
import toast from "react-hot-toast";
import { BsCashCoin } from "react-icons/bs";
import logo from "../../Assets/Images/SNT-logo-2022-removebg-preview.png";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { LoderContext } from "../../App";
import Loader from "../../Components/Loader.jsx";
function Paymentpage() {
  const navigate = useNavigate();
  // const [Razorpay] = useRazorpay();
  const { totalPrice, discountPrice, getCartitems } = useContext(CartContext);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const { isLoading, setIsLoading } = useContext(LoderContext);
  const { Checkout } = useParams();

  useEffect(() => {
    if (totalPrice === 0) {
      navigate("/Checkout/cart");
    }
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      toast("Loading....");
    };
    const loadSelectedAddress = JSON.parse(getCookie("selectedAddress"));
    setSelectedAddress(loadSelectedAddress);
    return () => {
      document.body.removeChild(script);
    };
  }, [navigate, totalPrice]);
  function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  const handlePayment = () => {
    if (!window.Razorpay) {
      toast.error("Server-down ! Please try again...");
      return;
    }
    axios
      .post(`/api/v1/orders`, {
        status: "Pending",
        uuid: uuidv4().slice(0, 8),
        address: `${`${selectedAddress?.Delivery_address}`}${`, ${selectedAddress?.Locality}`}${`, ${selectedAddress?.City}`}${`, ${selectedAddress?.State}`}-${
          selectedAddress?.Pin_code
        }`,
      })
      .then((res) => {
        console.log(res);
        if (res?.status === 201) {
          const options = {
            key: "rzp_test_3SkpPeZzq0FEsu",
            amount:
              totalPrice * 100 -
              discountPrice * 100 -
              localStorage.getItem("Coupon") * 100,
            currency: "INR",
            order_id: res?.data?.razorpay_order_id,
            name: "SNT - Shree Navkar Tradecom Pvt. Ltd.",
            description: "Test Transaction",
            image: { logo },
            prefill: {
              email: localStorage.getItem("email"),
            },
            handler: (response) => {
              console.log(response?.status);
              getCartitems();
              Cookies.remove("C_code");
              Cookies.remove("is_coupon_applied");
              localStorage.removeItem("Coupon");
            },
            notes: {
              address: { selectedAddress },
            },
            theme: {
              color: "#242C44",
            },
          };

          const rzp1 = new window.Razorpay(options);
          rzp1.open();
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCOD = () => {
    setIsLoading({ loader: true });
    axios
      .post("api/v1/orders", {
        status: "Pending",
        uuid: uuidv4().slice(0, 8),
        address: `${`${selectedAddress?.Delivery_address}`}${`, ${selectedAddress?.Locality}`}${`, ${selectedAddress?.City}`}${`, ${selectedAddress?.State}`}-${
          selectedAddress?.Pin_code
        }`,
        payment_method: "cod",
      })
      .then((res) => {
        console.log(res);
        if (res?.status === 201) {
          toast.success(res?.data?.message);
          getCartitems();
          Cookies.remove("C_code");
          Cookies.remove("is_coupon_applied");
          localStorage.removeItem("Coupon");
          setIsLoading({ loader: false });
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };
  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      {isLoading.loader ? <Loader /> : null}
      <div className="max-w-7xl mx-auto px-4 py-8 mt-16 flex flex-wrap">
        <div className="w-full md:w-2/3 p-4">
          <div className="text-sm text-gray-400 flex items-center justify-center mb-4 ">
            <span> ---- </span>
            <h1
              onClick={() => {
                navigate("/Checkout/cart");
              }}
              className={`${
                Checkout === "cart" ? `text-lg text-[#242c44]` : ``
              } cursor-pointer`}
            >
              Cart
            </h1>
            <span> ---- </span>
            <h1
              onClick={() => {
                navigate("/Checkout/address");
              }}
              className={`${
                Checkout === "address" ? `text-lg text-[#242c44]` : ``
              } cursor-pointer`}
            >
              Address
            </h1>
            <span> ---- </span>
            <h1
              onClick={() => {
                navigate("/Checkout/payment");
              }}
              className={`${
                Checkout === "payment" ? `text-lg text-[#242c44]` : ``
              } cursor-pointer`}
            >
              Payment
            </h1>
          </div>
          <div className="flex items-center justify-between mb-8">
            <h1 className="text-xl text-[#242c44] font-semibold">
              Choose Payment Mode
            </h1>
          </div>
          <div className="bg-slate-100 p-4 rounded-lg shadow-md">
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
              <h1 className="text-md text-gray-400 font-semibold mb-4 lg:mb-0">
                Recommended Methods
              </h1>
              <div className="flex sm:items-center max-[457px]:flex-col justify-between mt-4 md:mt-0 ">
                <div>
                  <UserButton
                    onClick={handlePayment}
                    name={"Pay Now"}
                    icon={<RiSecurePaymentFill size={25} />}
                  />
                </div>
                <div className="max-[457px]:mt-4">
                  <UserButton
                    onClick={handleCOD}
                    name={"Cash On Delivery"}
                    icon={<BsCashCoin size={25} />}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/3 p-4 ">
          <div className="rounded-lg shadow-md bg-gray-50 p-6 ">
            <div className="flex flex-col w-full mb-4">
              <h1 className="text-md text-gray-400 font-semibold mb-4">
                Delivery Address
              </h1>
              <div className="text-[#242c44] flex p-4 border hover:border-[#242c44]">
                <div className="p-4 w-full">
                  <h1 className="text-md mb-4 font-semibold">
                    {selectedAddress?.Name}
                  </h1>
                  <h1 className="text-md text-gray-500">
                    {selectedAddress?.Delivery_address}
                    {selectedAddress?.Locality
                      ? `,${selectedAddress?.Locality}`
                      : null}
                  </h1>
                  <h1 className="text-md text-gray-500 mb-4">
                    {selectedAddress?.City}, {selectedAddress?.State} -{" "}
                    {selectedAddress?.Pin_code}
                  </h1>
                  <h1 className="text-md font-semibold mb-2">
                    <span className="text-gray-500 font-normal">
                      {"Mobile: "}
                    </span>
                    {selectedAddress?.Mobile_no}
                  </h1>
                </div>
              </div>
            </div>
            <div className="mb-6">
              <h1 className="text-xl font-semibold text-[#242c44]">
                PRICE DETAILS
              </h1>
            </div>
            <div className="flex items-center justify-between mb-2">
              <span className="text-[#242c44]">Total MRP</span>
              <span className="flex items-center text-[#242c44]">
                <PiCurrencyInrDuotone />
                {totalPrice}
              </span>
            </div>
            <div className="flex items-center justify-between mb-2">
              <span className="text-[#242c44]">Discount On MRP</span>
              <span className="flex items-center text-green-600">
                - <PiCurrencyInrDuotone />
                {discountPrice}
              </span>
            </div>
            <div className="flex items-center justify-between mb-2">
              <span className="text-[#242c44]">Coupon Discount</span>
              {localStorage.getItem("Coupon") ? (
                <span className="flex items-center text-green-600">
                  - <PiCurrencyInrDuotone />
                  {localStorage.getItem("Coupon")}
                </span>
              ) : (
                "---"
              )}
            </div>
            <div className="flex items-center justify-between mb-2">
              <span className="text-[#242c44]">Delivery Charges</span>
              <span className="flex items-center text-green-600">"FREE"</span>
            </div>
            <div className="flex items-center justify-between mb-2 border-t-2 pt-2">
              <span className="text-[#242c44] text-lg font-semibold">
                Total Amount
              </span>
              <span className="flex items-center text-lg font-semibold text-[#242c44]">
                <PiCurrencyInrDuotone />
                {totalPrice - discountPrice - localStorage.getItem("Coupon")}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Paymentpage;
