import React from "react";
import { BiSolidQuoteAltLeft, BiSolidQuoteAltRight } from "react-icons/bi";

function Testimonials({ content, image, name }) {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between max-w-4xl mx-auto bg-slate-200 shadow-xl rounded-lg overflow-hidden my-8 p-6 transition-transform duration-300 hover:scale-105 ">
      <div className="flex items-center justify-center w-1/2 md:w-1/4 mb-4 md:mb-0">
        <img
          src={image}
          alt="user"
          className="h-44 w-36 rounded-full border-4 border-white object-cover"
        />
      </div>
      <div className="md:ml-6 md:w-3/4 flex-grow">
        <div className="flex   mb-4">
          <p className="text-gray-700 text-base md:text-lg text-justify md:text-left italic leading-relaxed mb-2">
            <span className="text-orange-400 text-4xl">"</span>
            <span className="max-sm:text-xs text-[#242c44]">{content}</span>
            <span className="text-orange-400 text-4xl relative top-2">"</span>
          </p>
        </div>
        <div className="mt-4 text-center md:text-left">
          <span className="text-gray-900 font-bold text-lg md:text-xl">
            - {name}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
