import React from "react";
import { RxCross1 } from "react-icons/rx";

function ViewProduct({ onClose, user }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 my-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-3xl w-full mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className="mr-5 text-lg sm:text-2xl font-semibold  text-[#F58634]">
                {user.user.name}'s order details
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <div className="mt-4 block w-full overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead className="bg-[#F58634] text-white">
                <tr>
                  <th className="py-2 px-4">Product Name</th>
                  <th className="py-2 px-4">Price</th>
                  <th className="py-2 px-4">Quantity</th>
                  <th className="py-2 px-4">Discount (%)</th>
                  <th className="py-2 px-4">Total</th>
                </tr>
              </thead>
              <tbody>
                {user.order_items.map((order, index) => {
                  const total_price =
                    order.quantity * order.product_details.discount_on_mrp;
                  return (
                    <tr key={index} className="bg-gray-100">
                      <td className="border px-4 py-2">{order.product_name}</td>
                      <td className="border px-4 py-2">₹{order.price}</td>
                      <td className="border px-4 py-2">{order.quantity}</td>
                      <td className="border px-4 py-2">
                        {parseInt(order.product_details.discount)}
                      </td>
                      <td className="border px-4 py-2">{total_price}</td>
                    </tr>
                  );
                })}
                <tr className="bg-gray-100">
                  <td className="border px-4 py-2" colSpan={4}>
                    <div className="flex items-end justify-end">
                      Total amount:
                    </div>
                  </td>
                  <td className="border px-4 py-2">{user.total_price}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewProduct;
