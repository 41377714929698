import React, { useEffect, useState } from "react";
import SideBar from "./AdminComponents/SideBar";
import { useSelector } from "react-redux";
import Button from "../../Components/Button/Button";
import AddImageForm from "./AdminComponents/Modal/Form/AddImageForm";
import axios from "axios";
import SliderImages from "./AdminComponents/SliderImages";
import ClientLogo from "./AdminComponents/ClientLogo";
import toast from "react-hot-toast";
import AddTestimonialForm from "./AdminComponents/Modal/Form/AddTestimonialForm";
import TestimonialView from "./AdminComponents/TestimonialView";
import TestimonialUpdateForm from "./AdminComponents/Modal/UpdateForm/TestimonialUpdateForm";
import DeleteModal from "./AdminComponents/Modal/DeleteModal";
import Loader from "./AdminComponents/Loader";

function WebContent() {
  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [addTestimonial, setAddTestimonial] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = useState("");
  const view = useSelector((state) => state.navbar.value);

  const handleTestimonialUpdateView = (testimonial) => {
    setUpdate(true);
    setSelectedTestimonial({ ...testimonial });
  };
  const handleTestimonialDeleteView = (testimonial) => {
    setDelete(true);
    setSelectedTestimonial({ ...testimonial });
  };

  const addImageHandler = (value, { resetForm }) => {
    const formData = new FormData();
    [...value.slider_image].forEach((image, index) => {
      formData.append("page[images][]", image);
    });

    [...value.logo_image].forEach((image, index) => {
      formData.append("page[client_logos][]", image);
    });

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(`api/v1/pages/${1}/add_images`, formData, { headers })
      .then((res) => {
        if (res.status === 200) {
          setOpen(false);
          resetForm();
          fetchImages();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchImages = () => {
    axios
      .get(`api/v1/pages`)
      .then((res) => {
        setImagesData(res.data.pages);
        setLoader(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteImage = (id) => {
    axios
      .delete(`api/v1/pages/destroy_image/${id}`)
      .then((res) => {
        if (res.status === 200) {
          fetchImages();
          toast.success(res.data.message);
        }
      })
      .catch((Err) => {
        console.log(Err);
        toast.error(Err.message);
      });
  };

  const addTestimonialHandler = (values, { resetForm }) => {
    const loading = toast.loading("Adding ...", {
      autoClose: true,
    });
    const formData = new FormData();
    formData.append("testimonial[name]", values.name);
    formData.append("testimonial[user_profile]", values.photo);
    formData.append("testimonial[comment]", values.description);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    axios
      .post(`api/v1/testimonials`, formData, { headers })
      .then((res) => {
        if (res.status === 201) {
          resetForm();
          toast.dismiss(loading);
          toast.success(res.data.message);
          setAddTestimonial(false);
          getTestimonial();
        }
      })
      .catch((err) => {
        toast.dismiss(loading);
        console.log(err);
        toast.error(err.message);
      });
  };

  const getTestimonial = () => {
    axios
      .get(`api/v1/testimonials`)
      .then((res) => {
        setTestimonial(res.data.testimonials);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateTestimonialHandler = (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("testimonial[name]", values.name);
    if (values.photo) {
      formData.append("testimonial[user_profile]", values.photo);
    }
    formData.append("testimonial[comment]", values.description);
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .put(`api/v1/testimonials/${selectedTestimonial.id}`, formData, {
        headers,
      })
      .then((res) => {
        if (res.status === 200) {
          setUpdate(false);
          setSelectedTestimonial("");
          resetForm();
          getTestimonial();
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteTestimonail = () => {
    axios
      .delete(`api/v1/testimonials/${selectedTestimonial.id}`)
      .then((res) => {
        if (res.status === 200) {
          getTestimonial();
          setDelete(false);
          setSelectedTestimonial("");
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    fetchImages();
    getTestimonial();
  }, []);

  return (
    <div>
      <SideBar />
      <div
        className={`h-screen p-7 ${
          view ? "ml-40 ease-in duration-150" : "ml-0 ease-in duration-150"
        }`}
      >
        {loader ? (
          <div>
            <div className="flex items-start justify-start sm:ml-20 ml-0 sm:mt-8 mt-10">
              <Button name={"Add Images/Logo"} onClick={() => setOpen(true)} />
            </div>
            <div className="flex flex-col items-start justify-start sm:ml-20 ml-0 mt-5">
              <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634] mr-5">
                Slider carousel
              </h1>
              <div className="mt-3 w-full">
                <SliderImages data={imagesData} onDelete={deleteImage} />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start sm:ml-20 ml-0 mt-5">
              <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634] mr-5">
                Client logo
              </h1>
              <div className="mt-3 w-full">
                <ClientLogo data={imagesData} onDelete={deleteImage} />
              </div>
            </div>
            <div className="flex flex-col items-start justify-start sm:ml-20 ml-0 mt-5 mb-5">
              <div className="flex flex-row">
                <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634] mr-5 mt-1">
                  Testimonials
                </h1>
                <div>
                  <Button
                    name={"Add Testimonial"}
                    onClick={() => setAddTestimonial(true)}
                  />
                </div>
              </div>
              <div className="mt-3 w-full">
                <TestimonialView
                  data={testimonial}
                  UpdateView={handleTestimonialUpdateView}
                  DeleteView={handleTestimonialDeleteView}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </div>
      {open && (
        <AddImageForm
          onClose={() => setOpen(false)}
          onSubmit={addImageHandler}
        />
      )}
      {addTestimonial && (
        <AddTestimonialForm
          onClose={() => setAddTestimonial(false)}
          onSubmit={addTestimonialHandler}
        />
      )}
      {update && (
        <TestimonialUpdateForm
          onClose={() => {
            setUpdate(false);
            setSelectedTestimonial("");
          }}
          data={selectedTestimonial}
          onSubmit={handleUpdateTestimonialHandler}
        />
      )}
      {Delete && (
        <DeleteModal
          icon={true}
          onClose={() => {
            setDelete(false);
            setSelectedTestimonial("");
          }}
          title={"Remove Testimonial"}
          message={`Do you want tot delete testimonial of ${selectedTestimonial.user_name}?`}
          onDelete={handleDeleteTestimonail}
        />
      )}
    </div>
  );
}

export default WebContent;
