import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { PiCurrencyInrDuotone } from "react-icons/pi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { LuDownload } from "react-icons/lu";
import { LoderContext } from "../../App";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { GoDot } from "react-icons/go";
import { CgDetailsMore } from "react-icons/cg";
import toast from "react-hot-toast";
import { CartContext } from "../../CartContext";
import Skeleton from "react-loading-skeleton";
import Cookies from "js-cookie";
function Viewmore() {
  const navigate = useNavigate();
  const { getCartitems } = useContext(CartContext);
  const [value, setValue] = React.useState(4.5);
  const { isLoading, setIsLoading } = useContext(LoderContext);
  const { id } = useParams();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [mainImageurl, setMainImageurl] = useState(null);
  const [viewDiv, setViewDiv] = useState({
    keyFeatures: false,
    specialFeatures: false,
  });

  const handleViewDiv = (section) => {
    setViewDiv((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
    axios
      .get(`/api/v1/products/${id}`)
      .then((response) => {
        if (response.status === 200) {
          setSelectedProduct(response.data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // setIsLoading(false); // Stop loading in case of error
      });
  }, [id, setIsLoading]);

  const sections = [
    { key: "keyFeatures", label: "Key Features" },
    { key: "specialFeatures", label: "Special Features" },
  ];

  const handleViewBrochure = (link) => {
    window.open(link);
  };
  const handleAddToCart = (id) => {
    if (!localStorage.getItem("access_token")) {
      toast("Please login to add items");
      navigate("/Login");
    } else
      axios
        .post(`/api/v1/cart_items`, {
          product_id: id,
          quantity: 1,
        })
        .then((res) => {
          toast.success(res?.data?.message);
          const coupon_code = Cookies.get("C_code");
          if (coupon_code) {
            handleCoupon(JSON.parse(coupon_code), true);
          }
          console.log(res);
          if (!coupon_code) {
            getCartitems();
          }
        })
        .catch((err) => {
          console.log(err);
        });
  };
  const handleCoupon = (values, no_message) => {
    axios
      .post("/api/v1/carts/apply_coupon", {
        coupon_code: values?.coupon_code,
      })
      .then((res) => {
        if (res?.status === 200) {
          Cookies.set("C_code", JSON.stringify(values), { expires: 30 });
        }
        console.log(res);
        if (no_message === false) {
          toast.success(res?.data?.message);
        }
        localStorage.setItem("Coupon", res?.data?.discount_amount);
        getCartitems();
      })
      .catch((err) => {
        console.log(err);
        if (no_message === false) {
          toast.error(err?.response?.data?.errors);
        }
      });
  };

  return (
    <div className="">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 py-8 mt-20">
        <AnimatePresence>
          {isLoading.loader === true ? (
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.9 }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto">
                <div className="relative">
                  <div className="md:fixed top-25 left-4 w-full md:w-1/2 h-auto flex items-center flex-col m-auto pb-10 rounded-lg">
                    <Skeleton width={400} height={300} className="rounded-lg" />
                    <div className="flex justify-center md:justify-start space-x-2 overflow-auto md:mt-4 mt-4 mx-4">
                      <Skeleton width={100} height={100} />
                      {selectedProduct?.other_images?.map((url, index) => (
                        <Skeleton key={index} width={100} height={100} />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col p-6">
                  <div className="flex items-center justify-between w-full">
                    <h2 className=" font-semibold text-gray-800">
                      <Skeleton width={400} height={40} />
                    </h2>
                    <div className="flex items-center justify-end text-[#242C44] cursor-pointer">
                      <Skeleton width={100} height={40} />
                    </div>
                  </div>
                  <h3 className="text-lg text-gray-600 mb-2">
                    <Skeleton width={200} height={25} />
                  </h3>
                  <p className="text-gray-600 mb-4 flex flex-col">
                    <Skeleton width={500} height={10} />
                    <Skeleton width={500} height={10} />
                    <Skeleton width={500} height={10} />
                  </p>
                  <Box className="mb-4">
                    <Skeleton width={100} height={25} />
                    <Skeleton width={500} height={10} />
                    <Skeleton width={500} height={10} />
                    <Skeleton width={500} height={10} />
                  </Box>
                  <div className="flex flex-col md:flex-row md:items-end md:justify-between mb-4">
                    <div className="flex flex-col justify-end">
                      <Skeleton width={200} height={25} />
                    </div>
                    <Skeleton width={100} height={40} />
                  </div>
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between w-full">
                      <h2 className=" font-semibold text-gray-800">
                        <Skeleton width={400} height={40} />
                      </h2>
                      <div className="flex items-center justify-end text-[#242C44] cursor-pointer">
                        <Skeleton width={100} height={40} />
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-full">
                      <h2 className=" font-semibold text-gray-800">
                        <Skeleton width={400} height={40} />
                      </h2>
                      <div className="flex items-center justify-end text-[#242C44] cursor-pointer">
                        <Skeleton width={100} height={40} />
                      </div>
                    </div>
                  </div>
                  <div className="text-[#242C44] mt-8">
                    <div className="mt-8">
                      <h3 className="text-lg text-gray-600 mb-2">
                        <Skeleton width={200} height={25} />
                      </h3>
                      <p className="text-gray-600 mb-4 flex flex-col">
                        <Skeleton width={500} height={10} />
                        <Skeleton width={500} height={10} />
                        <Skeleton width={500} height={10} />
                      </p>
                      <Box className="mb-4">
                        <Skeleton width={100} height={25} />
                        <Skeleton width={500} height={10} />
                        <Skeleton width={500} height={10} />
                        <Skeleton width={500} height={10} />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          ) : (
            <motion.div
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.9 }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mx-auto">
                <div className="relative">
                  <div className="md:fixed top-25 left-4 w-full md:w-1/2 h-auto flex items-center flex-col m-auto pb-10 rounded-lg">
                    <img
                      src={mainImageurl || selectedProduct?.main_image}
                      alt="Product"
                      className="sm:w-96 sm:h-[450px] w-auto h-auto rounded-xl object-fill"
                    />
                    <div className="flex justify-center md:justify-start space-x-2 overflow-auto md:mt-4 mt-4 mx-4">
                      <img
                        src={selectedProduct?.main_image}
                        alt={`main_image`}
                        className="h-24 w-24 md:h-24 object-fill rounded-lg cursor-pointer border-2 border-gray-300 hover:border-gray-500 transition-all duration-200"
                        onClick={() =>
                          setMainImageurl(selectedProduct?.main_image)
                        }
                      />
                      {selectedProduct?.other_images?.map((url, index) => (
                        <img
                          key={index}
                          src={url}
                          alt={`other_image_${index}`}
                          className="h-24 w-24 md:h-24 object-fill rounded-lg cursor-pointer border-2 border-gray-300 hover:border-gray-500 transition-all duration-200"
                          onClick={() => setMainImageurl(url)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col p-6">
                  <div className="flex items-center justify-between w-full">
                    <h2 className="text-4xl font-semibold text-gray-800 ">
                      {selectedProduct?.product_details?.product_name ||
                        "Product Name"}
                    </h2>
                    <div
                      className="flex items-center justify-end text-[#242C44] cursor-pointer"
                      onClick={() => {
                        handleViewBrochure(selectedProduct?.brochure);
                      }}
                    >
                      <LuDownload size={28} />
                      Brochure
                    </div>
                  </div>
                  <h3 className="text-lg text-gray-600 mb-2">
                    {"Category: "}
                    {selectedProduct?.category_name || "Category Name"}
                  </h3>
                  <p className="text-gray-600 mb-4">
                    {"Description: "}
                    <span className="text-justify">
                      {selectedProduct?.product_details?.description ||
                        "Product description here."}
                    </span>
                  </p>
                  <Box className="mb-4">
                    <Typography component="legend" className="">
                      Rating
                    </Typography>
                    <span className="items-center flex">
                      <span className="mr-2">{value}</span>
                      <Rating
                        name="half-rating-read"
                        defaultValue={value}
                        precision={0.5}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      />
                    </span>
                  </Box>
                  <p className="text-gray-600">
                    {"Brand Name: "}
                    <span className="text-justify">
                      {selectedProduct?.product_details?.brand_name ||
                        "Brand Name"}
                    </span>
                  </p>
                  <p className="text-gray-600 mb-4">
                    {"Origin: "}
                    <span className="text-justify">
                      {selectedProduct?.product_details?.country || "Country"}
                    </span>
                  </p>
                  <div className="flex flex-col md:flex-row md:items-end md:justify-between mb-4">
                    <div className="flex flex-col justify-end">
                      <div className="flex items-center text-sm mb-4">
                        M.R.P:
                        <span className="line-through flex items-center ml-2">
                          <PiCurrencyInrDuotone />
                          {selectedProduct?.product_details?.mrp} /-
                        </span>
                      </div>
                      <div className="flex items-end mb-4">
                        <span className="text-2xl text-gray-800 font-semibold flex items-center">
                          <PiCurrencyInrDuotone />
                          {selectedProduct?.product_details?.mrp -
                            (selectedProduct?.product_details?.mrp *
                              (selectedProduct?.product_details?.discount ||
                                10)) /
                              100}{" "}
                        </span>
                        <span className="text-green-600 text-xl ml-2">
                          {`( ${
                            selectedProduct?.product_details?.discount || 10
                          }% off )`}
                        </span>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        handleAddToCart(selectedProduct?.product_details?.id);
                      }}
                      className="px-4 py-2 bg-gray-800 text-white text-md text-center rounded-lg shadow-md transition-transform transform hover:scale-40 hover:bg-white hover:text-[#242C44] hover:border-2 border-[#242C44]"
                    >
                      Add To Cart
                    </button>
                  </div>
                  <div className="flex flex-col">
                    {sections.map(({ key, label }) => (
                      <div
                        key={key}
                        className="bg-white shadow-md rounded-lg p-4 mb-4 cursor-pointer"
                        onClick={() => handleViewDiv(key)}
                      >
                        <h3 className="text-lg font-semibold text-gray-800 mb-2 flex items-center justify-between">
                          {label}
                          {viewDiv[key] ? (
                            <IoClose className="ml-2" />
                          ) : (
                            <FaPlus className="ml-2" />
                          )}
                        </h3>
                        {viewDiv[key] && (
                          <div className="transition-all duration-300">
                            {key === "keyFeatures" &&
                              selectedProduct?.product_details?.features.map(
                                (feature, index) => (
                                  <p
                                    key={index}
                                    className="flex items-center text-gray-600 mb-1"
                                  >
                                    <IoMdCheckmarkCircleOutline className="text-green-600 mr-2" />
                                    {feature}
                                  </p>
                                )
                              )}
                            {key === "specialFeatures" &&
                              selectedProduct?.product_details?.special_features.map(
                                (feature, index) => (
                                  <p
                                    key={index}
                                    className="flex items-center text-gray-600 mb-1"
                                  >
                                    <IoMdCheckmarkCircleOutline className="text-green-600 mr-2" />
                                    {feature}
                                  </p>
                                )
                              )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="text-[#242C44] mt-8">
                    <div className="mt-8">
                      <p className="text-xl font-semibold flex items-center">
                        PRODUCT DETAILS{" "}
                        <CgDetailsMore size={24} className="ml-2" />
                      </p>
                      <div className="mt-4">
                        <p className="text-lg font-semibold">Model Details</p>
                        <p className="flex items-center">
                          <GoDot />
                          {"Model Name : "}
                          {selectedProduct?.product_details?.product_model_name}
                        </p>
                        <p className="flex items-center">
                          <GoDot />
                          {"Model No. : "}
                          {selectedProduct?.product_details?.product_model_no}
                        </p>
                      </div>
                      <div className="mt-4">
                        <p className="text-lg font-semibold">Information</p>
                        <p className="flex items-center">
                          <GoDot />
                          {selectedProduct?.product_details?.info}
                        </p>
                      </div>
                      <div className="mt-4">
                        <p className="text-lg font-semibold">
                          Technology Support
                        </p>
                        <p className="flex items-center">
                          <GoDot />
                          {"Connecting Technology : "}
                          {
                            selectedProduct?.product_details
                              ?.connecting_technology
                          }
                        </p>
                        <p className="flex items-center">
                          <GoDot />
                          {"Mobile Application : "}
                          {selectedProduct?.product_details
                            ?.mobile_application === true
                            ? "Yes"
                            : "No"}
                        </p>
                      </div>
                      <div className="mt-4">
                        <p className="text-lg font-semibold">Material Info.</p>
                        <p className="flex items-center">
                          <GoDot />
                          <span>
                            {" "}
                            Material name :{" "}
                            {selectedProduct?.product_details?.material}
                          </span>
                        </p>
                        <p className="flex items-center">
                          <GoDot />
                          <span>
                            Height : {selectedProduct?.product_details?.height}
                          </span>
                        </p>
                        <p className="flex items-center">
                          <GoDot />
                          <span>
                            Width : {selectedProduct?.product_details?.width}
                          </span>
                        </p>
                        <p className="flex items-center">
                          <GoDot />
                          <span>
                            Depth : {selectedProduct?.product_details?.depth}
                          </span>
                        </p>
                      </div>
                      <div className="mt-4">
                        <p className="text-lg font-semibold">Warranty</p>
                        <p className="flex items-center">
                          <GoDot />
                          {selectedProduct?.product_details?.warranty}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default Viewmore;
