import React from "react";
import { FaTrashAlt } from "react-icons/fa";

function DeleteModal({ icon, title, onDelete, onClose, message }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-y-scroll">
      <div className="flex items-center w-full max-w-3xl p-8 mx-auto lg:px-12 lg:w-3/5">
        <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg  bg-white ">
          <div className="text-center p-5 flex-auto justify-center">
            {icon === true ? (
              <div className="flex items-center justify-center mb-2">
                <FaTrashAlt className="h-12 w-12 text-red-700" />
              </div>
            ) : (
              <></>
            )}
            <h2 className="font-bold text-xl tracking-widest  text-red-500">
              {title}
            </h2>
            <p className="font-semibold text-lg">{message}</p>

            <div className="p-3  mt-2 text-center space-x-4 md:block">
              <button
                onClick={onDelete}
                className="mb-2 md:mb-0 bg-red-500 border border-red-500 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-red-600 duration-300 ease-out"
              >
                Yes
              </button>
              <button
                onClick={onClose}
                className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100 duration-300 ease-out"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
