import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const userRole = localStorage.getItem("userRole");

  if (!isAuthenticated) {
    return <Navigate to="/Admin" />;
  }
  if (userRole === "admin" && isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/Admin" />;
  }
}

export default PrivateRoute;
