import React, { useEffect, useState } from "react";
import SideBar from "./AdminComponents/SideBar";
import { useSelector } from "react-redux";
import axios from "axios";
import toast from "react-hot-toast";
import QuotationTable from "./AdminComponents/Table/QuotationTable";
import QuotationUpdateForm from "./AdminComponents/Modal/UpdateForm/QuotationUpdateForm";
import DeleteModal from "./AdminComponents/Modal/DeleteModal";
import Loader from "./AdminComponents/Loader";

function Quotation() {
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [quotation, setQuotation] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState("");
  const view = useSelector((state) => state.navbar.value);

  const handleQuotationUpdateView = (quotation) => {
    setSelectedQuotation({ ...quotation });
    setUpdate(true);
  };
  const handleQuotationDeleteView = (quotation) => {
    setSelectedQuotation({ ...quotation });
    setDelete(true);
  };

  const fetchCategories = () => {
    axios
      .get(`api/v1/categories`)
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProductData = () => {
    axios
      .get(`api/v1/products`)
      .then((res) => {
        setProducts(res.data.products);
        setLoader(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateQuotation = (value, { resetForm }) => {
    axios
      .put(`api/v1/quotations/${selectedQuotation.id}`, {
        quotation: {
          name: value.name,
          email: value.email,
          mobile_number: value.contact_number,
          quotation_items: value.product_data,
          address: value.address,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          resetForm();
          setUpdate(false);
          getQuotation();
          setSelectedQuotation("");
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  const deleteQuotation = () => {
    axios
      .delete(`api/v1/quotations/${selectedQuotation.id}`)
      .then((res) => {
        if (res.status === 200) {
          setDelete(false);
          getQuotation();
          toast.success(res.data.message);
          setSelectedQuotation("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQuotation = () => {
    axios
      .get(`api/v1/quotations`)
      .then((res) => {
        setQuotation(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProductData();
    fetchCategories();
    getQuotation();
  }, []);

  return (
    <div>
      <SideBar />
      <div
        className={`h-screen p-7 ${
          view ? "ml-40 ease-in duration-150" : "ml-0 ease-in duration-150"
        }`}
      >
        {loader ? (
          <div className="flex flex-col">
            <div className="flex items-start justify-start sm:ml-20 ml-0 mt-10"></div>
            <div className="flex items-start justify-start sm:ml-20 ml-0 mt-10">
              <div className="w-full">
                <QuotationTable
                  data={quotation}
                  onUpdate={handleQuotationUpdateView}
                  onDelete={handleQuotationDeleteView}
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </div>
      {update && (
        <QuotationUpdateForm
          onClose={() => {
            setUpdate(false);
            setSelectedQuotation("");
          }}
          data={selectedQuotation}
          products={products}
          categories={categories}
          onSubmit={updateQuotation}
        />
      )}
      {Delete && (
        <DeleteModal
          icon={true}
          title={"Delete Quotation"}
          message={`Do you want to delete ${selectedQuotation.name}'s quotation?`}
          onDelete={deleteQuotation}
          onClose={() => {
            setDelete(false);
            setSelectedQuotation("");
          }}
        />
      )}
    </div>
  );
}

export default Quotation;
