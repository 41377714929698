import React from "react";

function UserButton({ type, onClick, name, disable, icon }) {
  return (
    <div>
      <button
        type={type}
        onClick={onClick}
        disabled={disable}
        className="px-5 flex items-center justify-center space-x-2 py-2 border-2 border-[#242C44] text-[#242C44] rounded-full hover:bg-[#242C44] hover:text-white duration-150 ease-in"
      >
        <span>{name}</span>
        <span>{icon && icon}</span>
      </button>
    </div>
  );
}

export default UserButton;
