import { Form, Formik } from "formik";
import React from "react";
import { RxCross1 } from "react-icons/rx";
import Select from "../../../../../Components/FormFields/Select";
import Input from "../../../../../Components/FormFields/Input";
import Button from "../../../../../Components/Button/Button";

function OrderUpdate({ data, onClose, courierData, onUpdate }) {
  const status = [
    { id: 1, name: "In Transit" },
    { id: 2, name: "Out for Delivery" },
    { id: 3, name: "Delivered" },
  ];

  const paymentStatus = [{ id: 1, name: "paid" }];

  const handleDeliveryStatusChange = (value, setFieldValue) => {
    const selectedStatus = status.find(
      (data) => parseInt(data.id) === parseInt(value)
    );

    setFieldValue("status_id", value);
    setFieldValue("status", selectedStatus.name);
  };

  const handlePaymentStatusChange = (value, setFieldValue) => {
    const selectedPaymentStatus = paymentStatus.find(
      (data) => parseInt(data.id) === parseInt(value)
    );
    setFieldValue("payment_id", value);
    setFieldValue("payment_status", selectedPaymentStatus.name);
  };

  const status_id = status.find((detail) => detail.name === data.status);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 my-auto mx-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-3xl w-full mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634] mr-5">
                {data.user.name}'s order
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <Formik
            initialValues={{
              courier_id: data.courier ? data.courier.id : "",
              tracking_id: data.tracking_id || "",
              status_id: status_id ? status_id.id : "",
              status: data.status || "",
              payment_id: "",
              payment_status: data.payment_status || "",
            }}
            enableReinitialize={true}
            onSubmit={onUpdate}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="grid sm:grid-cols-2 grid-cols-1 sm:mt-4 mt-2 gap-2 sm:gap-4">
                  <div>
                    <Select
                      label={"Courier service"}
                      name={"courier_id"}
                      selectedOption={"Courier"}
                      options={courierData}
                      onChange={(e) =>
                        setFieldValue("courier_id", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <Input
                      label={"Tracking ID"}
                      name={"tracking_id"}
                      placeholder={"Tracking ID"}
                      type={"text"}
                      disable={values.courier_id === "" ? true : false}
                    />
                  </div>
                  <div>
                    <Select
                      label={"Delivery Status"}
                      name={"status_id"}
                      selectedOption={"Delivery Status"}
                      options={status}
                      onChange={(e) =>
                        handleDeliveryStatusChange(
                          e.target.value,
                          setFieldValue
                        )
                      }
                    />
                  </div>
                  <div>
                    <Select
                      label={"Payment Status"}
                      name={"payment_id"}
                      selectedOption={"Payment Status"}
                      options={paymentStatus}
                      onChange={(e) =>
                        handlePaymentStatusChange(e.target.value, setFieldValue)
                      }
                    />
                  </div>
                </div>
                <div className="sm:mt-4 mt-2">
                  <Button name={"Update"} type={"submit"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default OrderUpdate;
