import React, { useState } from "react";
import Input from "../../../../../Components/FormFields/Input";
import { FieldArray, Form, Formik } from "formik";
import Select from "../../../../../Components/FormFields/Select";
import { RxCross1 } from "react-icons/rx";
import Button from "../../../../../Components/Button/Button";

function QuotationUpdateForm({
  onClose,
  data,
  products,
  categories,
  onSubmit,
}) {
  const [filteredData, setFilteredData] = useState(products);

  const handleCategoryChange = (value, setFieldValue) => {
    const filterByCategory = products.filter(
      (product) => parseInt(product.category_id) === parseInt(value)
    );
    if (value === "Category") {
      setFieldValue("category", value);
      setFilteredData(products);
    } else {
      setFieldValue("category", value);
      setFilteredData(filterByCategory);
    }
  };

  const handleProductChange = (product, isChecked, values, setFieldValue) => {
    const productData = values.product_data || [];
    if (isChecked) {
      setFieldValue("product_data", [
        ...productData,
        { product_id: product.id, quantity: 1, discount: 0 },
      ]);
    } else {
      setFieldValue(
        "product_data",
        productData.filter((item) => item.product_id !== product.id)
      );
    }
  };

  const calculateTotal = (quantity, mrp, discount) => {
    const total = quantity * mrp;
    const discountAmount = (total * discount) / 100;
    return total - discountAmount;
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 mx-auto my-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-7xl sm:w-full w-4/5 mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634]">
                {data.name}'s Quotation
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <div>
            <Formik
              initialValues={{
                name: "" || data.name,
                email: "" || data.email,
                contact_number: "" || data.mobile_number,
                category: "",
                product_data: data.quotation_items || [],
                address: data.address || "",
              }}
              onSubmit={onSubmit}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="grid sm:grid-cols-2 grid-cols-1 sm:mt-4 mt-2 gap-2 sm:gap-4">
                    <div>
                      <Input
                        name={"name"}
                        type={"text"}
                        placeholder={"John doe"}
                        label={"Name"}
                      />
                    </div>
                    <div>
                      <Input
                        name={"email"}
                        type={"email"}
                        placeholder={"johndoe@gmail.com"}
                        label={"Email"}
                      />
                    </div>
                    <div>
                      <Input
                        name={"contact_number"}
                        placeholder={"9845******"}
                        type={"tel"}
                        label={"Contact number"}
                      />
                    </div>
                    <div>
                      <Select
                        name={"category"}
                        label={"Category"}
                        selectedOption={"Category"}
                        options={categories}
                        onChange={(e) =>
                          handleCategoryChange(e.target.value, setFieldValue)
                        }
                      />
                    </div>
                  </div>
                  <div className="sm:mt-4 mt-2">
                    <Input
                      name={"address"}
                      label={"Address"}
                      placeholder={"Address"}
                      type={"text"}
                    />
                  </div>
                  <div className="mt-2">
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Products
                    </label>
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4 h-44 overflow-y-auto">
                      {filteredData.map((data) => (
                        <div
                          key={data.id}
                          className="flex items-center flex-row border-[#F58634] border-2 h-20 sm:w-auto w-auto rounded-md shadow-md p-2 cursor-pointer"
                        >
                          <div className="flex items-start justify-start m-2 h-5 w-5">
                            <input
                              value={data.id}
                              type="checkbox"
                              className="h-5 w-5 border-[#F58634] accent-[#F58634] cursor-pointer"
                              onChange={(e) =>
                                handleProductChange(
                                  data,
                                  e.target.checked,
                                  values,
                                  setFieldValue
                                )
                              }
                              checked={values.product_data.some(
                                (item) => item.product_id === data.id
                              )}
                            />
                          </div>
                          <div className="flex flex-col ml-1">
                            <h1 className="text-[#F58634] font-semibold text-sm">
                              {data.product_name}
                            </h1>
                            <h2 className="text-xs font-semibold">
                              ₹ {data.mrp}
                            </h2>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <FieldArray name="product_data">
                    {({ remove }) => (
                      <div className="mt-4 block w-full overflow-x-auto">
                        <table className="min-w-full bg-white">
                          <thead className="bg-[#F58634] text-white">
                            <tr>
                              <th className="py-2 px-4">Product Name</th>
                              <th className="py-2 px-4">Price</th>
                              <th className="py-2 px-4">Quantity</th>
                              <th className="py-2 px-4">Discount (%)</th>
                              <th className="py-2 px-4">Total</th>
                              <th className="py-2 px-4">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values.product_data.map((product, index) => {
                              const productDetails = products.find(
                                (p) => p.id === product.product_id
                              );
                              const total = calculateTotal(
                                product.quantity,
                                productDetails.mrp,
                                product.discount
                              );
                              return (
                                <tr key={index} className="bg-gray-100">
                                  <td className="border px-4 py-2">
                                    {productDetails?.product_name}
                                  </td>
                                  <td className="border px-4 py-2">
                                    ₹ {productDetails?.mrp}
                                  </td>
                                  <td className="border px-4 py-2">
                                    <Input
                                      name={`product_data.${index}.quantity`}
                                      type="number"
                                      placeholder="Quantity"
                                      onChange={(e) =>
                                        setFieldValue(
                                          `product_data.${index}.quantity`,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="border px-4 py-2">
                                    <Input
                                      name={`product_data.${index}.discount`}
                                      type="number"
                                      placeholder="Discount"
                                      value={product.discount}
                                      onChange={(e) =>
                                        setFieldValue(
                                          `product_data.${index}.discount`,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td className="border px-4 py-2">
                                    ₹ {total}
                                  </td>
                                  <td className="border px-4 py-2">
                                    <button
                                      type="button"
                                      className="text-red-500"
                                      onClick={() => remove(index)}
                                    >
                                      Remove
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </FieldArray>
                  <div className="sm:mt-4 mt-2">
                    <Button name={"Update"} type={"submit"} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuotationUpdateForm;
