import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
import Button from "../../../Components/Button/Button";
import { RxCross1 } from "react-icons/rx";

function ClientLogo({ data, onDelete }) {
  const [currentPage, setCurrentPage] = useState(0);
  const [load, setLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [itemsPerPage] = useState(10);

  const Logo = data.flatMap((slider) => slider.client_logos);

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Logo.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(Logo.length / itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <div className="mb-5">
      <div className="flex items-center justify-between mb-7">
        <div className="flex items-start justify-start">
          {load && (
            <Button name={"Remove Logos"} onClick={() => setEdit(!edit)} />
          )}
        </div>
        <div className="flex items-end justify-end">
          {totalPages > 1 && (
            <div className="flex justify-end">
              <ReactPaginate
                pageCount={totalPages}
                onPageChange={handlePageChange}
                containerClassName={"flex"}
                pageRangeDisplayed={1}
                previousLabel={<FaChevronLeft />}
                nextLabel={<FaChevronRight />}
                activeClassName={"active"}
                previousClassName={
                  "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                }
                nextClassName={
                  "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                }
                pageClassName={"mx-1"}
                pageLinkClassName={
                  "bg-gray-200 text-[#020002] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                }
                breakClassName={"mx-1"}
                breakLinkClassName={
                  "px-3 py-1 bg-gray-200 text-gray-600 rounded"
                }
              />
            </div>
          )}
        </div>
      </div>
      <div className="grid sm:grid-cols-5 grid-cols-1 sm:mt-4 mt-2 sm:gap-4 gap-2">
        {currentItems.map((image) => (
          <div className="grid gap-4" key={image.id}>
            {edit && (
              <button
                onClick={() => onDelete(image.id)}
                className="absolute -mt-2 -ml-2 bg-red-600 text-red-100 text-lg font-bold p-2 rounded-full"
              >
                <RxCross1 />
              </button>
            )}
            <img
              className={`${load ? "flex" : "hidden"} w-auto rounded shadow-md`}
              src={image.url}
              onLoad={() => setLoad(true)}
            />
            {!load && <Skeleton className="h-20 w-20 -z-50" />}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ClientLogo;
