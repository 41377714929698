import React from "react";
import { RxCross1 } from "react-icons/rx";
import File from "../../../../../Components/FormFields/File";
import TextArea from "../../../../../Components/FormFields/TextArea";
import Button from "../../../../../Components/Button/Button";
import { FieldArray, Form, Formik } from "formik";
import Select from "../../../../../Components/FormFields/Select";
import Input from "../../../../../Components/FormFields/Input";
import Radio from "../../../../../Components/FormFields/Radio";
import { FaPlus } from "react-icons/fa";

function ProductUpdateForm({
  onClose,
  selectedProduct,
  categories,
  handleUpdateProduct,
}) {
  const connectingTech = [
    { id: 1, name: "Wireless" },
    { id: 2, name: "Cellular" },
    { id: 3, name: "Bluetooth" },
  ];
  const handleConnectingTech = (value, setFieldValue) => {
    const selectedTech = connectingTech.find((tech) => tech.id == value);

    if (value) {
      setFieldValue("connecting_technology", value);
      setFieldValue("connecting_technology_name", selectedTech.name);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 my-auto mx-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-7xl w-full mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634]">
                {selectedProduct.product_name}'s details
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <Formik
            initialValues={{
              product_name: selectedProduct.product_name || "",
              brand_name: selectedProduct.brand_name || "",
              product_model_name: selectedProduct.product_model_name || "",
              connecting_technology:
                selectedProduct.connecting_technology || "",
              connecting_technology_name:
                selectedProduct.connecting_technology || "",
              mobile_application: selectedProduct.mobile_application || false,
              product_model_no: selectedProduct.product_model_no || "",
              asin_no: selectedProduct.asin_no || "",
              country: selectedProduct.country || "",
              description: selectedProduct.description || "",
              special_features: selectedProduct.special_features || [],
              features: selectedProduct.features || [],
              warranty: selectedProduct.warranty || "",
              mrp: selectedProduct.mrp || "",
              gst: selectedProduct.gst || "",
              height: selectedProduct.height || "",
              width: selectedProduct.width || "",
              depth: selectedProduct.depth || "",
              weight: selectedProduct.weight || "",
              material: selectedProduct.material || "",
              discount: selectedProduct.discount || "",
              delivery_time: selectedProduct.delivery_time || "",
              info: selectedProduct.info || "",
              category: selectedProduct.category_id || "",
              main_image: selectedProduct.main_image_url,
              other_images: selectedProduct.other_images_urls,
              brochure: selectedProduct.brochure_url,
              featured: selectedProduct.fectured || false,
              best_selling: selectedProduct.best_seller || false,
            }}
            enableReinitialize={true}
            onSubmit={handleUpdateProduct}
          >
            {({ setFieldValue, errors, values }) => (
              <Form>
                <div className="grid grid-cols-1 sm:grid-cols-5 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <Select
                      name={"category"}
                      selectedOption={"Category"}
                      label={"Category"}
                      options={categories}
                      onChange={(e) =>
                        setFieldValue("category", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <Input
                      name={"product_name"}
                      label={"Product name"}
                      placeholder={"Product name"}
                      type={"text"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"brand_name"}
                      label={"Brand name"}
                      placeholder={"Brand name"}
                      type={"text"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"product_model_name"}
                      label={"Product model name"}
                      placeholder={"Product model name"}
                      type={"text"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"product_model_no"}
                      label={"Product model number"}
                      placeholder={"Product model number"}
                      type={"text"}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <Select
                      name={"connecting_technology"}
                      label={"Connecting technology"}
                      selectedOption={"Technology"}
                      options={connectingTech}
                      onChange={(e) =>
                        handleConnectingTech(e.target.value, setFieldValue)
                      }
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Mobile application
                    </label>
                    <div className="flex mt-4">
                      <Radio
                        name={"mobile_application"}
                        id={"yes"}
                        value={true}
                        label={"Yes"}
                        onChange={() => {
                          setFieldValue("mobile_application", true);
                        }}
                      />
                      <div className="ml-2">
                        <Radio
                          name={"mobile_application"}
                          id={"no"}
                          value={false}
                          label={"No"}
                          onChange={() => {
                            setFieldValue("mobile_application", false);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Input
                      label={"Product ASIN"}
                      name={"asin_no"}
                      type={"text"}
                      placeholder={"ASIN no"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"country"}
                      placeholder={"India"}
                      type={"text"}
                      label={"Country of origin"}
                    />
                  </div>
                </div>
                <div className="mt-2 sm:mt-4">
                  <TextArea
                    name={"description"}
                    label={"Description"}
                    placeholder={"description..."}
                  />
                </div>
                <FieldArray name="features">
                  {({ push }) => (
                    <div className="mt-2 sm:mt-4">
                      <label className="block mb-2 text-sm text-gray-600">
                        Features
                      </label>
                      <button
                        type="button"
                        onClick={() => push("")}
                        className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                      >
                        <FaPlus />
                      </button>
                      <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                        {values.features.map((detail, index) => (
                          <Input
                            name={`features.${index}`}
                            type={"text"}
                            placeholder={"Feature"}
                            label={"Feature"}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </FieldArray>
                <FieldArray name="special_features">
                  {({ push }) => (
                    <div className="mt-2 sm:mt-4">
                      <label className="block mb-2 text-sm text-gray-600">
                        Special features
                      </label>
                      <button
                        type="button"
                        onClick={() => push("")}
                        className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                      >
                        <FaPlus />
                      </button>
                      <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                        {values.special_features.map((detail, index) => (
                          <Input
                            name={`special_features.${index}`}
                            type={"text"}
                            placeholder={"Special feature"}
                            label={"Special feature"}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </FieldArray>
                <div className="grid grid-cols-1 sm:grid-cols-4 gap-2 sm:gap-4 mt-2 sm:mt-4">
                  <div>
                    <Input
                      name={"mrp"}
                      type={"number"}
                      label={"MRP"}
                      placeholder={"12xx"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"gst"}
                      type={"number"}
                      label={"GST (%)"}
                      placeholder={"8"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"discount"}
                      type={"number"}
                      label={"Discont (%)"}
                      placeholder={"12"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"warranty"}
                      type={"text"}
                      label={"Warranty"}
                      placeholder={"Warranty"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"height"}
                      type={"text"}
                      label={"Height"}
                      placeholder={"Height"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"width"}
                      type={"text"}
                      label={"Width"}
                      placeholder={"Width"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"depth"}
                      type={"text"}
                      label={"Depth"}
                      placeholder={"Depth"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"weight"}
                      type={"text"}
                      label={"Weight"}
                      placeholder={"Weight"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"material"}
                      type={"text"}
                      label={"Material"}
                      placeholder={"Material"}
                    />
                  </div>
                  <div>
                    <Input
                      name={"delivery_time"}
                      type={"text"}
                      label={"Delivery time"}
                      placeholder={"1 hour"}
                    />
                  </div>
                  <div>
                    <File
                      name={"main_image"}
                      type={"file"}
                      accept={"Image/*"}
                      label={"Main image"}
                      onChange={(e) =>
                        setFieldValue("main_image", e.target.files[0])
                      }
                      multiple={false}
                    />
                  </div>
                  <div>
                    <File
                      name={"brochure"}
                      type={"file"}
                      accept={".pdf"}
                      label={"Brochure"}
                      onChange={(e) =>
                        setFieldValue("brochure", e.target.files[0])
                      }
                      multiple={false}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Best seller ?
                    </label>
                    <div className="flex mt-4">
                      <Radio
                        name={"best_selling"}
                        id={"yes"}
                        value={true}
                        label={"Yes"}
                        onChange={() => {
                          setFieldValue("best_selling", true);
                        }}
                      />
                      <div className="ml-2">
                        <Radio
                          name={"best_selling"}
                          id={"no"}
                          value={false}
                          label={"No"}
                          onChange={() => {
                            setFieldValue("best_selling", false);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="block mb-2 text-sm text-gray-600 ">
                      Featured ?
                    </label>
                    <div className="flex mt-4">
                      <Radio
                        name={"featured"}
                        id={"yes"}
                        value={true}
                        label={"Yes"}
                        onChange={() => {
                          setFieldValue("featured", true);
                        }}
                      />
                      <div className="ml-2">
                        <Radio
                          name={"featured"}
                          id={"no"}
                          value={false}
                          label={"No"}
                          onChange={() => {
                            setFieldValue("featured", false);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 sm:mt-4">
                  <TextArea name={"info"} placeholder={"INFO"} label={"Info"} />
                </div>
                <div className="mt-2 sm:mt-4">
                  <Button type={"submit"} name={"Update"} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default ProductUpdateForm;
