import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import { PiCurrencyInrDuotone } from "react-icons/pi";
import axios from "axios";
import { State } from "country-state-city";
import AddAddress from "./UserComponents/Model/AddAddress";
import { CartContext } from "../../CartContext";
import { LoderContext } from "../../App";
import { useNavigate, useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";

function Addresspage() {
  const navigate = useNavigate();
  const { isLoading } = useContext(LoderContext);
  const [openAddressModel, setOpenAddressModel] = useState(false);
  const { getCartitems, totalPrice, discountPrice, currentUser } =
    useContext(CartContext);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(currentUser);
  const { Checkout } = useParams();

  useEffect(() => {
    if (isLoading.unauthorized) {
      navigate("/");
    }
    if (totalPrice === 0) {
      navigate("/Checkout/cart");
    }
    // Load addresses from cookies when component mounts
    const loadedAddresses = JSON.parse(getCookie("userAddress")) || [];
    setAddresses(loadedAddresses);
    const currentUserAddress = {
      Name: currentUser?.name,
      Mobile_no: currentUser?.mobile_no,
      Delivery_address: currentUser?.delivery_address,
      Locality: currentUser?.locality || "",
      City: currentUser?.city,
      State: currentUser?.state,
      Pin_code: currentUser?.pin_code,
    };
    setSelectedAddress(currentUserAddress);
    let expires = "";
    const date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
    document.cookie = `${"selectedAddress"}=${JSON.stringify(
      currentUserAddress
    )}${expires}; path=/`;
  }, [currentUser, isLoading.unauthorized, navigate]);

  function AddAddressToArray(name, values, days) {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    const newAddress = {
      Name: values?.fullName,
      Delivery_address: values?.address,
      Locality: values?.locality,
      City: values?.city,
      State: State.getStateByCodeAndCountry(values?.state, "IN")?.name,
      Pin_code: values?.pincode,
      Mobile_no: values?.mobile,
      email: localStorage.getItem("email"),
    };

    const updatedAddresses = [...addresses, newAddress];
    setAddresses(updatedAddresses);
    // Update cookies as well
    document.cookie = `${name}=${JSON.stringify(
      updatedAddresses
    )}${expires}; path=/`;

    setOpenAddressModel(false);
  }

  function getCookie(name) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  const handleRemoveAddress = (index) => {
    const updatedAddresses = [...addresses];
    updatedAddresses.splice(index, 1);
    setAddresses(updatedAddresses);
    // Update cookies as well
    document.cookie = `userAddress=${JSON.stringify(updatedAddresses)}; path=/`;
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 py-8 mt-16">
        <div className="text-sm text-gray-400 flex items-center justify-center ">
          <span> ---- </span>
          <h1
            onClick={() => {
              navigate("/Checkout/cart");
            }}
            className={`${
              Checkout === "cart" ? `text-lg text-[#242c44]` : ``
            } cursor-pointer`}
          >
            Cart
          </h1>
          <span> ---- </span>
          <h1
            onClick={() => {
              navigate("/Checkout/address");
            }}
            className={`${
              Checkout === "address" ? `text-lg text-[#242c44]` : ``
            } cursor-pointer`}
          >
            Address
          </h1>
          <span> ---- </span>
          <h1
            onClick={() => {
              navigate("/Checkout/payment");
            }}
            className={`${
              Checkout === "payment" ? `text-lg text-[#242c44]` : ``
            } cursor-pointer`}
          >
            Payment
          </h1>
        </div>
        <div className="p-8">
          <div className="flex max-sm:flex-col sm:items-center justify-between  w-full xl:w-2/3">
            <h1 className="text-xl text-gray-800 font-semibold">
              Select Delivery Address
            </h1>
            <button
              onClick={() => {
                setOpenAddressModel(true);
              }}
              className="p-2 max-sm:mt-4 w-fit bg-white border-2 border-[#242c44] rounded-md hover:bg-[#242c44] hover:text-white"
            >
              Add New Address
            </button>
          </div>

          <div className="flex flex-col w-full xl:w-2/3 mt-4">
            <h1 className="text-md text-gray-400 font-semibold mb-4">
              Default Address
            </h1>
            <div className="text-gray-800 flex p-4 border hover:ring hover:ring-orange-300 hover:ring-opacity-40 rounded-md shadow-sm">
              <div className="w-4 h-4 mt-4">
                <input
                  type="radio"
                  name="address"
                  id="default_address"
                  className="accent-purple-700 h-full w-full"
                  defaultChecked
                  value={currentUser}
                  onChange={() => {
                    const currentUserAddress = {
                      Delivery_address: currentUser?.delivery_address,
                      Locality: currentUser?.locality || "",
                      City: currentUser?.city,
                      State: currentUser?.state,
                      Pin_code: currentUser?.pin_code,
                    };
                    setSelectedAddress(currentUserAddress);
                    let expires = "";
                    const date = new Date();
                    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
                    expires = "; expires=" + date.toUTCString();
                    document.cookie = `${"selectedAddress"}=${JSON.stringify(
                      currentUserAddress
                    )}${expires}; path=/`;
                  }}
                />
              </div>
              <div className="p-4 w-full">
                <h1 className="text-md mb-4 font-semibold">
                  {currentUser?.name}
                </h1>
                <h1 className="text-md text-gray-500">
                  {currentUser?.delivery_address}
                  {currentUser?.locality ? `, ${currentUser?.locality}` : null}
                </h1>
                <h1 className="text-md text-gray-500 mb-4">
                  {currentUser?.city}, {currentUser?.state} -{" "}
                  {currentUser?.pin_code}
                </h1>
                <h1 className="text-md font-semibold mb-2">
                  <span className="text-gray-500 font-normal">Mobile: </span>
                  {currentUser?.mobile_no}
                </h1>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full xl:w-2/3 mt-4">
            <h1 className="text-md text-gray-400 font-semibold mb-4">
              Other Addresses
            </h1>
            {addresses.length !== 0 ? (
              addresses
                .filter((data) => data.email === localStorage.getItem("email"))
                .map((address, index) => (
                  <div
                    key={index}
                    className="text-gray-800 flex p-4  hover:ring hover:ring-orange-300 hover:ring-opacity-40 rounded-md shadow-sm mb-2"
                  >
                    <div className="w-4 h-4 mt-4">
                      <input
                        type="radio"
                        name="address"
                        id={`address_${index}`}
                        className="accent-purple-700 h-full w-full"
                        value={address}
                        onChange={() => {
                          setSelectedAddress(address);
                          let expires = "";
                          const date = new Date();
                          date.setTime(
                            date.getTime() + 30 * 24 * 60 * 60 * 1000
                          );
                          expires = "; expires=" + date.toUTCString();
                          document.cookie = `${"selectedAddress"}=${JSON.stringify(
                            address
                          )}${expires}; path=/`;
                        }}
                      />
                    </div>
                    <div className="p-4 w-full">
                      <h1 className="text-md mb-4 font-semibold">
                        <span>{address?.Name}</span>
                      </h1>
                      <h1 className="text-md text-gray-500">
                        {address?.Delivery_address}
                        {address?.Locality ? `, ${address.Locality}` : null}
                      </h1>
                      <h1 className="text-md text-gray-500 mb-4">
                        {address?.City}, {address?.State} - {address.Pin_code}
                      </h1>
                      <h1 className="mb-2 flex items-center justify-between">
                        <span className="font-normal">
                          <span className="text-gray-600">Mobile: </span>
                          <span className="text-md font-semibold">
                            {address.Mobile_no}
                          </span>
                        </span>
                        <div
                          onClick={() => {
                            handleRemoveAddress(index);
                          }}
                          className="text-red-600 flex items-center justify-center cursor-pointer"
                        >
                          <MdDelete />
                          Remove
                        </div>
                      </h1>
                    </div>
                  </div>
                ))
            ) : (
              <div className="flex flex-col w-full md:w-2/3">
                <h1 className="text-md text-gray-400 font-semibold mb-4">
                  No other addresses found.
                </h1>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="w-full  xl:w-1/3 xl:fixed  top-28 right-4 bg-red-300">
        <div className="h-auto w-full p-8 rounded-xl shadow-xl bg-gray-50 text-gray-800">
          <div className="mb-4">
            <span className="text-xl font-semibold text-gray-800">
              PRICE DETAILS
            </span>
          </div>
          <div className="flex items-center justify-between mb-2">
            <span className="text-gray-800">Total MRP</span>
            <span className="flex items-center text-gray-800">
              <PiCurrencyInrDuotone />
              {totalPrice}
            </span>
          </div>
          <div className="flex items-center justify-between mb-2">
            <span className="text-gray-800">Discount On MRP</span>
            <span className="flex items-center text-green-600">
              - <PiCurrencyInrDuotone />
              {discountPrice}
            </span>
          </div>
          <div className="flex items-center justify-between mb-2">
            <span className="text-gray-800">Coupon Discount</span>
            {localStorage.getItem("Coupon") ? (
              <span className="flex items-center text-green-600">
                - <PiCurrencyInrDuotone />
                {localStorage.getItem("Coupon")}
              </span>
            ) : (
              "---"
            )}
          </div>
          <div className="flex items-center justify-between mb-2">
            <span className="text-gray-800">Delivery Charges</span>
            <span className="flex items-center text-green-600">FREE</span>
          </div>
          <div className="flex items-center justify-between mb-2 border-t-2 pt-2">
            <span className="text-gray-800 text-lg font-semibold">
              Total Amount
            </span>
            <span className="flex items-center text-lg font-semibold text-gray-800">
              <PiCurrencyInrDuotone />
              {totalPrice -
                discountPrice -
                (localStorage.getItem("Coupon") || 0)}
            </span>
          </div>
          <div className="mt-4">
            <button
              onClick={() => {
                navigate("/Checkout/payment");
              }}
              type="submit"
              className="py-2 w-full border-2 border-[#242c44] text-gray-800 hover:bg-[#242c44] hover:text-white duration-150 ease-in"
            >
              PLACE ORDER
            </button>
          </div>
        </div>
      </div>
      {openAddressModel && (
        <AddAddress
          setOpenAddressModel={setOpenAddressModel}
          AddAddressToArray={AddAddressToArray}
        />
      )}
    </div>
  );
}

export default Addresspage;
