import React, { useRef } from "react";
import logo from "../../../../Assets/Images/SNT-logo-2022-removebg-preview.png";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { MdEmail, MdSend } from "react-icons/md";
import jsPDF from "jspdf";
import { BsDownload } from "react-icons/bs";
import html2canvas from "html2canvas";
import { uid } from "uid";
import axios from "axios";
import toast from "react-hot-toast";

function QuaotationDetail({ quotation, onClose }) {
  const quotationRef = useRef(null);

  const downloadQuotation = () => {
    html2canvas(quotationRef.current, { scale: 1 }).then((canvas) => {
      const pdf = new jsPDF("portrait", "mm", "a4");
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 10, 10);

      pdf.save(`Quotation_${quotation.name}_${uid()}.pdf`);
    });
  };

  const calculateTotalAmount = (price, gst) => {
    const priceWithGst = price * (1 + gst / 100);
    return priceWithGst;
  };

  const calculateTotalAmountForAllItems = () => {
    return quotation.quotation_items.reduce((total, item) => {
      const itemTotal = calculateTotalAmount(
        item.discounted_price,
        item.product.gst
      );
      return total + itemTotal;
    }, 0);
  };

  const totalAmount = calculateTotalAmountForAllItems().toFixed(2);

  const sendMail = () => {
    const loadingToastId = toast.loading("Sending email...", {
      autoClose: false,
    });

    html2canvas(quotationRef.current, { scale: 1 })
      .then((canvas) => {
        const pdf = new jsPDF("portrait", "mm", "a4");
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 0, 0, 210, 297);

        const pdfBlob = pdf.output("blob");

        const formData = new FormData();
        formData.append("quotation_id", quotation.id);
        formData.append("pdf", pdfBlob, "quotation.pdf");
        formData.append("Content-Type", "application/pdf");

        axios
          .post(
            `api/v1/quotations/${quotation.id}/send_quotation_details`,
            formData
          )
          .then((res) => {
            if (res.status === 200) {
              toast.dismiss(loadingToastId);
              toast.success(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.dismiss(loadingToastId);
            toast.error(err.message);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col items-center justify-center my-auto z-50"
      >
        <div className="flex flex-row mt-5">
          <div onClick={(e) => e.stopPropagation()}>
            <button
              className="flex flex-row px-5 py-2 border-2 border-[#F58634] text-[#F58634] rounded-full hover:bg-[#F58634] hover:text-white duration-150 ease-in bg-white"
              onClick={downloadQuotation}
            >
              <span className="mr-2 mt-1">
                <BsDownload />
              </span>
              Download Quotation
            </button>
          </div>
          <div className="ml-5" onClick={(e) => e.stopPropagation()}>
            <button
              onClick={sendMail}
              className="flex flex-row px-5 py-2 border-2 border-[#F58634] text-[#F58634] rounded-full hover:bg-[#F58634] hover:text-white duration-150 ease-in bg-white"
            >
              <span className="mr-2 mt-1">
                <MdSend />
              </span>
              Mail Quotation
            </button>
          </div>
        </div>
        <div
          ref={quotationRef}
          className="bg-white p-4 rounded shadow-md my-5 h-[310mm] w-[210mm] mb-10 sm:mt-5 mt-5 mr-5"
        >
          <div className="flex items-center justify-between border-b-2 border-gray-200">
            <div className="flex items-start justify-start">
              <img src={logo} className="w-52" />
            </div>
            <div className="flex items-end justify-end flex-col  mb-2 w-5/12">
              <h2 className="w-full text-xs flex mt-2">
                <span className="mr-2 text-lg text-[#F58634]">
                  <FaPhone />
                </span>
                +91 79 66140001/2,+91 88664 56412,+91 92277 63361
              </h2>
              <h2 className="w-full text-xs flex mt-2">
                <span className="mr-2 text-lg text-[#F58634]">
                  <MdEmail />
                </span>
                navkar@navkartrade.com
              </h2>
              <h2 className="w-full text-xs flex mt-2">
                <span className="mr-2 text-lg text-[#F58634]">
                  <FaLocationDot />
                </span>
                918/919, Solaris Business Hub, Opp. Jain Mandir, Nr. Bhuyangdev
                Cross Road, Ahmedabad-63.
              </h2>
            </div>
          </div>
          <div className="flex items-center justify-center my-3">
            <h2 className="text-xl text-[#F58634] font-semibold">Quotation</h2>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-start justify-start mt-4">
              <h2 className="text-gray-500 font-semibold text-base">
                To: {quotation.name}
              </h2>
            </div>
            <div className="flex items-end justify-end mt-4">
              <h2 className="text-gray-500 font-semibold text-base">
                Date:
                {new Date(quotation.created_at).toLocaleString("en-IN", {
                  timeZone: "Asia/Kolkata",
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </h2>
            </div>
          </div>
          <div className="mt-4 block w-full">
            <table className="min-w-full bg-white text-sm">
              <thead className="bg-[#F58634] text-white">
                <tr>
                  <th className="py-1 px-3">Model number</th>
                  <th className="py-1 px-3">Product Name</th>
                  <th className="py-1 px-3">Quantity</th>
                  <th className="py-1 px-3">Price without GST</th>
                  <th className="py-1 px-3">Discount (%)</th>
                  <th className="py-1 px-3">GST(%)</th>
                  <th className="py-1 px-3">Total</th>
                </tr>
              </thead>
              <tbody>
                {quotation.quotation_items &&
                  quotation.quotation_items.map((item, index) => (
                    <tr key={index} className="bg-gray-100">
                      <td className="border px-3 py-1">
                        {item.product.product_model_no}
                      </td>
                      <td className="border px-3 py-1">
                        {item.product.product_name}
                      </td>
                      <td className="border px-3 py-1">{item.quantity}</td>
                      <td className="border px-3 py-1">
                        ₹{Number(item.product.mrp).toLocaleString()}
                      </td>
                      <td className="border px-3 py-1">
                        {parseInt(item.discount)}
                      </td>
                      <td className="border px-3 py-1">{item.product.gst}</td>
                      <td className="border px-3 py-1">
                        ₹
                        {calculateTotalAmount(
                          item.discounted_price,
                          item.product.gst
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  ))}
                <tr className="bg-gray-100 w-full">
                  <td className="border px-3 py-1" colSpan="6">
                    <div className="flex items-end justify-end">
                      <h2 className="font-bold text-lg">Total amount</h2>
                    </div>
                  </td>
                  <td className="border px-3 py-1">
                    ₹{Number(totalAmount).toLocaleString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuaotationDetail;
