import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import Cardview1 from "../../Components/CardViews.jsx/Cardview1";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { LoderContext } from "../../App";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import logo from "../../Assets/Images/SNT-logo-2022-removebg-preview.png";
import { useRef } from "react";
import Slider from "react-slick";
import bgimage from "../../Assets/Images/Shree Navkar Tradecom.png";
import Testimonials from "./UserComponents/Cardview/Testimonials";
import { FaCircleChevronRight } from "react-icons/fa6";
function Home({ setStatusFilter, statusFilter }) {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const { isLoading, setIsLoading } = useContext(LoderContext);
  const [allSliderimages, setSliderimages] = useState([]);
  const [allClientLogos, setClientLogos] = useState([]);
  const [allTestimonials, setTestimonials] = useState([]);
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    // rows: 2,
    // slidesPerRow: 2
  };
  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading({ loader: true });
      try {
        const response = await axios.get("/api/v1/products");
        if (response.status === 200) {
          setProducts(response.data.products);
          setIsLoading({ loader: false });
        }
      } catch (error) {
        console.error("Error fetching products ok :", error);
        if (error?.response?.status === 404) {
          setIsLoading({ notfound: true });
        }
        if (error?.code === "ERR_NETWORK") {
          setIsLoading({ notfound: true, loader: false });
        }
      }
    };
    const fetchSliderImages = async () => {
      setIsLoading({ loader: true });
      try {
        const response = await axios.get("/api/v1/pages");
        if (response.status === 200) {
          console.log(response);
          setSliderimages(response.data.pages.flatMap((E) => E.images));
          setClientLogos(response.data.pages.flatMap((E) => E.client_logos));
          setIsLoading({ loader: false });
        }
      } catch (error) {
        console.error("Error fetching products ok :", error);
        if (error?.response?.status === 404) {
          setIsLoading({ notfound: true });
        }
        if (error?.code === "ERR_NETWORK") {
          setIsLoading({ notfound: true, loader: false });
        }
      }
    };
    const fetchTestimonials = async () => {
      setIsLoading({ loader: true });
      try {
        const response = await axios.get("/api/v1/testimonials");
        if (response.status === 200) {
          console.log("tt" + response?.data?.testimonials);
          setTestimonials(response.data?.testimonials);
          setIsLoading({ loader: false });
        }
      } catch (error) {
        console.error("Error fetching products ok :", error);
        if (error?.response?.status === 404) {
          setIsLoading({ notfound: true });
        }
        if (error?.code === "ERR_NETWORK") {
          setIsLoading({ notfound: true, loader: false });
        }
      }
    };
    fetchTestimonials();
    fetchSliderImages();
    fetchProducts();
  }, [navigate, setIsLoading]);

  const handleViewMore = (id) => {
    navigate(`/AllProducts/ViewProducts/${id}`);
  };
  const container = useRef();
  return (
    <div className="min-h-screen bg-[#ffffff]">
      <Navbar />
      {isLoading.notfound && (
        <div
          ref={container}
          className="container  items-center justify-center flex  mx-auto px-4 py-8 mt-16 "
        >
          <div className=" text-6xl text-[#242c44]">404 Page Not Found</div>
        </div>
      )}
      <div className=" mx-auto px-4 py-8 ">
        <AnimatePresence mode="wait">
          <motion.div
            key="products"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.9 }}
          >
            {isLoading.loader ? (
              <>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-16">
                  {Array.from({ length: 5 }, (_, index) => (
                    <div
                      key={index}
                      className="max-w-sm mx-auto rounded-md overflow-hidden shadow-md bg-white transition-transform duration-300 hover:shadow-lg hover:-translate-y-2 py-0"
                    >
                      <div className="relative group">
                        <Skeleton height={160} width={300} />
                      </div>
                      <div className="p-4 h-56 flex flex-col justify-between">
                        <h3 className="text-lg font-semibold text-gray-800 ">
                          <Skeleton width={150} />
                        </h3>
                        <h4 className="text-md font-semibold text-gray-600 ">
                          <Skeleton width={150} />
                        </h4>
                        <p className="text-gray-600 text-sm mb-2 line-clamp-3">
                          <Skeleton width={200} />
                        </p>
                        <div className="flex items-center justify-between">
                          <span className="font-semibold text-lg text-gray-800 flex items-center justify-center">
                            <Skeleton width={100} />
                          </span>
                          <span>
                            <Skeleton width={80} />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div>
                <div className="container mx-auto p-4 mt-16">
                  <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    showArrows={true}
                    interval={3000}
                    transitionTime={500}
                    showStatus={false}
                  >
                    {allSliderimages.flatMap((image) => (
                      <div key={image.id} className="relative">
                        <img
                          src={image?.url}
                          alt="Product"
                          className="h-48 sm:h-64 md:h-80 lg:h-96 xl:h-[500px] object-fill w-full rounded-lg shadow-lg"
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
                <div className="p-4 mt-16">
                  <div className="flex items-center justify-between flex-wrap gap-4">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-[#242c44] h-fit">
                      Best Seller
                    </h1>
                    <h1
                      onClick={() => {
                        setStatusFilter("bestseller");
                        navigate("/AllProducts");
                      }}
                      className="text-lg sm:text-xl md:text-xl text-[#242c44] font-semibold cursor-pointer hover:border-b-2 p-2 ease-in duration-200 border-[#242c44] bg-slate-50 flex items-center"
                    >
                      <span>View All {statusFilter}</span>
                      <span className="flex ml-2">
                        <FaCircleChevronRight />
                      </span>
                    </h1>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-8">
                    {products
                      .slice(0, 8)
                      .filter((data) => data.bestseller === true)
                      .map((product) => (
                        <Cardview1
                          key={product.id}
                          src={product.main_image_url}
                          productname={product.product_name}
                          category={product.category}
                          description={product.description}
                          mrp={product.mrp}
                          id={product.id}
                          handleViewMore={handleViewMore}
                        />
                      ))}
                  </div>
                </div>
                <div className="p-4 mt-16">
                  <div className="flex items-center justify-between flex-wrap gap-4">
                    <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-[#242c44] h-fit">
                      Best Seller
                    </h1>
                    <h1
                      onClick={() => {
                        setStatusFilter("featured");
                        navigate("/AllProducts");
                      }}
                      className="text-lg sm:text-xl md:text-xl text-[#242c44] font-semibold cursor-pointer hover:border-b-2 p-2 ease-in duration-200 border-[#242c44] bg-slate-50 flex items-center"
                    >
                      <span>View All</span>
                      <span className="flex ml-2">
                        <FaCircleChevronRight />
                      </span>
                    </h1>
                  </div>

                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-8">
                    {products
                      .slice(0, 8)
                      .filter((data) => data.featured === true)
                      .map((product) => (
                        <Cardview1
                          key={product.id}
                          src={product.main_image_url}
                          productname={product.product_name}
                          category={product.category}
                          description={product.description}
                          mrp={product.mrp}
                          id={product.id}
                          handleViewMore={handleViewMore}
                        />
                      ))}
                  </div>
                </div>
                <div className="relative mt-16">
                  <img src={bgimage} alt="imageofsnt" className="w-full" />
                </div>
                <div className="p-4 mt-16">
                  <h1 className="text-4xl font-semibold text-[#242c44]  h-fit w-fit p-2">
                    Our believers
                  </h1>
                  <Slider {...settings}>
                    {allClientLogos.flatMap((image) => (
                      <div
                        key={image.id}
                        className="relative p-4  bg-opacity-25"
                      >
                        <img
                          src={image?.url}
                          alt="Product"
                          className="w-32 h-32 object-fill rounded-full "
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className="p-4 mt-16">
                  <h1 className="text-4xl font-semibold text-[#242c44] h-fit w-fit p-2 mb-8">
                    Testimonials
                  </h1>
                  <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    showArrows={true}
                    interval={3000}
                    transitionTime={500}
                    showStatus={false}
                  >
                    {allTestimonials.map((test) => (
                      <div
                        key={test.id}
                        className="flex justify-center items-center p-4"
                      >
                        <Testimonials
                          content={test.content}
                          image={test.user_profile_url}
                          name={test.user_name}
                        />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            )}
          </motion.div>
        </AnimatePresence>
      </div>
      <footer className="flex flex-col   relative sm:flex-row w-full justify-between items-start sm:items-center bg-slate-100 p-8 rounded-md shadow-lg">
        <div className="mb-8 sm:mb-0">
          <img
            src={logo}
            alt="Shree Narkar Tradecom Logo"
            className="h-10 w-auto"
          />
          <p className="flex flex-col mt-2 text-gray-600">
            <span>© 2021 Shree Narkar Tradecom.</span>
            <span>All rights reserved.</span>
          </p>
        </div>
        <div className="mb-8 sm:mb-0">
          <h1 className="text-lg font-semibold text-gray-800">Get in Touch</h1>
          <p className="flex flex-col mt-2 text-gray-600">
            <span>918/919, Solaris Business Hub, Opp. Jain Mandir,</span>
            <span>Nr. Bhuyangdev Cross Road, Ahmedabad-63.</span>
          </p>
          <p className="mt-2 text-gray-600">
            <Link to="mailto:navkar@navkartrade.com">
              E: navkar@navkartrade.com
            </Link>
            <br />
            <Link to="tel:+917966140001">L: +91 79 66140001/2</Link>
            <br />
            <Link to="tel:+918866456412">
              M: +91 88664 56412 / +91 92277 63361
            </Link>
          </p>
        </div>
        <div>
          <h1 className="text-lg font-semibold text-gray-800">Learn More</h1>
          <p className="flex flex-col mt-2 text-gray-600">
            <Link to="/about-us">About Us</Link>
            <Link to="/our-services">Our Services</Link>
            <Link to="/products">Products</Link>
            <Link to="/terms-of-use">Terms of Use</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Home;
