import React from "react";

function Button({ type, onClick, name, disable }) {
  return (
    <div>
      <button
        type={type}
        onClick={onClick}
        disabled={disable}
        className="px-5 py-2 border-2 border-[#F58634] text-[#F58634] rounded-full hover:bg-[#F58634] hover:text-white duration-150 ease-in"
      >
        {name}
      </button>
    </div>
  );
}

export default Button;
