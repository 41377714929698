import { Navigate } from "react-router-dom";

function UserPrivateRoute({ children }) {
  const isAuthenticated = localStorage.getItem("isAuthenticated");
  const userRole = localStorage.getItem("userRole");

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  if (userRole === "customer" && isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
}

export default UserPrivateRoute;
