import React, { useContext, useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import { HiOutlineMenu } from "react-icons/hi";
import logo from "../Assets/Images/SNT-logo-2022-removebg-preview.png";
import { CartContext } from "../CartContext";
import { FaCartShopping, FaCircleChevronLeft } from "react-icons/fa6";
import { IoLogOut } from "react-icons/io5";

function Navbar() {
  const { totleQuantity } = useContext(CartContext);
  const [toggleView, setToggleView] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("email"));
  const { Page, Checkout } = useParams();
  useEffect(() => {
    setIsLoggedIn(!!localStorage.getItem("email"));
  }, []);
  const tabs = [
    { id: 1, name: "Home", path: "" },
    { id: 2, name: "Products", path: "AllProducts" },
    { id: 3, name: "About", path: "https://www.navkartrade.com/about.php" },
    { id: 4, name: "Contact", path: "https://www.navkartrade.com/contact.php" },
    {
      id: 5,
      name: "Profile",
      path: `UserProfile/${Page ? Page : `overview`}`,
      icon: <FaRegUserCircle />,
      auth: true,
    },
    {
      id: 6,
      name: "Cart",
      path: `Checkout/${Checkout ? Checkout : "cart"}`,
      icon: <FaCartShopping size={20} />,
      auth: true,
    },
    { id: 7, name: "Login", path: "Login", auth: false },
    {
      id: 8,
      name: "Logout",
      path: "Logout",
      icon: <IoLogOut size={20} />,
      auth: true,
    },
  ];

  const ToggleView = () => {
    setToggleView(!toggleView);
  };

  const handleLogout = () => {
    localStorage.clear();
    setIsLoggedIn(false);
  };

  return (
    <nav className="text-[#242c44] bg-[#ffffff] shadow-lg fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div
            className={`sm:flex-shrink-0 sm:w-auto p-4 ${
              toggleView ? `flex items-center justify-end w-full` : null
            } flex items-center justify-between w-full`}
          >
            <HiOutlineMenu
              className="sm:hidden flex cursor-pointer"
              size={28}
              onClick={ToggleView}
            />
            <img className="h-10 w-auto" src={logo} alt="Logo" />
          </div>
          <div className="hidden sm:block max-md:w-full">
            <ul className="flex lg:space-x-4 md:space-x-1 w-full items-center justify-between">
              {tabs.map(
                (tab) =>
                  (tab.auth === undefined || tab.auth === isLoggedIn) && (
                    <li key={tab.id}>
                      {tab.path.startsWith("http") ? (
                        <a
                          href={tab.path}
                          className="hover:bg-[#242C44] hover:text-white flex items-center justify-center px-3 py-2 rounded-md text-sm font-medium"
                        >
                          <span className="flex items-center justify-center">
                            {tab.icon && (
                              <span className="mr-2">{tab.icon}</span>
                            )}
                            {<span>{tab.name}</span>}
                          </span>
                        </a>
                      ) : (
                        <NavLink
                          to={`${tab.path === "Logout" ? `/` : `/${tab.path}`}`}
                          className={({ isActive }) =>
                            `${
                              tab.name === "Logout"
                                ? isActive
                                  ? ""
                                  : ""
                                : isActive
                                ? "border-b-2 border-[#242c44]"
                                : ""
                            } ${
                              tab.name === "Cart"
                                ? ""
                                : "hover:bg-[#242C44] hover:text-white"
                            } flex items-center justify-center  md:px-3 px-1 py-2  rounded-md text-sm font-medium`
                          }
                          onClick={
                            tab.name === "Logout" ? handleLogout : undefined
                          }
                        >
                          <span className="flex items-center justify-center">
                            {tab.icon && tab.name !== "Logout" && (
                              <span className="mr-2">{tab.icon}</span>
                            )}
                            {tab.name === "Cart" ? (
                              <span className="relative bottom-4 right-2 md:bottom-4 md:right-2 bg-[#242c44] text-[#ffffff] px-2 py-1 rounded-full">
                                {totleQuantity}
                              </span>
                            ) : (
                              <span>{tab.name}</span>
                            )}
                          </span>
                        </NavLink>
                      )}
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      </div>
      {toggleView && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-start">
          <div className="w-3/4 bg-[#ffffff] h-full p-6 shadow-lg">
            <div className="flex items-center justify-between mb-6">
              <img className="h-10 w-auto" src={logo} alt="Logo" />
              <FaCircleChevronLeft
                className="cursor-pointer"
                size={28}
                onClick={ToggleView}
              />
            </div>
            <ul className="flex flex-col space-y-4">
              {tabs.map(
                (tab) =>
                  (tab.auth === undefined || tab.auth === isLoggedIn) && (
                    <li key={tab.id}>
                      {tab.path.startsWith("http") ? (
                        <a
                          href={tab.path}
                          className="text-[#242c44] bg-gray-200 hover:bg-gray-400 hover:text-gray-100 px-4 py-2 rounded-md text-sm font-semibold flex items-center transition-all duration-300 "
                          onClick={ToggleView}
                        >
                          <span className="flex items-center justify-center">
                            {tab.icon && (
                              <span className="mr-2">{tab.icon}</span>
                            )}
                            {tab.name === "Cart" ? (
                              <span className="relative bottom-4 right-2 md:bottom-4 md:right-2 bg-[#242c44] text-[#ffffff] px-2 py-1 rounded-full">
                                {totleQuantity}
                              </span>
                            ) : (
                              <span>{tab.name}</span>
                            )}
                          </span>
                        </a>
                      ) : (
                        <NavLink
                          to={`${tab.path === "Logout" ? `/` : `/${tab.path}`}`}
                          className={({ isActive }) =>
                            `${
                              tab.name === "Logout"
                                ? `text-[#242c44] bg-gray-200 hover:bg-gray-400 hover:text-gray-100 px-4 py-2 rounded-md text-sm font-semibold flex items-center transition-all duration-300`
                                : isActive
                                ? " bg-[#242c44] text-white shadow-2xl px-4 py-2 rounded-md text-sm font-semibold flex items-center"
                                : "text-[#242c44] bg-gray-200 hover:bg-gray-400 hover:text-gray-100 px-4 py-2 rounded-md text-sm font-semibold flex items-center transition-all duration-300"
                            } `
                          }
                          onClick={() => {
                            ToggleView();
                            if (tab.name === "Logout") handleLogout();
                          }}
                        >
                          <span className="flex items-center justify-center">
                            {tab.icon && (
                              <span className="mr-2">{tab.icon}</span>
                            )}
                            {tab.name === "Cart" ? (
                              <span className="relative bottom-4 right-2 md:bottom-4 md:right-2 bg-[#242c44] text-[#ffffff] px-2 py-1 rounded-full">
                                {totleQuantity}
                              </span>
                            ) : (
                              <span>{tab.name}</span>
                            )}
                          </span>
                        </NavLink>
                      )}
                    </li>
                  )
              )}
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
