import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import SideBar from "./AdminComponents/SideBar";
import QuotationForm from "./AdminComponents/Modal/Form/QuotationForm";
import QuaotationDetail from "./AdminComponents/Modal/QuaotationDetail";
import Loader from "./AdminComponents/Loader";

function AddQuotation() {
  const view = useSelector((state) => state.navbar.value);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [quotations, setQuotations] = useState([]);
  const [loader, setLoader] = useState(false);
  const [viewQuotation, setViewQuotation] = useState(false);
  const [detail, setDetail] = useState("");
  const [filteredData, setFilteredData] = useState(products);

  useEffect(() => {
    getProductData();
    fetchCategories();
    getQuotations();
  }, []);

  const fetchCategories = () => {
    axios
      .get(`api/v1/categories`)
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleViewQuotation = (response, id) => {
    const selectedQuotation = response.find((data) => data.id === id);

    setDetail(selectedQuotation);
    setViewQuotation(true);
  };

  const getProductData = () => {
    axios
      .get(`api/v1/products`)
      .then((res) => {
        setProducts(res.data.products);
        setLoader(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getQuotations = () => {
    return axios
      .get(`api/v1/quotations`)
      .then((res) => {
        setQuotations(res.data);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCategoryChange = (value, setFieldValue) => {
    const filterByCategory = products.filter(
      (product) => parseInt(product.category_id) === parseInt(value)
    );
    if (value === "Category") {
      setFieldValue("category", value);
      setFilteredData(products);
    } else {
      setFieldValue("category", value);
      setFilteredData(filterByCategory);
    }
  };

  const addQuotation = (value, { resetForm }) => {
    axios
      .post(`api/v1/quotations`, {
        quotation: {
          name: value.name,
          email: value.email,
          mobile_number: value.contact_number,
          quotation_items: value.product_data,
          address: value.address,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          resetForm();
          toast.success(res.data.message);
          setFilteredData(products);
          getQuotations()
            .then((response) => {
              const data = response.find(
                (quotation) => quotation.id === res.data.quotation_id
              );
              handleViewQuotation(response, data.id);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  return (
    <div>
      <SideBar />
      <div
        className={`h-screen p-7 ${
          view ? "ml-40 ease-in duration-150" : "ml-0 ease-in duration-150"
        }`}
      >
        {loader ? (
          <div className="flex items-center justify-center sm:ml-20 ml-0 mt-5">
            <QuotationForm
              filteredData={filteredData}
              products={products}
              categories={categories}
              onSubmit={addQuotation}
              handleCategoryChange={handleCategoryChange}
              setFilteredData={setFilteredData}
            />
          </div>
        ) : (
          <div>
            <Loader />
          </div>
        )}
      </div>
      {viewQuotation && (
        <QuaotationDetail
          onClose={() => setViewQuotation(false)}
          quotation={detail}
        />
      )}
    </div>
  );
}

export default AddQuotation;
