import React, { useEffect, useState } from "react";
import SideBar from "./AdminComponents/SideBar";
import { useSelector } from "react-redux";
import Button from "../../Components/Button/Button";
import CourierForm from "./AdminComponents/Modal/Form/CourierForm";
import axios from "axios";
import toast from "react-hot-toast";
import CourierTable from "./AdminComponents/Table/CourierTable";
import CourierUpdateForm from "./AdminComponents/Modal/UpdateForm/CourierUpdateForm";
import DeleteModal from "./AdminComponents/Modal/DeleteModal";
import Loader from "./AdminComponents/Loader";

function Courier() {
  const [courierData, setCourierData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCourier, setSelectedCourier] = useState("");
  const [loader, setLoader] = useState(false);
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const view = useSelector((state) => state.navbar.value);

  const handleUpdateCourierView = (courier) => {
    setSelectedCourier({ ...courier });
    setUpdate(true);
  };

  const handleDeleteCourierView = (courier) => {
    setSelectedCourier({ ...courier });
    setDelete(true);
  };

  const addCourierDetails = (value, { resetForm }) => {
    const toastLoader = toast.loading("Adding ...", {
      autoClose: false,
    });
    axios
      .post(`api/v1/couriers`, {
        courier: {
          name: value.courier_name,
          website: value.web_link,
        },
      })
      .then((res) => {
        if (res.status === 201) {
          toast.dismiss(toastLoader);
          toast.success(res.data.message);
          setOpen(false);
          resetForm();
          fetchCourierDetails();
        }
      })
      .catch((err) => {
        toast.dismiss(toastLoader);
        console.log(err);
      });
  };
  const updateCourierDetails = (value, { resetForm }) => {
    axios
      .put(`api/v1/couriers/${selectedCourier.id}`, {
        courier: {
          name: value.courier_name,
          website: value.web_link,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message);
          setUpdate(false);
          resetForm();
          fetchCourierDetails();
          setSelectedCourier("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteCourier = () => {
    axios
      .delete(`api/v1/couriers/${selectedCourier.id}`)
      .then((res) => {
        if (res.status === 200) {
          setDelete(false);
          fetchCourierDetails();
          setSelectedCourier("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCourierDetails = () => {
    axios
      .get(`api/v1/couriers`)
      .then((res) => {
        setCourierData(res.data.couriers);
        setLoader(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchCourierDetails();
  }, []);

  return (
    <div>
      <SideBar />
      <div
        className={`h-screen p-7 ${
          view ? "ml-40 ease-in duration-150" : "ml-0 ease-in duration-150"
        }`}
      >
        {loader ? (
          <div className="flex flex-col">
            <div className="flex justify-between items-start sm:ml-20 ml-0 mt-10">
              <Button
                name={"Add Courier Details"}
                onClick={() => setOpen(true)}
              />
            </div>
            <div className="flex items-center justify-center sm:ml-20 ml-0 mt-10">
              <div className="w-full">
                <CourierTable
                  data={courierData}
                  handleUpdateCourierView={handleUpdateCourierView}
                  handleDeleteCourierView={handleDeleteCourierView}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-white h-screen">
            <Loader />
          </div>
        )}
      </div>

      {open && (
        <CourierForm
          onClose={() => setOpen(false)}
          onSubmit={addCourierDetails}
        />
      )}
      {update && (
        <CourierUpdateForm
          onClose={() => setUpdate(false)}
          selectedCourier={selectedCourier}
          onSubmit={updateCourierDetails}
        />
      )}
      {Delete && (
        <DeleteModal
          icon={true}
          title={"Delete Courier Service"}
          message={`Do you want to remove ${selectedCourier.name} ?`}
          onDelete={deleteCourier}
          onClose={() => setDelete(false)}
        />
      )}
    </div>
  );
}

export default Courier;
