import React, { useEffect, useState } from "react";
import SideBar from "./AdminComponents/SideBar";
import { useSelector } from "react-redux";
import axios from "axios";
import ProductTable from "./AdminComponents/Table/ProductTable";
import ProductUpdateForm from "./AdminComponents/Modal/UpdateForm/ProductUpdateForm";
import toast from "react-hot-toast";
import DeleteModal from "./AdminComponents/Modal/DeleteModal";
import Loader from "./AdminComponents/Loader";

function ViewProduct() {
  const view = useSelector((state) => state.navbar.value);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);

  const handleUpdateProductView = (product) => {
    setSelectedProduct({ ...product });
    setOpen(true);
  };

  const handleDeleteProductView = (product) => {
    setOpenDelete(true);
    setSelectedProduct({ ...product });
  };

  const productData = () => {
    axios
      .get(`api/v1/products`)
      .then((resposne) => {
        setData(resposne.data.products);
        setLoader(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCategories = () => {
    axios
      .get(`api/v1/categories`)
      .then((response) => {
        setCategories(response.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdateProduct = (value, { resetForm }) => {
    const formData = new FormData();

    formData.append("product[category_id]", value.category);
    formData.append("product[product_name]", value.product_name);
    formData.append("product[brand_name]", value.brand_name);
    formData.append("product[product_model_name]", value.product_model_name);
    formData.append(
      "product[connecting_technology]",
      value.connecting_technology_name
    );
    formData.append("product[mobile_application]", value.mobile_application);
    formData.append("product[product_model_no]", value.product_model_no);
    formData.append("product[asin_no]", value.asin_no);
    formData.append("product[country]", value.country);
    formData.append("product[description]", value.description);
    value.features.forEach((feature, index) => {
      formData.append("product[features][]", feature);
    });
    value.special_features.forEach((specialFeature, index) => {
      formData.append("product[special_features][]", specialFeature);
    });
    formData.append("product[warranty]", value.warranty);
    formData.append("product[mrp]", value.mrp);
    formData.append("product[gst]", value.gst);
    formData.append("product[height]", value.height);
    formData.append("product[width]", value.width);
    formData.append("product[depth]", value.depth);
    formData.append("product[weight]", value.weight);
    formData.append("product[material]", value.material);
    formData.append("product[discount]", value.discount);
    formData.append("product[delivery_time]", value.delivery_time);
    formData.append("product[info]", value.info);
    formData.append("product[bestseller]", value.best_selling);
    formData.append("product[featured]", value.featured);
    if (value.main_image !== selectedProduct.main_image_url) {
      formData.append("product[main_image]", value.main_image);
    }
    if (value.brochure !== selectedProduct.brochure_url) {
      formData.append("product[brochure]", value.brochure);
    }

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    axios
      .put(`api/v1/products/${selectedProduct.id}`, formData, { headers })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          resetForm();
          setOpen(false);
          toast.success(response.data.message);
          productData();
        }
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const deleteProduct = () => {
    axios
      .delete(`api/v1/products/${selectedProduct.id}`)
      .then((response) => {
        if (response.status === 200) {
          setOpenDelete(false);
          productData();
          toast.success(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  useEffect(() => {
    productData();
    fetchCategories();
  }, []);

  return (
    <div>
      <SideBar />
      <div
        className={`h-screen p-7 ${
          view ? "ml-40 ease-in duration-150" : "ml-0 ease-in duration-150"
        }`}
      >
        {loader ? (
          <div className="flex flex-col">
            <div className="flex justify-between items-start sm:ml-20 ml-0 mt-10">
              <div className="w-full">
                <ProductTable
                  data={data}
                  productData={productData}
                  handleDeleteProductView={handleDeleteProductView}
                  handleUpdateProductView={handleUpdateProductView}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-white h-screen">
            <Loader />
          </div>
        )}
      </div>
      {open && (
        <ProductUpdateForm
          selectedProduct={selectedProduct}
          onClose={() => setOpen(false)}
          categories={categories}
          handleUpdateProduct={handleUpdateProduct}
        />
      )}
      {openDelete && (
        <DeleteModal
          icon={true}
          title={"Delete product"}
          message={`Do you wanna delete ${selectedProduct.product_name} ?`}
          onClose={() => {
            setOpenDelete(false);
          }}
          onDelete={deleteProduct}
        />
      )}
    </div>
  );
}

export default ViewProduct;
