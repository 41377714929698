import React from "react";

function Loader() {
  return (
    <div>
      <div class="flex items-center justify-center h-screen flex-row gap-2">
        <div class="w-4 h-4 rounded-full bg-[#F58634] animate-bounce [animation-delay:.7s]"></div>
        <div class="w-4 h-4 rounded-full bg-[#F58634] animate-bounce [animation-delay:.3s]"></div>
        <div class="w-4 h-4 rounded-full bg-[#F58634] animate-bounce [animation-delay:.7s]"></div>
      </div>
    </div>
  );
}

export default Loader;
