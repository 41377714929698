import { ErrorMessage, Field, useField } from "formik";
import React from "react";

function File({ label, ...props }) {
  const [field, , helpers] = useField(props);

  const handleChange = (event) => {
    const files = event.target.files[0];
    helpers.setValue(files);
  };

  return (
    <div>
      <label htmlFor={props.name} className="block mb-2 text-sm text-gray-600 ">
        {label}
      </label>
      <input
        id={props.name}
        name={props.name}
        type="file"
        onChange={props.onChange}
        accept={props.accept}
        multiple={props.multiple}
        className="block w-full px-4 py-2 mt-2 text-[#1B1D1E] placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1B1D1E]  focus:ring-[#1B1D1E] focus:outline-none focus:ring focus:ring-opacity-40"
      />
      <ErrorMessage
        name={props.name}
        className="text-red-500 text-sm mt-1"
        component="div"
      />
    </div>
  );
}

export default File;
