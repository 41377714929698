import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight, FaTrash } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import ReactPaginate from "react-paginate";
import ViewUser from "../Modal/ViewUser";
import ViewProduct from "../Modal/ViewProduct";

function OrderTable({ data, onUpdate, search }) {
  const [viewUser, setViewUser] = useState(false);
  const [viewProducts, setViewProducts] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(10);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleViewProducts = (order) => {
    setSelectedOrder({ ...order });
    setViewProducts(true);
  };

  const handleViewUser = (order) => {
    setSelectedOrder({ ...order });
    setViewUser(true);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data
    .filter((order) =>
      (order.uuid?.toLowerCase() ?? "").includes(search.toLowerCase())
    )
    .slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  return (
    <div>
      <div className="flex flex-col h-full border">
        <div className="w-full mx-auto bg-white  rounded-sm">
          <header className="flex justify-between px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Orders</h2>
            {totalPages > 1 && (
              <div className="flex justify-end">
                <ReactPaginate
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                  containerClassName={"flex"}
                  pageRangeDisplayed={1}
                  previousLabel={<FaChevronLeft />}
                  nextLabel={<FaChevronRight />}
                  activeClassName={"active"}
                  previousClassName={
                    "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                  }
                  nextClassName={
                    "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                  }
                  pageClassName={"mx-1"}
                  pageLinkClassName={
                    "bg-gray-200 text-[#020002] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                  }
                  breakClassName={"mx-1"}
                  breakLinkClassName={
                    "px-3 py-1 bg-gray-200 text-gray-600 rounded"
                  }
                />
              </div>
            )}
          </header>
        </div>

        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full border-collapse">
            <thead className="text-xs font-semibold uppercase text-gray-600 bg-gray-100">
              <tr>
                <th className="px-4 py-2">
                  <div className="text-left">Order ID</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Customer details</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Product(s)</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Amount</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Payment Status</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Courier name</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Tracking website</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Tracking ID</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Status</div>
                </th>
                <th className="px-4 py-2">
                  <div className="text-left">Action</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {currentItems.map((order) => (
                <tr key={order.id}>
                  <td className="p-2 border-r">
                    <div className="text-left">{order.uuid}</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      <button
                        onClick={() => handleViewUser(order)}
                        className="font-semibold hover:underline"
                      >
                        View
                      </button>
                    </div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      <button
                        onClick={() => handleViewProducts(order)}
                        className="font-semibold hover:underline"
                      >
                        View
                      </button>
                    </div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">{order.total_price}</div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      {order.payment_status.toUpperCase()}
                    </div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      {order.courier === null ? "-" : order.courier.name}
                    </div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      <button
                        onClick={() => window.open(order.courier.website)}
                        disabled={order.courier === null}
                        className="font-semibold hover:underline"
                      >
                        View
                      </button>
                    </div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">
                      {order.tracking_id ? order.tracking_id : "-"}
                    </div>
                  </td>
                  <td className="p-2 border-r">
                    <div className="text-left">{order.status}</div>
                  </td>
                  <td className="p-2 ">
                    <div className="flex text-left">
                      <button
                        onClick={() => onUpdate(order)}
                        className="bg-green-200 text-green-600 p-2 rounded-lg text-lg shadow-md hover:shadow-none duration-300 ease-out"
                      >
                        <MdEdit />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {viewUser && (
        <ViewUser
          onClose={() => {
            setViewUser(false);
            setSelectedOrder("");
          }}
          user={selectedOrder}
        />
      )}
      {viewProducts && (
        <ViewProduct
          onClose={() => {
            setViewProducts(false);
            setSelectedOrder("");
          }}
          user={selectedOrder}
        />
      )}
    </div>
  );
}

export default OrderTable;
