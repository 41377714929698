import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { LoderContext } from "../../../App";
import UserOrderdetails from "../UserOrderdetails";

function Orderhistory({ setOpenUpdate }) {
  const { setIsLoading } = useContext(LoderContext);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState("");
  const [view, setView] = useState();
  const [id, setOrderId] = useState(null);
  useEffect(() => {
    setOpenUpdate(false);
    const fetchOrders = async () => {
      setIsLoading({ loader: true });
      try {
        const response = await axios.get("/api/v1/orders");
        if (response.status === 200) {
          setOrders(response.data.orders);
          setFilteredOrders(response.data.orders); // Initially set filteredOrders to all orders
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        setIsLoading({
          notfound:
            error?.response?.status === 404 || error?.code === "ERR_NETWORK",
        });
      } finally {
        setIsLoading({ loader: false });
      }
    };
    fetchOrders();
  }, [setIsLoading]);

  const handleFilterChange = (status) => {
    if (status === "all") {
      setFilteredOrders(orders);
    } else {
      const filtered = orders.filter((order) => order.status === status);
      setFilteredOrders(filtered);
    }
    setStatusFilter(status);
  };
  const handleDownload = (value) => {
    setOrderId(value);
    setView(!view);
  };
  const handleClose = () => {
    setView(!view);
  };
  return (
    <div className="w-full mt-6 p-4 flex flex-col">
      <div className="w-full mb-4 md:mb-0">
        <div className="grid w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-5 bg-white p-4 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 col-span-full md:col-span-5">
            Filter by Status
          </h2>
          <div className="col-span-1">
            <button
              className={`w-full py-2 mb-2 ${
                statusFilter === "all"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-blue-100"
              } rounded-md transition duration-300 ease-in-out`}
              onClick={() => handleFilterChange("all")}
            >
              All Orders
            </button>
          </div>
          <div className="col-span-1">
            <button
              className={`w-full py-2 mb-2 ${
                statusFilter === "pending"
                  ? "bg-yellow-500 text-white"
                  : "text-gray-700 hover:bg-yellow-100"
              } rounded-md transition duration-300 ease-in-out`}
              onClick={() => handleFilterChange("Pending")}
            >
              Pending
            </button>
          </div>
          <div className="col-span-1">
            <button
              className={`w-full py-2 mb-2 ${
                statusFilter === "shipped"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700 hover:bg-blue-100"
              } rounded-md transition duration-300 ease-in-out`}
              onClick={() => handleFilterChange("In Transit")}
            >
              In Transit
            </button>
          </div>
          <div className="col-span-1">
            <button
              className={`w-full py-2 mb-2 ${
                statusFilter === "delivered"
                  ? "bg-green-500 text-white"
                  : "text-gray-700 hover:bg-green-100"
              } rounded-md transition duration-300 ease-in-out`}
              onClick={() => handleFilterChange("Out for Delivery")}
            >
              Out for Delivery
            </button>
          </div>{" "}
          <div className="col-span-1">
            <button
              className={`w-full py-2 mb-2 ${
                statusFilter === "delivered"
                  ? "bg-green-500 text-white"
                  : "text-gray-700 hover:bg-green-100"
              } rounded-md transition duration-300 ease-in-out`}
              onClick={() => handleFilterChange("Delivered")}
            >
              Delivered
            </button>
          </div>
        </div>
      </div>
      <div className="w-full mt-4">
        {filteredOrders.length === 0 ? (
          <p className="text-center text-gray-600">No orders found.</p>
        ) : (
          filteredOrders.map((order) => (
            <div
              key={order.uuid}
              className="p-4 md:p-6 mb-4 md:mb-6 bg-white shadow-lg rounded-lg w-full"
            >
              <div className="grid gap-4 mb-4 max-sm:grid-cols-1 grid-cols-3">
                <div className="flex flex-col">
                  <div className="text-gray-700 mb-2">
                    <p className="text-sm font-medium">Order Number</p>
                    <p className="text-lg font-semibold">{order.uuid}</p>
                  </div>
                  <div className="text-gray-700 mb-2">
                    <p className="text-sm font-medium">Date Placed</p>
                    <p className="text-lg font-semibold">
                      {new Date(order.created_at).toLocaleDateString()}
                    </p>
                  </div>
                  {order?.status === "Delivered" ? (
                    <div className="text-gray-700 mb-2">
                      <p className="text-sm font-medium">Download Invoice</p>
                      <p
                        onClick={() => handleDownload(order.id)}
                        className="text-lg font-semibold"
                      >
                        Download {order.id}
                      </p>
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col">
                  <div className="text-gray-700 mb-2">
                    <p className="text-sm font-medium">Total Amount</p>
                    <p className="text-lg font-semibold">
                      ${order.discounted_price}
                    </p>
                  </div>
                  <div className="text-gray-700 mb-2">
                    <p className="text-sm font-medium">Status</p>
                    <p
                      className={`text-lg font-semibold capitalize ${
                        order.status === "pending"
                          ? "text-yellow-500"
                          : order.status === "shipped"
                          ? "text-blue-500"
                          : "text-green-500"
                      }`}
                    >
                      {order.status}
                    </p>
                  </div>
                </div>
                <div className="text-gray-700 bg-slate-200 mb-2 p-4 rounded-lg shadow-md">
                  <p className="text-sm font-medium">Courier Details:</p>
                  {order?.courier?.name ? (
                    <div>
                      <p className="text-lg font-semibold capitalize mt-2 flex items-center space-x-2">
                        <span className="block">Name: </span>
                        <span className="text-gray-600">
                          {order?.courier?.name}
                        </span>
                      </p>
                      <p className="text-lg font-semibold capitalize mt-2 flex items-center space-x-2">
                        <span className="block">Website: </span>
                        <span
                          onClick={() => {
                            window.open(order?.courier?.website);
                          }}
                          className="text-blue-500 underline text-xs cursor-pointer line-clamp-1"
                        >
                          {order?.courier?.website}
                        </span>
                      </p>
                      <p className="text-lg font-semibold capitalize mt-2 ">
                        <span className="block">
                          Tracking ID: {order?.tracking_id}
                        </span>
                      </p>
                    </div>
                  ) : (
                    <p>NOT ASSIGNED YET</p>
                  )}
                </div>
              </div>

              <div className="grid grid-cols-1 gap-4">
                {order.order_items.map((item) => (
                  <div
                    key={item.id}
                    className="flex  items-center bg-gray-100 p-4 rounded-lg shadow-md"
                  >
                    <img
                      src={item.product_details?.main_image}
                      alt={item.product_details?.product_name}
                      className="w-24 h-24 object-cover rounded-lg mr-4"
                    />
                    <div>
                      <h1 className="text-lg font-semibold text-gray-900">
                        {item.product_details?.product_name}
                      </h1>
                      <p className="text-gray-700">
                        {item.product_details?.info}
                      </p>
                      <p className="text-gray-600 mt-2">Price: ${item.price}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
      {view ? (
        <UserOrderdetails
          id={id}
          onClose={handleClose}
          orders={orders.filter((data) => data.id === id)}
        />
      ) : null}
    </div>
  );
}

export default Orderhistory;
