import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Input from "../../Components/FormFields/Input";
import { BgImage } from "../../Components/BgImage";
import Select from "../../Components/FormFields/Select";
import { State, City } from "country-state-city";
import axios from "axios";
import toast from "react-hot-toast";
import UserButton from "./UserComponents/UserButton.jsx";
function Signup() {
  const [clientId, setClientId] = useState("");
  const navigate = useNavigate();
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [next, setNext] = useState(false);
  const getClientDetails = () => {
    axios
      .get("api/v1/users/credential")
      .then((response) => {
        setClientId(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getClientDetails();
    handleCountryChange();
  }, []);

  const handleCountryChange = async () => {
    try {
      const states = State.getStatesOfCountry("IN").map((state) => ({
        name: state.name,
        id: state.isoCode,
      }));
      setStates(states);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleStateChange = async (selectedState) => {
    console.log(selectedState);
    try {
      const cities = City.getCitiesOfState("IN", selectedState).map((city) => ({
        name: city.name,
        id: city.name,
      }));
      setCities(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Please enter your full name"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Please enter a valid email"),
    password: Yup.string()
      .min(6, "Password should have at least 6 characters")
      .required("Please enter a password"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Please confirm your password"),
    gstno: Yup.string().matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "Invalid Gst No."
    ),
    mobile: Yup.string()
      .min(10, "10 digit required")
      .max(10, "must be 10 digit")
      .required("* required"),
    state: Yup.string().required("* required"),
    city: Yup.string().required("* required"),
    pincode: Yup.string().required("* required"),
    address: Yup.string().required("* required"),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    axios
      .post("api/v1/users", {
        user: {
          name: values.fullName,
          email: values.email,
          password: values.confirmPassword,
          mobile_no: values.mobile,
          delivery_address: values.address,
          pin_code: values.pincode,
          city: values.city,
          state: State.getStateByCodeAndCountry(values.state, "IN").name,
          role: "customer",
          locality: values?.locality,
          gst_no: values?.gstno,
        },
        client_id: clientId,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          toast.success("User Created Successfully.");
          navigate("/Login");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
    setSubmitting(false);
    resetForm();
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <BgImage
        backgroundImage={
          "https://images.unsplash.com/photo-1691449808001-bb8c157f0094?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTE1fHx0ZWNobm9sb2d5JTIwYmFja2dyb3VuZCUyMGRhcmt8ZW58MHx8MHx8fDA%3D"
        }
      />
      <div className="w-full max-w-4xl p-8 bg-white rounded-lg shadow-lg mx-6 md:mx-auto">
        <h2 className="text-3xl font-bold text-center mb-6">Sign Up</h2>
        <Formik
          initialValues={{
            fullName: "",
            email: "",
            password: "",
            confirmPassword: "",
            mobile: "",
            address: "",
            pincode: "",
            city: "",
            state: "",
            gstno: "",
            locality: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form className="grid grid-cols-1 gap-4">
              <div className={`grid grid-cols-1 gap-4 ${next ? `hidden` : ``}`}>
                <Input
                  type="text"
                  name="fullName"
                  label="Full Name"
                  placeholder="John Doe"
                  className="p-2 border rounded-md"
                  aria-label="Full Name"
                />
                <Input
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="john@example.com"
                  className="p-2 border rounded-md"
                  aria-label="Email"
                />
                <Input
                  type="number"
                  name="mobile"
                  label="Mobile No."
                  placeholder="Enter Mobile No."
                  className="p-2 border rounded-md"
                  aria-label="mobile"
                />
              </div>
              <div className={`grid grid-cols-1 gap-4 ${next ? `` : `hidden`}`}>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
                  <Select
                    label={"State"}
                    name={"state"}
                    options={states}
                    selectedOption={"Select State"}
                    onChange={(event) => {
                      console.log(event.currentTarget.value);
                      setFieldValue("state", event.currentTarget.value);
                      handleStateChange(event.currentTarget.value);
                      setFieldValue("city", "");
                    }}
                  />
                  <Select
                    label={"City"}
                    name={"city"}
                    options={cities}
                    selectedOption={"Select City"}
                    onChange={(event) => {
                      setFieldValue("city", event.currentTarget.value);
                    }}
                  />
                  <Input
                    type="text"
                    name="locality"
                    label="Locality / Town"
                    placeholder="Enter Locality"
                    className="p-2 border rounded-md"
                    aria-label="Locality"
                  />
                  <Input
                    type="number"
                    name="pincode"
                    label="Pin Code"
                    placeholder="Enter pincode"
                    className="p-2 border rounded-md"
                    aria-label="pincode"
                  />
                </div>
                <Input
                  type="textarea"
                  name="address"
                  label="Address"
                  placeholder="Enter Your Address here..."
                  className="p-2 border rounded-md"
                  aria-label="address"
                />
                <Input
                  type="text"
                  name="gstno"
                  label="Gst No."
                  placeholder="Gst Number ..."
                  className="p-2 border rounded-md"
                  aria-label="gstno"
                />
              </div>
              <div className={`${next ? `` : `hidden`} grid grid-cols-1 gap-4`}>
                <Input
                  type="password"
                  name="password"
                  label="Password"
                  placeholder="Enter your password"
                  className="p-2 border rounded-md"
                  aria-label="Password"
                />
                <Input
                  type="password"
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder="Confirm your password"
                  className="p-2 border rounded-md"
                  aria-label="Confirm Password"
                />
              </div>
              <div
                className={`flex items-center justify-around ${
                  next ? `` : `hidden`
                }`}
              >
                <UserButton
                  name="Back"
                  type="button"
                  className="w-full"
                  onClick={() => setNext(!next)}
                />
                <UserButton name="Sign Up" type="submit" className="w-full" />
              </div>
              <div
                className={`flex items-center justify-center ${
                  next ? `hidden` : ``
                }`}
              >
                <UserButton
                  name="Next"
                  type="button"
                  className="w-full"
                  onClick={() => setNext(!next)}
                />
              </div>
            </Form>
          )}
        </Formik>

        <p className="mt-4 text-sm text-center text-gray-600">
          Already have an account?{" "}
          <Link to="/login" className="text-blue-600 hover:underline">
            Log in
          </Link>
          .
        </p>
      </div>
    </div>
  );
}

export default Signup;
