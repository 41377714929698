import React, { useState } from "react";
import Navbar from "../../Components/Navbar";
import { NavLink, useParams } from "react-router-dom";
import EditAddress from "./UserComponents/EditAddress";
import Orderhistory from "./UserComponents/Orderhistory";

function UserProfilePage() {
  const { Page } = useParams();
  const [openUpdate, setOpenUpdate] = useState(false);
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-100 to-gray-200">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 py-8 mt-16 flex flex-col md:flex-row">
        <>
          <div className="md:w-1/4">
            <div className="md:sticky top-24 w-full bg-white shadow-lg rounded-lg p-4 mb-4 md:mb-0">
              <div className="space-y-4">
                <NavLink
                  to={"/UserProfile/overview"}
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? "bg-[#242c44] text-white font-semibold text-md p-3 rounded-lg"
                        : "text-[#242c44] bg-gray-200 hover:bg-gray-400 hover:text-gray-100 p-3 rounded-lg"
                    } flex items-center justify-start transition-colors duration-200`
                  }
                >
                  <h1>Overview</h1>
                </NavLink>
                <NavLink
                  to={"/UserProfile/Orderhistory"}
                  className={({ isActive }) =>
                    `${
                      isActive
                        ? "bg-[#242c44] text-white font-semibold text-md p-3 rounded-lg"
                        : "text-[#242c44] bg-gray-200 hover:bg-gray-400 hover:text-gray-100 p-3 rounded-lg"
                    } flex items-center justify-start transition-colors duration-200`
                  }
                >
                  <h1>Orders</h1>
                </NavLink>
              </div>
            </div>
          </div>

          {/* Main Content Area */}
          <div className="w-full md:ml-10 bg-white shadow-lg rounded-lg p-8">
            {Page === "overview" ? (
              <div>
                <h1 className="text-2xl text-[#242c44] font-semibold">
                  Account
                </h1>
                <EditAddress
                  openUpdate={openUpdate}
                  setOpenUpdate={setOpenUpdate}
                />
              </div>
            ) : Page === "Orderhistory" ? (
              <Orderhistory setOpenUpdate={setOpenUpdate} />
            ) : null}
          </div>
        </>
      </div>
    </div>
  );
}

export default UserProfilePage;
