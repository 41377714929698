import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Input from "../../../../Components/FormFields/Input";
import Select from "../../../../Components/FormFields/Select";
import * as Yup from "yup";
import { City, State } from "country-state-city";
import { IoIosCloseCircle } from "react-icons/io";

function AddAddress({ setOpenAddressModel, AddAddressToArray }) {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const formikref = useRef();
  useEffect(() => {
    handleCountryChange();
  }, []);
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Please enter your full name"),
    gstno: Yup.string()
      .matches(
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        "Invalid Gst No."
      )
      .required("* required"),
    mobile: Yup.string()
      .min(10, "10 digit required")
      .max(10, "must be 10 digit")
      .required("* required"),
    state: Yup.string().required("* required"),
    city: Yup.string().required("* required"),
    pincode: Yup.string().required("* required"),
    address: Yup.string().required("* required"),
  });
  const handleCountryChange = async () => {
    try {
      const states = State.getStatesOfCountry("IN").map((state) => ({
        name: state.name,
        id: state.isoCode,
      }));
      setStates(states);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleStateChange = async (selectedState) => {
    try {
      const cities = City.getCitiesOfState("IN", selectedState).map((city) => ({
        name: city.name,
        id: city.name,
      }));
      setCities(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };
  const handleClose = () => {
    setOpenAddressModel(false);
  };
  return (
    <div className="fixed inset-0 bg-center bg-cover bg-black bg-opacity-70 flex items-center justify-center z-50 ">
      <div className="h-full p-4 flex items-start justify-center">
        <div className=" h-fit bg-white rounded-xl p-2 shadow-2xl flex flex-col items-center justify-center">
          <div className="w-full h-1/4 flex items-center justify-between border-b-2 p-2">
            <span>Add New Address</span>
            <IoIosCloseCircle
              size={35}
              className="text-[#242c44]"
              onClick={() => {
                handleClose();
              }}
            />
          </div>
          <div className="h-96 overflow-auto w-full ">
            <Formik
              innerRef={formikref}
              initialValues={{
                fullName: "",
                mobile: "",
                address: "",
                pincode: "",
                city: "",
                state: "",
                gstno: "",
                locality: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                AddAddressToArray("userAddress", values, 30);
              }}
            >
              {({ errors, touched, setFieldValue, values, handleSubmit }) => (
                <Form
                  className="grid grid-cols-1 gap-4 p-4 w-full"
                  onSubmit={handleSubmit}
                >
                  <div className={`grid grid-cols-1 gap-4`}>
                    <h1 className="text-md font-semibold text-[#242c44]">
                      Contact Details
                    </h1>
                    <Input
                      type="text"
                      name="fullName"
                      label="Full Name"
                      placeholder="John Doe"
                      className="p-2 border rounded-md"
                      aria-label="Full Name"
                    />
                    <Input
                      type="number"
                      name="mobile"
                      label="Mobile No."
                      placeholder="Enter Mobile No."
                      className="p-2 border rounded-md"
                      aria-label="mobile"
                    />
                  </div>
                  <div className={`grid grid-cols-1 gap-4 mt-8`}>
                    <h1 className="text-md font-semibold text-[#242c44]">
                      Address
                    </h1>
                    <div className="grid grid-cols-1 gap-4">
                      <Select
                        label={"State"}
                        name={"state"}
                        options={states}
                        selectedOption={"Select State"}
                        onChange={(event) => {
                          setFieldValue("state", event.currentTarget.value);
                          handleStateChange(event.currentTarget.value);
                          setFieldValue("city", "");
                        }}
                      />
                      <Select
                        label={"City"}
                        name={"city"}
                        options={cities}
                        selectedOption={"Select City"}
                        onChange={(event) => {
                          setFieldValue("city", event.currentTarget.value);
                        }}
                      />
                      <Input
                        type="text"
                        name="locality"
                        label="Locality / Town"
                        placeholder="Enter Locality"
                        className="p-2 border rounded-md"
                        aria-label="Locality"
                      />
                      <Input
                        type="number"
                        name="pincode"
                        label="Pin Code"
                        placeholder="Enter pincode"
                        className="p-2 border rounded-md"
                        aria-label="pincode"
                      />
                    </div>
                    <Input
                      type="textarea"
                      name="address"
                      label="Address"
                      placeholder="Enter Your Address here..."
                      className="p-2 border rounded-md"
                      aria-label="address"
                    />
                    <Input
                      type="text"
                      name="gstno"
                      label="Gst No."
                      placeholder="Gst Number ..."
                      className="p-2 border rounded-md"
                      aria-label="gstno"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className={`grid grid-cols-1  w-full  `}>
            <button
              onClick={() => formikref?.current?.submitForm()}
              type="submit"
              className="text-md text-[#242c44] py-3 px-4 rounded-md hover:text-white hover:bg-[#242c44] border-2 border-[#242c44] "
            >
              ADD ADDRESS
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAddress;
