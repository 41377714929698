import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Input from "../../../Components/FormFields/Input";
import Select from "../../../Components/FormFields/Select";
import { City, State } from "country-state-city";
import UserInput from "../UserComponents/FormFields/UserInput.jsx";
import axios from "axios";
import toast from "react-hot-toast";
function EditAddress({ setOpenUpdate, openUpdate }) {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [currentUser, setCurrentUser] = useState([]);

  useEffect(() => {
    handleCountryChange();
    if (localStorage.getItem("isAuthenticated")) {
      getCurrentUserDetails();
    }
  }, []);
  const getCurrentUserDetails = () => {
    const id = localStorage.getItem("userId");
    axios
      .get(`api/v1/users/${id}`)
      .then((res) => {
        console.log(res?.data?.user);
        setCurrentUser(res?.data?.user);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCountryChange = async () => {
    try {
      const states = State.getStatesOfCountry("IN").map((state) => ({
        name: state.name,
        id: state.isoCode,
      }));
      setStates(states);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };
  const handleStateChange = async (selectedState) => {
    console.log(selectedState);
    try {
      const cities = City.getCitiesOfState("IN", selectedState).map((city) => ({
        name: city.name,
        id: city.name,
      }));
      setCities(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };
  const handleUpdateProfile = (values) => {
    axios
      .put(`api/v1/users/${currentUser?.id}`, {
        user: {
          name: values?.fullName,
          email: values.email,
          password: values.confirmPassword,
          mobile_no: values.mobile,
          delivery_address: values.address,
          pin_code: values.pincode,
          city: values.city,
          state:
            values.state === currentUser.state
              ? values.state
              : State.getStateByCodeAndCountry(values.state, "IN").name,
          role: "user",
          locality: values?.locality,
          gst_no: values?.gstno,
        },
      })
      .then((res) => {
        console.log(res);
        toast.success(res?.data?.message);
        getCurrentUserDetails();
        setOpenUpdate(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="flex flex-col  mt-6 border-4 w-full">
      {openUpdate ? (
        <Formik
          initialValues={{
            fullName: currentUser?.name || "",
            email: currentUser?.email,
            mobile: currentUser.mobile_no,
            address: currentUser.delivery_address,
            pincode: currentUser.pin_code,
            city: currentUser.city,
            state: currentUser.state || "",
            gstno: currentUser.gst_no,
            locality: currentUser.locality,
          }}
          validationSchema={Yup.object().shape({
            fullName: Yup.string().required("Please enter your full name"),
            email: Yup.string()
              .email("Invalid email format")
              .required("Please enter a valid email"),
            gstno: Yup.string().matches(
              /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
              "Invalid Gst No."
            ),
            mobile: Yup.string()
              .min(10, "10 digit required")
              .max(10, "must be 10 digit")
              .required("* required"),
            state: Yup.string().required("* required"),
            city: Yup.string().required("* required"),
            pincode: Yup.string().required("* required"),
            address: Yup.string().required("* required"),
          })}
          onSubmit={(values) => {
            handleUpdateProfile(values);
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="text-[#242c44] flex p-2 ">
                <div className="p-4 w-full ">
                  <h1 className="text-lg mb-4 font-semibold border-b-2 p-2">
                    {openUpdate ? "Update" : null} Profile Details :
                  </h1>
                  <div className="grid grid-cols-1 gap-4 mb-4">
                    <UserInput
                      type="text"
                      name="fullName"
                      label="Full Name"
                      // placeholder="John Doe"
                      className="p-2 border rounded-md"
                      // aria-label="Full Name"
                      value={values?.fullName}
                      onChange={(event) => {
                        setFieldValue("fullName", event.currentTarget.value);
                      }}
                    />
                    <UserInput
                      type="text"
                      name="mobile"
                      label="Mobile No"
                      placeholder="Mobile No"
                      className="p-2 border rounded-md"
                      aria-label="Mobile No"
                      value={values.mobile}
                      onChange={(event) => {
                        setFieldValue("mobile", event.target.value);
                      }}
                    />
                    <UserInput
                      type="text"
                      name="email"
                      label="Email"
                      placeholder="Email Id"
                      className="p-2 border rounded-md"
                      aria-label="Email"
                      value={values.email}
                      onChange={(event) => {
                        setFieldValue("email", event.target.value);
                      }}
                    />
                    <UserInput
                      type="textarea"
                      name="address"
                      label="Address"
                      placeholder="Enter Your Address here..."
                      className="p-2 border rounded-md"
                      aria-label="address"
                      value={values.address}
                      onChange={(event) => {
                        setFieldValue("address", event.target.value);
                      }}
                    />
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
                      <Select
                        label={"State"}
                        name={"state"}
                        options={states}
                        selectedOption={
                          values.state === currentUser?.state
                            ? values?.state
                            : State.getStateByCodeAndCountry(values.state, "IN")
                                .name
                        }
                        onChange={(event) => {
                          setFieldValue("state", event.currentTarget.value);
                          handleStateChange(event.currentTarget.value);
                          setFieldValue("city", "");
                        }}
                      />
                      <Select
                        placeholder={"Please Select City"}
                        label={"City"}
                        name={"city"}
                        options={cities}
                        selectedOption={
                          values.city === currentUser.city
                            ? values.city
                            : "Select city"
                        }
                        onChange={(event) => {
                          setFieldValue("city", event.currentTarget.value);
                        }}
                      />
                      <UserInput
                        type="text"
                        name="locality"
                        label="Locality / Town"
                        placeholder="Enter Locality"
                        className="p-2 border rounded-md"
                        aria-label="Locality"
                        value={values.locality}
                        onChange={(event) => {
                          setFieldValue("locality", event.target.value);
                        }}
                      />
                      <UserInput
                        type="number"
                        name="pincode"
                        label="Pin Code"
                        placeholder="Enter pincode"
                        className="p-2 border rounded-md"
                        aria-label="pincode"
                        value={values.pincode}
                        onChange={(event) => {
                          setFieldValue("pincode", event.target.value);
                        }}
                      />
                      <UserInput
                        type="text"
                        name="gstno"
                        label="Gst No."
                        placeholder="Gst Number ..."
                        className="p-2 border rounded-md"
                        aria-label="gstno"
                        value={values.gstno}
                        onChange={(event) => {
                          setFieldValue("gstno", event.target.value);
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <button
                        type="submit"
                        className="p-2 w-full bg-white border-2 border-[#242c44] rounded-md hover:bg-[#242c44] hover:text-white"
                      >
                        Update
                      </button>
                      <button
                        onClick={() => {
                          setOpenUpdate(false);
                        }}
                        type="button"
                        className="p-2 w-full bg-white hover:ring-2 ring-opacity-30 ring-red-400 border-2 border-[#242c44] rounded-md  hover:text-red-500"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="p-4 w-full">
          <h1 className="text-md font-semibold mb-4">
            <span> Name:</span>{" "}
            <span className="text-gray-400">{currentUser?.name}</span>
          </h1>
          <h1 className="text-md font-semibold mb-4">
            <span>Mobile: </span>
            <span className="text-gray-400">{currentUser?.mobile_no}</span>
          </h1>
          <h1 className="text-md font-semibold mb-4">
            <span>Email: </span>
            <span className="text-gray-400">{currentUser?.email}</span>
          </h1>
          <h1 className="text-md font-semibold mb-4">
            <span>Delivery Address: </span>
            <span className="text-gray-400">
              {currentUser?.delivery_address}
            </span>
            <span className="text-gray-400">
              {currentUser?.locality ? `, ${currentUser?.locality}` : null}
            </span>
          </h1>
          <h1 className="text-md font-semibold mb-4">
            <span>City:</span>{" "}
            <span className="text-gray-400">{currentUser?.city}</span>
          </h1>
          <h1 className="text-md font-semibold mb-4">
            <span>State: </span>
            <span className="text-gray-400">
              {currentUser?.state} - {currentUser?.pin_code}
            </span>
          </h1>
          <h1 className="text-md font-semibold mb-4">
            <span>Gst No:</span>{" "}
            <span className="text-gray-400">
              {currentUser?.gst_no ? currentUser?.gst_no : "---"}
            </span>
          </h1>
          <div>
            <button
              type="button"
              onClick={() => {
                setOpenUpdate(true);
              }}
              className="p-2 w-full bg-white border-2 border-[#242c44] rounded-md hover:bg-[#242c44] hover:text-white"
            >
              Edit Profile
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditAddress;
