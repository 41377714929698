import { Form, Formik } from "formik";
import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Input from "../../../../Components/FormFields/Input";
import Button from "../../../../Components/Button/Button";
import * as Yup from "yup";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import axios from "axios";
import toast from "react-hot-toast";
import ReactPaginate from "react-paginate";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { TiTick } from "react-icons/ti";

function CategoryModal({ setOpen, categories, fetchCategories }) {
  const [update, setUpdate] = useState(false);
  const [Delete, setDelete] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage] = useState(5);
  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Don't leave it empty."),
  });

  const handleUpdateView = (id) => {
    const category = categories.find((cat) => cat.id === id);
    setSelectedCategory(category);
    setUpdate(true);
  };
  const handleDeleteView = (id) => {
    const category = categories.find((cat) => cat.id === id);
    setSelectedCategory(category);
    setDelete(true);
  };

  const handleAddCategory = (values, { resetForm }) => {
    axios
      .post("api/v1/categories", {
        category: {
          name: values.category,
        },
      })
      .then((resposne) => {
        if (resposne.status === 200) {
          fetchCategories();
          toast.success(resposne.data.message);
          resetForm();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleUpdateCategory = (values, { resetForm }) => {
    axios
      .put(`api/v1/categories/${selectedCategory.id}`, {
        category: {
          name: values.category,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          fetchCategories();
          toast.success(response.data.message);
          setUpdate(false);
          resetForm();
          setSelectedCategory("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteCategory = () => {
    axios
      .delete(`api/v1/categories/${selectedCategory.id}`)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setDelete(false);
          setSelectedCategory("");
          fetchCategories();
          toast.success(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = categories.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(categories.length / itemsPerPage);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634]">
                Category
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={() => setOpen(false)}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <div>
            <Formik
              initialValues={{ category: "" }}
              validationSchema={validationSchema}
              onSubmit={handleAddCategory}
            >
              <Form>
                <div className="flex flex-row">
                  <div className="mt-2 sm:mt-4">
                    <Input
                      name={"category"}
                      label={"Category"}
                      placeholder={"Category"}
                      type={"text"}
                    />
                  </div>
                  <div className="ml-5 mt-10">
                    <Button name={"Add"} type={"submit"} />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
          <div className="w-full mb-4 overflow-hidden rounded-lg shadow-lg mt-5">
            <div className="w-full overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr className="text-md font-normal tracking-wide text-left text-gray-900 bg-gray-100 border-b border-gray-600">
                    <th className="px-4 py-3">Name</th>
                    <th className="px-4 py-3">Action</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y-2 divide-gray-200">
                  {currentItems.map((category, index) => (
                    <tr className="text-gray-700" key={index}>
                      <td className="px-4 py-3">
                        {update && selectedCategory.id === category.id ? (
                          <div>
                            <Formik
                              initialValues={{
                                category: "" || selectedCategory.name,
                              }}
                              onSubmit={handleUpdateCategory}
                            >
                              <Form>
                                <div className="flex sm:flex-row flex-col">
                                  <div className="mt-2 sm:mt-4">
                                    <Input
                                      name={"category"}
                                      label={"Category"}
                                      placeholder={selectedCategory.name}
                                      type={"text"}
                                    />
                                  </div>
                                  <div className="flex flex-row mt-5">
                                    <div>
                                      <Button name={"Update"} type={"submit"} />
                                    </div>
                                    <div className="ml-2">
                                      <Button
                                        name={"Cancel"}
                                        type={"button"}
                                        onClick={() => setUpdate(false)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </Form>
                            </Formik>
                          </div>
                        ) : (
                          <div>{category.name}</div>
                        )}
                      </td>
                      <td className="px-4 py-3">
                        {Delete && selectedCategory.id === category.id ? (
                          <div className="flex flex-col">
                            <div>
                              <h1 className="mr-2">
                                Do you wanna delete {selectedCategory.name} ?
                              </h1>
                            </div>
                            <div>
                              <button
                                onClick={handleDeleteCategory}
                                className="bg-green-200 text-green-600 p-2 rounded-lg text-lg shadow-md hover:shadow-none duration-300 ease-out"
                              >
                                <TiTick />
                              </button>
                              <button
                                onClick={() => {
                                  setDelete(false);
                                  setSelectedCategory("");
                                }}
                                className="bg-red-200 text-red-600 p-2 rounded-lg text-lg ml-2 shadow-md hover:shadow-none duration-300 ease-out"
                              >
                                <RxCross1 />
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex">
                            <button
                              onClick={() => handleUpdateView(category.id)}
                              className="bg-green-200 text-green-600 p-2 rounded-lg text-lg shadow-md hover:shadow-none duration-300 ease-out"
                            >
                              <MdEdit />
                            </button>
                            <button
                              onClick={() => handleDeleteView(category.id)}
                              className="bg-red-200 text-red-600 p-2 rounded-lg text-lg ml-2 shadow-md hover:shadow-none duration-300 ease-out"
                            >
                              <FaTrash />
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {totalPages > 1 && (
            <div className="flex justify-end">
              <ReactPaginate
                pageCount={totalPages}
                onPageChange={handlePageChange}
                containerClassName={"flex"}
                pageRangeDisplayed={1}
                previousLabel={<FaChevronLeft />}
                nextLabel={<FaChevronRight />}
                activeClassName={"active"}
                previousClassName={
                  "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                }
                nextClassName={
                  "bg-[#F58634] text-[#fbffff] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                }
                pageClassName={"mx-1"}
                pageLinkClassName={
                  "bg-gray-200 text-[#020002] w-7 h-7 items-center justify-center p-2 top-9 -right-4 text-xl flex rounded"
                }
                breakClassName={"mx-1"}
                breakLinkClassName={
                  "px-3 py-1 bg-gray-200 text-gray-600 rounded"
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CategoryModal;
