import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Input from "../../Components/FormFields/Input";
import axios from "axios";
import toast from "react-hot-toast";
import UserButton from "./UserComponents/UserButton";

function Login() {
  const [clientId, setClientId] = useState("");
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Please enter a valid email")
      .email("Invalid email format"),
    password: Yup.string()
      .min(6, "Password should have at least 6 characters")
      .required("Please enter a valid password"),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    axios
      .post(`api/v1/users/login`, {
        user: {
          email: values.email,
          password: values.password,
        },
        client_id: clientId,
      })
      .then((response) => {
        if (response.status === 200) {
          console.log(response);
          toast.success(response.data.message);
          localStorage.setItem("isAuthenticated", true);
          localStorage.setItem("userId", response.data.user.id);
          localStorage.setItem("userRole", response.data.user.role);
          localStorage.setItem("access_token", response.data.user.access_token);
          localStorage.setItem("email", response.data.user.email);
          if (response.data.user.role === "admin") {
            navigate("/Products");
          } else navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.error);
      });
    setSubmitting(false);
    resetForm();
  };
  const getClientDetails = () => {
    axios
      .get("api/v1/users/credential")
      .then((response) => {
        setClientId(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getClientDetails();
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#e0e0e0]">
      <div className="relative flex shadow-2xl rounded-lg w-full max-w-3xl md:w-5/6 lg:w-3/4 xl:w-2/3">
        <div className="w-full md:w-1/2 bg-white p-8 rounded-l-lg mx-6 md:mx-auto">
          <h2 className="text-4xl font-bold text-center mb-6 text-[#373A40]">
            Login
          </h2>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form className="grid grid-cols-1 gap-4">
                <Input
                  type="text"
                  name="email"
                  label={"Email"}
                  placeholder="johnsmith@example.com"
                  className="p-2 border rounded-md"
                  aria-label="Email"
                />
                <Input
                  type="password"
                  name="password"
                  label={"Password"}
                  placeholder="Enter your password"
                  className="p-2 border rounded-md"
                  aria-label="Password"
                />
                <Link
                  to="/ResetPassword"
                  className="text-sm text-gray-600 hover:text-blue-600 hover:underline"
                >
                  Reset password?
                </Link>
                <div className="flex items-center justify-center">
                  <UserButton name="Log In" type="submit" className="w-full" />
                </div>
              </Form>
            )}
          </Formik>
          <p className="mt-6 text-sm text-center text-gray-600">
            Don't have an account yet?{" "}
            <Link to="/Signup" className="text-blue-600 hover:underline">
              Sign up
            </Link>
            .
          </p>
        </div>
        <div className="hidden md:block md:w-1/2">
          <img
            src="https://images.unsplash.com/photo-1588318312727-e210fcd11464?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTA3fHxzbWFydCUyMGRldmljZXxlbnwwfHwwfHx8MA%3D%3D"
            alt="Login illustration"
            className="w-full h-full object-cover rounded-r-lg"
          />
        </div>
      </div>
    </div>
  );
}

export default Login;
