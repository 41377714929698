import React from "react";
import { RxCross1 } from "react-icons/rx";

function ViewUser({ onClose, user }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 my-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-3xl w-full mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className="mr-5 text-lg sm:text-2xl font-semibold  text-[#F58634]">
                {user.user.name}'s details
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 sm:gap-4">
            <div>
              <h1 className="text-gray-500 font-semibold">
                Name: {user.user.name}
              </h1>
            </div>
            <div>
              <h1 className="text-gray-500 font-semibold">
                Email: {user.user.email}
              </h1>
            </div>
            <div>
              <h1 className="text-gray-500 font-semibold">
                Contact number: {user.user.mobile_no}
              </h1>
            </div>
            <div>
              <h1 className="text-gray-500 font-semibold">
                Address: {user.address}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewUser;
