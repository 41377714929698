import React from "react";
import Lottie from "lottie-react";
import animationData from "../Components/Images/Animation - 1717563087602.json";

function Loader(props) {
  return (
    <div className=" flex items-center justify-center  w-full h-screen align-middle inset-0 bg-white bg-opacity-50 bg-cover bg-center z-50 fixed">
      <Lottie
        animationData={animationData}
        loop
        autoplay
        className="h-64 w-64"
      />
    </div>
  );
}

export default Loader;
