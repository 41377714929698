import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Input from "../../../../../Components/FormFields/Input";
import Select from "../../../../../Components/FormFields/Select";
import Button from "../../../../../Components/Button/Button";
import { FaTrash } from "react-icons/fa";
import * as Yup from "yup";

function QuotationForm({
  products,
  categories,
  onSubmit,
  handleCategoryChange,
  setFilteredData,
  filteredData,
}) {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    contact_number: Yup.string()
      .required("Contact number is required")
      .matches(/^[0-9]{10}$/, "Contact number must be exactly 10 digits"),
    address: Yup.string()
      .required("Address is required")
      .min(5, "Address must be at least 5 characters"),
    product_data: Yup.array()
      .min(1, "At least one product is required")
      .required("Product data is required"),
  });

  const handleProductChange = (product, isChecked, values, setFieldValue) => {
    const productData = values.product_data || [];
    if (isChecked) {
      setFieldValue("product_data", [
        ...productData,
        { product_id: product.id, quantity: 1, discount: 0 },
      ]);
    } else {
      setFieldValue(
        "product_data",
        productData.filter((item) => item.product_id !== product.id)
      );
    }
  };

  const calculateTotal = (quantity, mrp, discount) => {
    const total = quantity * mrp;
    const discountAmount = (total * discount) / 100;
    return total - discountAmount;
  };

  useEffect(() => {
    setFilteredData(products);
  }, [products]);

  return (
    <div className="bg-[#F4F4F4] p-8 rounded-md shadow-md mb-10 sm:w-full w-96">
      <h1 className=" text-lg sm:text-2xl font-semibold  text-[#F58634]">
        Quotation
      </h1>
      <div>
        <Formik
          initialValues={{
            name: "",
            email: "",
            contact_number: "",
            category: "Category",
            address: "",
            product_data: [],
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          enableReinitialize={true}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="grid sm:grid-cols-2 grid-cols-1 sm:mt-4 mt-2 gap-2 sm:gap-4">
                <div>
                  <Input
                    name={"name"}
                    type={"text"}
                    placeholder={"John doe"}
                    label={"Name"}
                  />
                </div>
                <div>
                  <Input
                    name={"email"}
                    type={"email"}
                    placeholder={"johndoe@gmail.com"}
                    label={"Email"}
                  />
                </div>
                <div>
                  <Input
                    name={"contact_number"}
                    placeholder={"9845******"}
                    type={"tel"}
                    label={"Contact number"}
                  />
                </div>
                <div>
                  <Select
                    name={"category"}
                    label={"Category"}
                    selectedOption={"Category"}
                    options={categories}
                    onChange={(e) =>
                      handleCategoryChange(e.target.value, setFieldValue)
                    }
                  />
                </div>
              </div>
              <div className="sm:mt-4 mt-2">
                <Input
                  name={"address"}
                  label={"Address"}
                  placeholder={"Address"}
                  type={"text"}
                />
              </div>
              <div className="mt-2">
                <label className="block mb-2 text-sm text-gray-600 ">
                  Products
                </label>
                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-2 sm:gap-4 mt-2 sm:mt-4 h-44 overflow-y-auto">
                  {filteredData.map((data) => (
                    <label
                      key={data.id}
                      className="flex items-center flex-row border-[#F58634] border-2 h-20 sm:w-auto w-auto rounded-md shadow-md p-2 cursor-pointer"
                    >
                      <div className="flex items-start justify-start m-2 h-5 w-5">
                        <input
                          value={data.id}
                          type="checkbox"
                          className="h-5 w-5 border-[#F58634] accent-[#F58634] cursor-pointer"
                          onChange={(e) =>
                            handleProductChange(
                              data,
                              e.target.checked,
                              values,
                              setFieldValue
                            )
                          }
                          checked={values.product_data.some(
                            (item) => item.product_id === data.id
                          )}
                        />
                      </div>
                      <div className="flex flex-col ml-1">
                        <h1 className="text-[#F58634] font-semibold text-sm">
                          {data.product_name}
                        </h1>
                        <h2 className="text-xs font-semibold">₹{data.mrp}</h2>
                      </div>
                    </label>
                  ))}
                </div>
                <ErrorMessage
                  name="product_data"
                  component="div"
                  className="text-sm mt-1 text-red-500"
                />
              </div>
              <FieldArray name="product_data">
                {({ remove }) => (
                  <div className="mt-4 block sm:w-full overflow-x-auto">
                    <table className="bg-white min-w-full">
                      <thead className="bg-[#F58634] text-white">
                        <tr>
                          <th className="py-2 px-4">Product Name</th>
                          <th className="py-2 px-4">Price</th>
                          <th className="py-2 px-4">Quantity</th>
                          <th className="py-2 px-4">Discount (%)</th>
                          <th className="py-2 px-4">Total</th>
                          <th className="py-2 px-4">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.product_data.map((product, index) => {
                          const productDetails = products.find(
                            (p) => p.id === product.product_id
                          );
                          const total = calculateTotal(
                            product.quantity,
                            productDetails.mrp,
                            product.discount
                          );
                          return (
                            <tr key={index} className="bg-gray-100">
                              <td className="border px-4 py-2">
                                {productDetails?.product_name}
                              </td>
                              <td className="border px-4 py-2">
                                ₹{productDetails?.mrp}
                              </td>
                              <td className="border px-4 py-2">
                                <Input
                                  name={`product_data.${index}.quantity`}
                                  type="number"
                                  placeholder="Quantity"
                                  onChange={(e) =>
                                    setFieldValue(
                                      `product_data.${index}.quantity`,
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="border px-4 py-2">
                                <Input
                                  name={`product_data.${index}.discount`}
                                  type="number"
                                  placeholder="Discount"
                                  value={product.discount}
                                  onChange={(e) =>
                                    setFieldValue(
                                      `product_data.${index}.discount`,
                                      e.target.value
                                    )
                                  }
                                />
                              </td>
                              <td className="border px-4 py-2">₹{total}</td>
                              <td className="border px-4 py-2">
                                <button
                                  type="button"
                                  className="text-red-500 bg-red-100 rounded-full p-2"
                                  onClick={() => remove(index)}
                                >
                                  <FaTrash />
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </FieldArray>
              <div className="sm:mt-4 mt-2">
                <Button name={"Add"} type={"submit"} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default QuotationForm;
