import React from "react";
import { useParams } from "react-router-dom";
import Cartpage from "./Pages/User/Cartpage";
import Addresspage from "./Pages/User/Addresspage";
import Paymentpage from "./Pages/User/Paymentpage";

function Checkoutpages() {
  const { Checkout } = useParams();
  return (
    <>
      {Checkout === "cart" ? (
        <Cartpage />
      ) : Checkout === "address" ? (
        <Addresspage />
      ) : (
        <Paymentpage />
      )}
    </>
  );
}

export default Checkoutpages;
