import React, { useState } from "react";
import { PiCurrencyInrDuotone } from "react-icons/pi";
import Skeleton from "react-loading-skeleton";

function Cardview1({
  id,
  src,
  productname,
  description,
  category,
  mrp,
  handleViewMore,
}) {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div className="w-72 mx-auto rounded-xl overflow-hidden shadow-2xl transition-transform duration-300 hover:shadow-lg hover:-translate-y-2 flex justify-between flex-col bg-white hover:outline outline-2 hover:outline-slate-900">
      <div className="relative group">
        <img
          src={src}
          alt="Product"
          className={`w-full h-48  object-fill ${imageLoaded ? "" : "hidden"}`}
          onLoad={() => setImageLoaded(true)}
        />
        {!imageLoaded && <Skeleton height={196} className="rounded-xl" />}
      </div>

      <div className="p-4 h-40 flex flex-col justify-between">
        <h3 className="text-md font-semibold text-[#242c44] mb-1 line-clamp-1">
          {productname}
        </h3>
        <h4 className="text-sm font-semibold text-gray-600 mb-1">{category}</h4>
        <p className="text-gray-600 text-xs mb-2 line-clamp-2">{description}</p>
        <div className="flex items-end justify-between">
          <span className="font-semibold text-md text-[#242c44] flex items-center">
            <PiCurrencyInrDuotone />
            {mrp}
          </span>
          <button
            className="bg-[#242C44] hover:bg-[#ffffff] hover:text-[#242C44] text-white font-semibold py-1 px-2 rounded focus:outline-none focus:ring-2 focus:ring-[#242C44] focus:ring-opacity-75 hover:border-2 border-[#242C44]"
            onClick={() => {
              handleViewMore(id);
            }}
            aria-label={`View more ${productname}`}
          >
            View More
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cardview1;
