import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import Button from "../../../../Components/Button/Button";
import axios from "axios";
import toast from "react-hot-toast";
import Skeleton from "react-loading-skeleton";

function ProductImages({ onClose, ImagesData, productData }) {
  const [edit, setEdit] = useState(false);
  const [Images, setImages] = useState(ImagesData.other_images);
  const [addImage, setAddImage] = useState([]);
  const [mainImageLoaded, setMainImageLoaded] = useState(false);
  const [otherImagesLoaded, setOtherImagesLoaded] = useState(false);

  const addImageHandler = () => {
    const formData = new FormData();

    [...addImage].forEach((img, index) => {
      formData.append("product[other_images][]", img);
    });

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    axios
      .post(`api/v1/products/${ImagesData.id}/add_images`, formData, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          setAddImage([]);
          toast.success(response.data.message);
          productData();
          onClose();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteImage = (id) => {
    axios
      .delete(`api/v1/products/${id}/delete_image`)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data.message);
          setImages((prevImages) =>
            prevImages.filter((image) => image.id !== id)
          );
          productData();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black overflow-auto bg-opacity-50">
      <div className="flex items-center justify-center z-50 my-auto">
        <div className="bg-white p-4 sm:p-8 rounded shadow-md max-w-5xl w-full mx-auto">
          <div className="flex items-center justify-between mb-2 sm:mb-4">
            <div className="flex items-start justify-start">
              <h1 className=" text-lg sm:text-2xl font-semibold text-[#F58634]">
                Images
              </h1>
            </div>
            <div className="flex items-end justify-end">
              <button
                className="bg-[#F58634] p-2 text-lg text-[#fbffff] rounded-full"
                onClick={onClose}
              >
                <RxCross1 />
              </button>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start mt-5 ">
            <h2 className="text-lg font-semibold text-[#F58634]">Main Image</h2>
            <img
              src={ImagesData.main_image_url}
              className={`${mainImageLoaded ? "flex" : "hidden"} h-80 w-1/2`}
              onLoad={() => setMainImageLoaded(true)}
            />
            {!mainImageLoaded && (
              <Skeleton className="h-80 w-1/2" height={350} width={400} />
            )}
          </div>
          <div className="flex flex-row my-8">
            <h2 className="text-lg font-semibold text-[#F58634] mt-5">
              Other Images
            </h2>
            {otherImagesLoaded && (
              <div className="ml-5 mt-3">
                <Button
                  name={"Add/Remove Images"}
                  onClick={() => setEdit(!edit)}
                />
              </div>
            )}
          </div>
          <div className="grid sm:grid-cols-4 grid-cols-2 gap-4 sm:mt-2 mt-4 ">
            {Images &&
              Images.map((image, index) => (
                <div className="m-3 relative" key={index}>
                  {edit && (
                    <button
                      onClick={() => deleteImage(image.id)}
                      className="absolute -mt-5 -ml-2 bg-red-600 text-red-100 text-lg font-bold p-2 rounded-full"
                    >
                      <RxCross1 />
                    </button>
                  )}
                  <img
                    src={image.url}
                    className={`${
                      otherImagesLoaded ? "flex" : "hidden"
                    } h-40 w-52`}
                    onLoad={() => setOtherImagesLoaded(true)}
                  />
                  {!otherImagesLoaded && (
                    <Skeleton className="h-40 w-52" height={150} width={200} />
                  )}
                </div>
              ))}
          </div>

          {edit && (
            <div className="flex justify-between">
              <div className="flex items-start justify-start">
                <input
                  type="file"
                  multiple
                  onChange={(e) => setAddImage(e.target.files)}
                  className="block w-full px-4 py-2 mt-2 text-[#1B1D1E] placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-[#1B1D1E] focus:ring-[#1B1D1E] focus:outline-none focus:ring focus:ring-opacity-40"
                />
              </div>
              <div className="flex items-end justify-end">
                <Button
                  name={"Add Images"}
                  type={"button"}
                  onClick={addImageHandler}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductImages;
