import { ErrorMessage, Field } from "formik";
import React from "react";

function Select({ label, name, selectedOption, options, onChange }) {
  return (
    <div>
      <label className="block mb-2 text-sm text-gray-600 ">{label}</label>
      <Field
        as="select"
        name={name}
        onChange={onChange}
        className="block w-full px-4 py-2 mt-2 text-[#1B1D1E] placeholder-gray-400 bg-white border border-gray-200 rounded-md  focus:border-[#1B1D1E]  focus:ring-[#1B1D1E] focus:outline-none focus:ring focus:ring-opacity-40"
      >
        <option selected>{selectedOption}</option>
        {options.map((option, index) => (
          <option key={index} value={option.id}>
            {option.name}
          </option>
        ))}
      </Field>
      <ErrorMessage
        name={name}
        className="text-red-500 text-sm mt-1"
        component="div"
      />
    </div>
  );
}

export default Select;
